<div class="rebrandingUI-report-card rebrandingUI" *ngIf="report" (click)="openReport()" [ngClass]='{ "bg-green": !report.get("read") }'>
  <div class="unread">
    <div class="shine loop">
      <div class="tag" *ngIf='!report.get("read") && isNew' i18n="@@new">
        NEW
      </div>
      <div class="tag" *ngIf='!report.get("read") && !isNew' i18n="@@unread">
        UNREAD
      </div>
    </div>
  </div>
  <div class="tw-mt-0.5 tw-flex-grow tw-relative tw-flex tw-flex-col tw-items-start">
    <div class="tw-text-300" i18n="@@checkUpReportBy">
      {{report.get('type')}} <br/> Check-up report by <br /><b>{{report.get('doctor')?.get('DoctorDisplayName') || 'Dr. ' + report.get('doctor')?.get('username')}}</b>
    </div>
    <div class="tw-text-start tw-text-500 tw-capitalize tw-font-bold tw-pt-2.5 tw-w-11/12 ">
      <span class="date">{{report.get('createdAt') | date: 'd'}}</span><span>&nbsp;</span><span class="month">{{report.get('createdAt') | date: 'MMM'}},</span><span>&nbsp;</span><span class="month">{{report.get('createdAt') | date: 'YYYY'}}</span>
    </div>
  </div>
  <div class="images">
    <div class="tw-mt-0.5 tw-relative tw-flex tw-flex-row">
      <img class="rebrandingUI-image" src="https://storage.googleapis.com/heallocontent/app/img/purple-icon.svg" />
    </div>
    <button class="rebrandingUI-btn" (click)="openReport()">
      <span i18n="@@viewReport">View Report</span><span>&nbsp;&nbsp;></span>
    </button>
  </div>
</div>
