import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { DirectivesModule } from '@directives/directives.module';
import { CsIconModule } from '@components/icon';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { ButtonModule } from '@components/button';
import { UserPrescriptionViewComponent } from './user-prescription-view.component';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
    DirectivesModule,
    LoadingModule,
    CsIconModule,
    ButtonModule,
    ToolbarTabsModule,
  ],
  declarations: [UserPrescriptionViewComponent],
})
export class UserPrescriptionViewModule {}
