<div class="hbox font-noto">
  <loading class="pos-abs vh-center" *ngIf="loading"></loading>
  <div class="flex-box" *ngIf="!loading">
    <div class="flex-heading">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@explore">Explore</span>
      </div>
      <div class="buy-regimen" *ngIf="unBoughtRegimen" (click)="buyRegimen()">
        <span i18n="@@yourTreatmentKitIsReady">Your treatment kit is ready</span>
        <div class="btn" i18n="@@buyNow">Buy Now</div>
      </div>
    </div>
    <div class="flex-content bg-app last:tw-mb-20" #scrollContainer="" (scroll)="onScroll($event)">
      <ng-container *ngFor="let feed of feedsJSON; let i = index; trackBy: trackById">
        <feed [feed]="feed" [visible]="feed?.isVisible" [index]="i" [isLiked]="feed?.isLiked" (likeToggle)="updateIsLikedFromFeedComponent($event, i)" (onCTAClick)="onCTAClick($event)"></feed>
      </ng-container>
      <div class="empty-hint centerA" *ngIf="!loading && !feeds.length" i18n="@@noFeedsAvailable"> No Feeds Available </div>
      <div id="intersectionSubElement" style="height: 1px"></div>
    </div>
  </div>
</div>