import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { CureskinStarComponent } from './cureskin-star/cureskin-star.component';
import { CureskinStarTermsComponent } from './cureskin-star-terms/cureskin-star-terms.component';

@NgModule({
  imports: [CommonModule, ToolbarModule, ButtonModule],
  declarations: [CureskinStarComponent, CureskinStarTermsComponent],
  exports: [],
})
export class CureskinStarModule {}
