import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { ShopBannersComponent } from './shop-order-banners.component';

@NgModule({
  imports: [CommonModule, CsIconModule],
  declarations: [ShopBannersComponent],
  exports: [ShopBannersComponent],
})
export class ShopBannersModule {}
