import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatV2Component } from './chatV2.component';
import { ChatV2ViewModule } from '../chatV2-view/chatV2-view.module';

@NgModule({
  imports: [
    CommonModule,
    ChatV2ViewModule,
  ],
  exports: [ChatV2Component],
  declarations: [ChatV2Component],
})
export class ChatV2Module {}
