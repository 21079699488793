import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowRefService } from '@services/window-ref-service/index';
import { ConnectionService } from '@services/connection-service/index';
import { DataStoreService } from '@services/data-store-service/index';
import { BroadcastService } from '@services/broadcast-service';

@Component({ selector: 'user-diet', templateUrl: './user-diet.html', styleUrls: ['./user-diet.scss'] })
export class UserDietComponent {
  dietData: any;
  selectedNoon: string;
  loading: boolean = true;
  instructionSet: any;

  static parameters: Array<any> = [
    DomSanitizer,
    WindowRefService,
    ConnectionService,
    Router,
    DataStoreService, ActivatedRoute, BroadcastService];
  constructor(private dom: DomSanitizer,
              private window: WindowRefService,
              private conn: ConnectionService,
              private router: Router, private dataStore: DataStoreService, private route: ActivatedRoute,
              private broadcast: BroadcastService) {
  }

  // async ngOnInit(): Promise<any> {
  //   const diet = await this.conn.getDiet({ where: { objectId: this.route.snapshot.params.id } });
  //   if (!diet) {
  //     this.back();
  //     return;
  //   }
  //   this.dietData = JSON.parse(JSON.stringify(diet));
  //   this.loading = false;
  //   this.showInstruction('BREAKFAST');
  // }

  // showInstruction(noon: any): void {
  //   this.selectedNoon = noon;
  //   this.instructionSet = this.dietData[String(this.selectedNoon).toLowerCase()];
  // }

  // consultNow(): void {
  // }

  // back(): void {
  //   this.broadcast.broadcast('NAVIGATION_BACK');
  // }
}
