<div class="tw-block tw-w-full tw-h-full tw-border-spacing-0 tw-overflow-hidden tw-font-body">
  <div class="tw-flex tw-h-full tw-flex-col tw-flex-nowrap tw-relative">
    <div class="tw-w-full tw-flex-initial">
      <div class="tw-flex tw-items-center tw-justify-center tw-h-14 tw-relative tw-bg-black" *ngIf="!hideNavBar" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta', 'tw-text-300': user?.get('languagePreference') !== 'ta'}">
        <cs-icon class="tw-absolute tw-left-6 tw-top-4 tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'" (click)="back()"></cs-icon>
        <div class="tw-font-body tw-text-white tw-ml-8" *ngIf="'Consultation' !== title && this.selectedChatType.className  !== 'SupportTicket'">
          {{title || selectedChatType?.get('title')}}
        </div>
        <div class="tw-font-body tw-text-white tw-ml-8" *ngIf="'Consultation' !== title && this.selectedChatType.className  === 'SupportTicket'">
          {{selectedChatType?.get('ticketNumber')}}
        </div>
        <div class="tw-font-body tw-text-white" *ngIf="'Consultation' === title && selectedChatType.get('PrivateMainConcernClass') === 'FACE'" i18n="@@discoverSkin"> Discover clear & healthy skin </div>
        <div class="tw-font-body tw-text-white" *ngIf="'Consultation' === title && selectedChatType.get('PrivateMainConcernClass') === 'HAIR'" i18n="@@discoverHair"> Discover strong & healthy hair </div>
      </div>
      <cross-regimen-banner *ngIf="selectedChatType?.className === 'ConsultationSession'" [incompleteChat]="true"></cross-regimen-banner>
    </div>
    <div class="tw-w-full tw-overflow-x-hidden tw-overflow-y-auto tw-flex-auto tw-relative tw-bg-gray-100 tw-bg-[url('https://storage.googleapis.com/heallocontent/app/img/chat-bg.svg')] scrollFast" id="messageContainer" #messageContainer="">
      <div class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-full tw-absolute tw-z-10 tw-p-[10px] tw-left-1/2 tw-top-4 -tw-translate-x-1/2 tw-shadow-z1" *ngIf="(chatPayload && chatPayload.loading.old) || !chats.length">
        <loading class="tw-scale-75"></loading>
      </div>
      <div class="tw-w-full">
        <div class="tw-w-full tw-clear-both" *ngFor="let chat of chats; let i = index; trackBy: trackChatArray" title='{{chat.get("BotMetadata")}}' [id]="chat.id">
          <ng-container *ngIf="isChatVisible(chat)">
            <div class="tw-w-full tw-text-center" *ngIf="i === 0 || chat.displayDate !== chats[i-1].displayDate">
              <span class="tw-inline-block tw-w-[150px] tw-px-0 tw-py-[2px] tw-bg-purple-100 tw-rounded-4 tw-uppercase tw-mt-[10px] tw-text-200 tw-tracking-[1px]">{{chat.displayDate}}</span>
            </div>
            <div class="tw-float-right tw-w-full tw-text-center tw-mt-[10px]" #unreadMessageDivider="" *ngIf='chat.get("unreadMessageStart") >= 0'>
              <div class="tw-font-medium tw-text-200 tw-py-[10px] tw-px-5 tw-rounded-6 tw-text-center tw-inline-block tw-m-auto tw-bg-white" i18n="@@unreadMessages">
                {{chat.get("unreadMessageStart")}} Unread Messages
              </div>
            </div>
            <div class="tw-text-300 tw-mt-6 tw-max-w-[300px] tw-min-w-[90px] tw-mx-4 tw-mb-0 tw-pb-3 tw-clear-both tw-break-words tw-whitespace-pre-line tw-overflow-x-hidden tw-bg-gray-50 tw-animate-anim_scale" *ngIf="chat.get('Mode') !== appConfig.Shared.Assistant.Mode.SMS_COPY" [ngClass]='{ "tw-relative tw-rounded-tl-4 tw-rounded-tr-4 tw-rounded-br-4 tw-rounded-bl-0 tw-origin-left tw-shadow-z1": ((isInternalUser && chat.get("Owner") === "USER") || (!isInternalUser && chat.get("Owner") !== "USER")), "tw-float-right tw-relative tw-origin-right tw-bg-green-100 tw-text-black tw-shadow-[0px_2px_3px_0px_rgba(0,0,0,0.1)] tw-rounded-tl-5 tw-rounded-tr-5 tw-rounded-br-5 tw-rounded-bl-[0px] tw-mb-5": ((isInternalUser && (chat.get("Owner") !== "USER")) || (!isInternalUser && chat.get("Owner") === "USER")), "tw-mb-[10px]": i === chats.length-1, "tw-pt-0 tw-px-0 tw-mb-1 tw-min-w-20": appConfig.Shared.Assistant.MediaTypeModes.includes(chat.get("Mode")), "tw-pt-4 tw-px-4" : !appConfig.Shared.Assistant.MediaTypeModes.includes(chat.get("Mode")) }' [ngStyle]="{'animation-duration': allowAnimation ? '0ms' : '400ms'}">
              <ng-container *ngIf="!appConfig.Shared.Assistant.NonPlainTextModes.includes(chat.get('Mode'))">
                <div *ngIf='chat.get("Owner") === "USER"'>
                  {{chat.get('Message')}}
                </div>
                <div *ngIf='chat.get("Owner") !== "USER"' [innerHTML]="chat.get('Message')"></div>
              </ng-container>
              <div class="tw-relative" *ngIf="chat.get('Mode') === appConfig.Shared.Assistant.Mode.IMAGE_CLUBBED">
                <image-card (click)="handleImageCardClick(chat)" [data]="chat.get('imageArray')" (openImageModal)="openImageModal(chat)" (expandImages)="expandImages(chat)"></image-card>
              </div>
              <ng-container *ngIf="appConfig.Shared.Assistant.RequestImageModes.includes(chat.get('Mode'))">
                <div *ngIf='chat.get("Owner") === "USER"'>
                  {{chat.get('Message')}}
                </div>
                <div *ngIf='chat.get("Owner") !== "USER"' [innerHTML]="chat.get('Message')"></div>
                <cs-button id="takePhoto" *ngFor="let inputs of (chat.get('Inputs') || []); let i = index;" [class]="'tw-mt-[10px] primary btn-xs tw-float-right tw-clear-both'" (callback)="processAssistantAction(chat, i, inputs)">
                  <cs-button-text>{{inputs.text}}</cs-button-text>
                  <cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon>
                </cs-button>
              </ng-container>
              <ng-container *ngIf="chat.get('Mode') === appConfig.Shared.Assistant.Mode.AUDIO">
                <audio-chat [data]="chat" [user]="user" [doctor]="allocatedDoctor"></audio-chat>
              </ng-container>
              <ng-container *ngIf="chat.get('Mode') === appConfig.Shared.Assistant.Mode.ARTICLE">
                <article [data]="chat" (action)="processAssistantAction($event.chat, $event.index, $event.input)"></article>
              </ng-container>
              <div class="tw-clear-both timestamp tw-opacity-60 tw-text-right tw-pt-2 ts-new tw-tracking-[0.17em] tw-uppercase tw-text-100 tw-leading-5 tw-text-gray-400" *ngIf="!appConfig.Shared.Assistant.MediaTypeModes.includes(chat.get('Mode'))">
                {{chat.get('createdAt') | date: (isInternalUser ? 'hh:mm a' :
        'hh:mm a') }} &nbsp; {{isInternalUser ? chat.get('OperatorName')
        || chat.get('Owner') : ''}}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="tw-float-right tw-w-full" *ngIf="!ui.botReplyInProcess">
          <single-selector *ngIf="latestMessage" [data]="latestMessage" (message)="processAssistantAction($event.chat, $event.index, $event.input)" [allowAnimation]="allowAnimation"></single-selector>
        </div>
        <div class="tw-min-w-20 w20 mB20 tw-w-1/5 tw-clear-both tw-float-left tw-my-5 tw-mx-4 tw-bg-white tw-px-[15px] tw-py-[10px] tw-relative tw-rounded-tl-5 tw-rounded-tr-5 tw-rounded-br-5 tw-rounded-bl-0 tw-origin-left tw-shadow-z1 tw-animate-anim_scale" *ngIf="ui.botReplyInProcess">
          <div class="dot1 tw-inline-block tw-w-1.5 tw-h-1.5 tw-rounded-full tw-mt-[3px] tw-mx-[5px] tw-mb-0 tw-bg-gray-500 "></div>
          <div class="dot2 tw-inline-block tw-w-1.5 tw-h-1.5 tw-rounded-full tw-mt-[3px] tw-mx-[5px] tw-mb-0 tw-bg-gray-500 "></div>
          <div class="dot3 tw-inline-block tw-w-1.5 tw-h-1.5 tw-rounded-full tw-mt-[3px] tw-mx-[5px] tw-mb-0 tw-bg-gray-500 "></div>
        </div>
      </div>
      <div class="tw-clear-both" *ngIf="!ui.chatInputEnable">
        <div class="tw-px-3 tw-py-4 tw-flex tw-w-full tw-items-center" *ngIf="!ui.botReplyInProcess && timer.minutes < 0">
          <single-selector *ngIf="!ui.chatInputEnable && latestMessage" [data]="latestMessage" (message)="processAssistantAction($event.chat, $event.index, $event.input)" [allowAnimation]="allowAnimation"></single-selector>
        </div>
        <div class="tw-flex tw-items-center tw-flex-wrap tw-my-0 tw-mx-3 tw-pt-1.5 tw-px-0 tw-pb-3" *ngIf="timer.minutes >= 0 " (click)="openActiveSessionRegimen()">
          <div class="tw-text-center tw-bg-white tw-tracking-[0.5px] tw-rounded-5 tw-rounded-bl-0 tw-py-2 tw-px-6 tw-text-blue-500 tw-shadow-z1">
            <div class="tw-min-h-10 tw-flex tw-items-center tw-justify-center tw-flex-wrap">
              <div class="tw-inline-block" i18n="@@getYourTreatmentTime"> Get your treatment kit in </div>
              <div class="tw-inline-block">
                <div class="tw-inline-block tw-pl-[10px]">{{timer.minutes}} Min</div>
                <div class="tw-min-w-7 tw-text-right tw-py-0 tw-px-1 tw-inline-block">
                  {{timer.seconds > 9 ? timer.seconds : '0'+timer.seconds}}
                </div>
                <div class="tw-inline-block">Sec</div>
              </div>
            </div>
            <div class="tw-flex tw-justify-center tw-items-center" *ngIf="timer.minutes < 0">
              <loading class="tw-scale-[0.8]"></loading>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-pb-4 tw-w-full tw-clear-both" *ngIf='selectedChatType?.get("status") === appConfig.Shared.Ticket.status.Completed'></div>
    </div>
    <div class="tw-w-full tw-flex-[0_1_0] tw-bg-gray-100">
      <div class="tw-flex tw-w-full tw-items-center tw-py-[15px] tw-px-3" *ngIf="ui.chatInputEnable && !ui.botReplyInProcess">
        <div class="tw-relative tw-mr-3 tw-flex-grow tw-bg-white tw-rounded-full">
          <input class="secondary" [(ngModel)]="chatPayload.input" placeholder="Type your message here" i18n-placeholder="@@chatPlaceholder" autocomplete="off" (blur)="onKeyBoardOpen()" (click)="onKeyBoardOpen()" />
        </div>
        <div class="tw-inline-block tw-w-[45px] tw-h-[45px]">
          <div class="tw-flex tw-items-center tw-justify-center tw-w-[45px] tw-h-[45px] tw-rounded-full tw-text-center tw-bg-white tw-shadow-z1 " (click)="saveUserResponse(null, chatPayload.input)" [ngStyle]="{'background': chatPayload.input ? '#fff' : '#fff'}">
            <em class="cs-icon-send tw-ml-1 tw-text-600" [ngStyle]='{ "color" : chatPayload.input ? "#26216e" : "#c3c3c3" } '></em>
          </div>
        </div>
      </div>
      <div class="tw-py-5 tw-px-4" *ngIf="this.selectedChatType?.className === 'SupportTicket' && ([appConfig.Shared.Ticket.status.Completed, appConfig.Shared.Ticket.status.RequestClosure].includes(selectedChatType?.get('status'))) && !isInternalUser">
        <div class="tw-flex tw-items-center tw-bg-white tw-rounded-1 tw-p-4 tw-mb-4" *ngIf="!this.selectedChatType.get('feedback') && !afterSaveFeedback">
          <div class="tw-flex-1" i18n="@@wasThisHelpfull">Was this helpful?</div>
          <div class="tw-min-w-20 tw-flex tw-items-center tw-flex-wrap tw-justify-end">
            <em class="tw-text-600 tw-ml-[15px]" (click)="updateFeedback(true)" [ngClass]="{ 'cs-icon-like-filled tw-animate-anim_like tw-text-green-400': isHelpfull, 'cs-icon-like': !isHelpfull }"></em>
            <em class="tw-text-600 tw-ml-[15px]" (click)="updateFeedback(false)" [ngClass]="{ 'cs-icon-dislike-filled tw-animate-anim_like tw-text-red-400': isHelpfull === false, 'cs-icon-dislike': isHelpfull !== false }"></em>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-bg-white tw-rounded-[5px] tw-p-4 tw-mb-4 feedback" *ngIf="afterSaveFeedback">
          <cs-icon class="tw-w-6 tw-h-6 tw-text-green-400" [iconName]="'check_circle'"></cs-icon>
          <div class="tw-flex-1 tw-ml-[10px]" i18n="@@thankYouForYourFeedback"> Thank You For Your Feedback </div>
        </div>
      </div>
      <div class="tw-p-[15px] tw-bg-orange-500 tw-text-white tw-font-medium tw-text-center tw-text-300" *ngIf='selectedChatType?.get("status") === appConfig.Shared.Ticket.status.Completed' i18n="@@thisQueryHasBeenClosed"> This conversation is marked as closed. If you have any other questions or issues, please open the <b>“Need Help”</b> section of our app to raise a new query. </div>
    </div>
  </div>
</div>
<multiImages *ngIf="multiImageData.length" [data]="multiImageData" (close)="closeMultiImagePopUp($event)"></multiImages>
<file-picker (onFileChange)="onUploadReport($event)" accept="image/jpeg,application/pdf"></file-picker>
<pop-up *ngIf="imageModal.open" [fullscreen]="true" [class]="'bdr-rad-10 '" [show]="imageModal.open" [triggerDismiss]="true" (close)="closePopup()">
  <div class="tw-block tw-border-spacing-0 tw-w-full tw-h-full tw-overflow-hidden">
    <div class="tw-flex tw-flex-col tw-flex-nowrap tw-h-full"></div>
    <div class="tw-flex-initial tw-w-full" #header="">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="closeImageModal()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
      </div>
    </div>
    <div class="tw-font-body tw-relativel tw-flex-1 tw-pb-5 tw-bg-gray-100" #content="">
      <div class="tw-relative tw-overflow-hidden">
        <div style="position: relative; width: 100%; height: 100%">
          <img [src]="imageModal.imageUrl" alt="Image Description" style="width: 100%; max-height: 100%; object-fit: contain" />
        </div>
      </div>
    </div>
  </div>
</pop-up>