import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { ButtonModule } from '@components/button';
import { ToolbarModule } from '@components/toolbar';
import { DirectivesModule } from '@directives/directives.module';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { UserCheckupComponent } from './user-checkup.component';
import { UserFollowupModule } from '../user-followup/user-followup.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ToolbarTabsModule,
    ButtonModule,
    LoadingModule,
    ToolbarModule,
    DirectivesModule,
    UserFollowupModule,
    CsIconModule,
  ],
  declarations: [UserCheckupComponent],
  exports: [UserCheckupComponent],
})
export class UserCheckupModule {
}
