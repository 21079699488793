import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-voice-note-banner-script',
  templateUrl: './voice-note-script.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class VoiceNoteScriptComponent {
    @Input('productsInCart') productsInCart:any;
    @Input('user') user:Table.User;
    @Input('trackEventAudio') trackEventAudio:any;
    @Output('trackVoiceEvent') trackVoiceEvent:EventEmitter<any> = new EventEmitter();
    @Output('goToCart') goToCart:EventEmitter<void> = new EventEmitter();
    handleClick():void {
      this.goToCart.emit();
    }
    handleClickOne(event:any):void {
      this.trackVoiceEvent.emit(event);
    }
}
