<div class="tw-block tw-border-spacing-0 tw-w-full tw-flex-1 tw-overflow-x-hidden tw-h-screen">
  <div class="tw-flex tw-flex-col tw-flex-1 tw-h-full">
    <div class="w-w-full tw-flex-initial tw-font-body">
      <toolbar (onAction)="back()">Terms & Conditions</toolbar>
    </div>
    <div class="tw-w-full tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto tw-p-5 tw-font-body">
      <div class="tw-text-700 tw-text-center tw-pb-2.5">
        Terms and Conditions for the CureSkin Stars program
      </div>
      <div class="tw-pb-2.5">
        By participating in the CureSkin Stars program, you agree to the
        following
      </div>
      <div class="tw-pb-2.5">
        1. Entry to the CureSkin Stars program is by invitation from CureSkin
        only. There are no other ways to enter the program, and invitation to
        one CureSkin user doesn't guarantee the same for any other user.
      </div>
      <div class="tw-pb-2.5">
        2. Fulfillment of the criteria of the CureSkin Stars program (as
        specified in the official message from a CureSkin representative after
        the opt-in) is necessary to receive CureSkin cash. If the criteria is
        not fulfilled within a specified time, invitation to the program can be
        annulled.
      </div>
      <div class="tw-pb-2.5">
        3. CureSkin reserves the right to withdraw or halt the program with an
        absolution of pending obligations at any time.
      </div>
      <div class="tw-pb-2.5">
        4. All the footage and content that is provided by the customers who
        join this program may be used by CureSkin for promotional activities
        across all Social Media channels, the footage may also be used on TV
        ads, print ads and other forms of advertising and may even be used for
        representational purposes on other avenues such as exhibitions,
        screenings, website, etc.
      </div>
      <div class="tw-pb-2.5">
        5. By providing any video footage or pictures ("the assets") to CureSkin
        as a part of the CureSkin stars program, the user agrees that CureSkin
        will have absolute rights to the assets for any form of usage,
        modifications and publication as CureSkin management deems fit and that
        the user, under no circumstance, will have any rights over the footage
        or its usage of it.
      </div>
      <div class="tw-pb-2.5">
        6. CureSkin cash worth Rs. 2000 would be credited to the user's CureSkin
        wallet on the successful submission of the video, provided it is deemed
        to be fit for usage by CureSkin.
      </div>
      <div class="tw-pb-2.5">
        7. CureSkin reserves the right to reject any footage that is submitted
        by the user for any reason whatsoever. The rejected footage will not be
        used by CureSkin for any purpose. If CureSkin management requests for
        modification to the footage, the user will be required to comply in
        order to receive the gift box.
      </div>
      <div class="tw-pb-2.5">
        8. All legal disputes pertinent to the CureSkin Stars program are to be
        discussed and settled in Bangalore courts only.
      </div>
    </div>
  </div>
</div>
