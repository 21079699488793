import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { ConnectionService } from '@services/connection-service';

/**
 * Check for user authentication.
 * If user is not authenticated then based on the environment(mobile/web app),
 * user is redirected to different entry points in the route.
 *
 * @param route
 * @param state
 * @returns
 */
export const authenticationGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any => {
  const { isUserLoggedIn }: any = inject(ConnectionService);
  const isMobileApp: any = inject(AppWebBridgeService).isAppWebBridgeLoaded();

  if (isUserLoggedIn) return true;

  const redirectUrl = isMobileApp ? ['onboarding/number'] : ['/language'];
  return createUrlTreeFromSnapshot(route, redirectUrl);
};
