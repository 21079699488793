import { Injectable } from '@angular/core';
import { ConnectionService } from '../connection-service';
import { LocalStorageService } from '../local-storage-service';
import { LoggingService } from '../logging-service/logging-service';
import { AppConfig } from '../../app/app.config';

declare const AppWebBridge: any;

@Injectable()
export class AppWebBridgeService {
  constructor(private conn: ConnectionService,
    private localStorageService: LocalStorageService,
    private appConfig: AppConfig,
    private loggingService: LoggingService) { }

  isAppWebBridgeLoaded(): boolean {
    return typeof AppWebBridge !== 'undefined';
  }

  /** Notifies Native about the logout */
  notifyUserLogout(): void {
    if (!this.isAppWebBridgeLoaded()) return;
    AppWebBridge.userLogout();
  }

  notifyUserLanguageChange(language: string): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.changeUserLanguage) return;
    AppWebBridge.changeUserLanguage(language);
  }

  /** Request SMS permission from native */
  notifyRequestSmsPermission(): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestSmsPermission) return;
    AppWebBridge.requestSmsPermission();
  }

  /** Requests Native to take control of back navigation */
  notifyRequestBack(): boolean {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestBack) return false;
    AppWebBridge.requestBack();
    return true;
  }

  requestNotificationStatus(): boolean {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.isNotificationPermissionEnabled) return true;
    const status = AppWebBridge.isNotificationPermissionEnabled();
    return status;
  }

  requestNotificationPermission(): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestNotificationPermission) return;
    AppWebBridge.requestNotificationPermission();
  }

  /** Requests Native app version */
  requestAppVersion(): number {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestAppVersion) return 0;
    if (this.requestOSInformation() === 'iOS') {
      AppWebBridge.requestAppVersion();
      return Number(this.localStorageService.getValue('CureSkin/appBridgeVersion', '0'));
    }
    return AppWebBridge.requestAppVersion();
  }

  /** Requests Native app to open share app intent */
  requestShareApp(): boolean {
    if (!this.isAppWebBridgeLoaded()) return false;
    AppWebBridge.shareApp();
    return true;
  }

  /** Requests Native app to open share app intent with text */
  requestShareAppWithText(linkTitle: string = '', linkDescription: string = '', text: string = ''): boolean {
    if (!this.isAppWebBridgeLoaded()) return false;
    if (!AppWebBridge.shareAppWithText) {
      return this.requestShareApp();
    }
    if (this.requestOSInformation() === 'iOS') {
      AppWebBridge.shareAppWithText(JSON.stringify({ linkTitle, linkDescription, text }));
    } else {
      AppWebBridge.shareAppWithText(linkTitle, linkDescription, text);
    }
    return true;
  }

  /** Requests Native app to open share app for sharing images */
  requestShareImageUrl(imageURL: string, content?: string): boolean {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.shareImage) return false;
    if (this.requestOSInformation() === 'iOS') {
      AppWebBridge.shareImage(JSON.stringify({ imageURL, content }));
    } else {
      AppWebBridge.shareImage(imageURL, content);
    }
    return true;
  }

  logEventInBranchAndFirebaseFromiOS(config: { [key in 'firebase' | 'branch']: { name: string } }): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.logEventInBranch || !AppWebBridge.logEventInFirebase) return;
    if (this.requestOSInformation() !== 'iOS') return;

    if (config.branch) AppWebBridge.logEventInBranch(config.branch.name);
    if (config.firebase) AppWebBridge.logEventInFirebase(config.firebase.name);
  }

  /** Notifies Native on chat page load */
  notifyAssistantLoaded(url: string): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.chatLoaded) return;
    const chatType = this.getChatType(url);
    switch (chatType) {
      case 'followUp': {
        AppWebBridge.chatLoaded('FollowUp');
        break;
      }
      case 'consultationSession': {
        AppWebBridge.chatLoaded('ConsultationSession');
        break;
      }
      case 'supportTicket': {
        AppWebBridge.chatLoaded('SupportTicket');
        break;
      }
      default: AppWebBridge.chatLoaded('');
    }
  }

  /** Requests native to open camera for AI analysis */
  notifyCaptureImage(type: string, queryParameter: object = {}, extraData: object = {}): void {
    if (!this.isAppWebBridgeLoaded()) return;
    if (this.requestOSInformation() === 'android' && this.requestAppVersion() <= 179399) {
      AppWebBridge.requestCaptureImage(type);
      return;
    }
    if (this.requestOSInformation() === 'iOS' && this.requestAppVersion() === 0) {
      AppWebBridge.requestCaptureImage(type);
      return;
    }
    AppWebBridge.requestCaptureImage(JSON.stringify({ type, queryParameter, extraData }));
  }

  /** Requests native to open contact for referral flow */
  notifyContactsOpen(): void {
    if (!this.isAppWebBridgeLoaded()) return;
    if (this.requestOSInformation() === 'android') {
      AppWebBridge.requestContactList();
      return;
    }
    if (this.requestOSInformation() === 'iOS' && this.requestAppVersion() === 0) {
      this.loggingService.log('entering into iOS block');
      AppWebBridge.requestContactList();
      return;
    }
    AppWebBridge.requestContactList();
  }

  /** Requests native to listen for sms to read OTP */
  requestStartSMSListener(): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.startSMSListener) return;
    AppWebBridge.startSMSListener();
  }

  /** Requests native OS information */
  requestOSInformation(): string {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestOSInformation) return 'web';
    return AppWebBridge.requestOSInformation();
  }

  requestAndroidAdvertisingID(): string {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestAndroidAdvertisingID) return '';
    return AppWebBridge.requestAndroidAdvertisingID();
  }

  requestReferralCode(): string {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestReferralCode) return '';
    return AppWebBridge.requestReferralCode();
  }

  requestRazorpayLoaded(): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestRazorpayLoaded) return;
    AppWebBridge.requestRazorpayLoaded();
  }

  requestRazorpayPayment(paymentOption: object): boolean {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestRazorpayPayment) return false;
    AppWebBridge.requestRazorpayPayment(JSON.stringify(paymentOption));
    return true;
  }

  requestGokWikSuccess(data: Record<string, unknown>): boolean {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestGokWikSuccess) return false;
    AppWebBridge.requestGokWikSuccess(JSON.stringify(data));
    return true;
  }

  /** Requests native to update app bridge version */
  updateAppBridgeVersion(): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.updateAppBridgeVersion) return;
    AppWebBridge.updateAppBridgeVersion();
  }

  requestTrackPurchase(purchaseAmount: number, currency: string, parameters: object): boolean {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestTrackPurchase) return false;
    AppWebBridge.requestTrackPurchase(purchaseAmount, currency, parameters);
    return true;
  }

  logWebAppVersion(): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.logWebAppVersion) return;
    AppWebBridge.logWebAppVersion(this.appConfig.Shared.AppVersion);
  }

  isCustomTabSupported(): boolean {
    return this.isAppWebBridgeLoaded() && AppWebBridge.requestOpenInOverlayWindow && this.localStorageService.getBooleanValue('customTab');
  }

  /** Requests native to open url in overlay window if custom tab is supported in android.
   * Overlay window in nothing but a new tab with some optimized/secured setting.
   * Android will set a boolean 'customTab' in localStorage, if customTab is supported in the android version.
   * */
  requestOpenInOverlayWindow(url: string): boolean {
    if (!this.isCustomTabSupported()) {
      return false;
    }
    AppWebBridge.requestOpenInOverlayWindow(url);
    return true;
  }

  /** Requests native to open url in custom tab */
  requestOpenInCustomTab(url: string): boolean {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestOpenInCustomTab) return false;
    AppWebBridge.requestOpenInCustomTab(url);
    return true;
  }

  /** Sends session token to native */
  notifyWebLoginToken(): void {
    const user = this.conn.getActingUser();
    if (!user || this.conn.isInternalUser()) return;
    const token = user.get('sessionToken');
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.notifyWebLoginToken) return;
    AppWebBridge.notifyWebLoginToken(token);
  }

  notifyWebAppVersion(): void {
    const user = this.conn.getActingUser();
    if (!user || this.conn.isInternalUser()) return;
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.notifyWebAppVersion) return;
    AppWebBridge.notifyWebAppVersion(this.appConfig.Shared.AppVersion);
  }

  /** Notifies Native about the login by sending the session token */
  notifyUserLoggedIn(): void {
    if (!this.isAppWebBridgeLoaded() || !this.conn.isUserLoggedIn || !AppWebBridge.userLoggedIn) return;
    AppWebBridge.userLoggedIn(this.conn.getCurrentUser().getSessionToken());
  }

  /** Requests native to read GPS location */
  getLocationDetails(): boolean {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.getLocationDetails) return false;
    AppWebBridge.getLocationDetails();
    return true;
  }

  cleverTapPushEvent(event: string, payload?: string): boolean {
    if (this.conn.getActingUser() && !this.conn.isInternalUser()) {
      this.conn.trackEventInCleverTap(event, this.conn.getActingUser()?.get('username'), JSON.parse(payload || '{}'));
    } else {
      if (!this.isAppWebBridgeLoaded() || (this.isAppWebBridgeLoaded() && !AppWebBridge.pushEvent)) {
        return false;
      }
      if (payload) AppWebBridge.pushEvent(event, payload);
      if (!payload) AppWebBridge.pushEvent(event);
    }
    return true;
  }

  /** Requests native to take PlayStore review */
  requestAppReview(): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestAppReview) return;
    AppWebBridge.requestAppReview();
  }

  /** Request native to clear the history */
  clearHistory(): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.requestClearHistory) return;
    AppWebBridge.requestClearHistory();
  }

  startLiveSession(sessionId: string): void {
    if (!this.isAppWebBridgeLoaded() || !AppWebBridge.startLiveSession) return;
    AppWebBridge.startLiveSession(sessionId);
  }

  private getChatType(url: string): string {
    if (url.startsWith('/chatV2')) {
      return url.split('?')[1]?.split('&').find((each: string): boolean => each.startsWith('type'))?.split('=')[1];
    }
    if (url.startsWith('/support/ticket/')) {
      return 'supportTicket';
    }
    if (url[0] !== '/') {
      return url;
    }
    return '';
  }
}
