import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { RegimenConcernSelectionModule } from '@shared/regimen-concern-selection/regimen-concern-selection.module';
import { OnboardingConcernComponent } from './onboarding-concern.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoadingModule,
    ButtonModule,
    RegimenConcernSelectionModule,
  ],
  declarations: [OnboardingConcernComponent],
  exports: [OnboardingConcernComponent],
})
export class OnboardingConcernModule {
}
