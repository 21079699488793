import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../app.config';

@Component({ selector: 'report-card', templateUrl: './report-card.html', styleUrls: ['./report-card.scss'] })
export class ReportCardComponent {
  loading: boolean = true;
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Input('data')
  set followUpReport(report: any) {
    this.report = report;
    this.isNew = (new Date().getTime() - report.get('createdAt').getTime()) <= 604800000;
  }
  report: any;
  isNew: any;
  user: any;

  constructor(private dom: DomSanitizer,
    private conn: ConnectionService,
    private router: Router,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
  }

  openReport(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'view-report' }));
    this.router.navigate([`/user/report/${this.report.id}`]);
  }
}
