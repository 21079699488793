import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading';
import { ToolbarModule } from '@components/toolbar';
import { TranslateStringsModule } from '@components/translate-string';
import { PopUpModalModule } from '@components/pop-up-modal';
import { ButtonModule } from '@components/button';
import { ProductListModule } from '@shared/product-list/product-list.module';
import { CsIconModule } from '@components/icon';
import { UserOrderViewComponent } from './user-order-view.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    LoadingModule,
    ButtonModule,
    ToolbarModule,
    TranslateStringsModule,
    PopUpModalModule,
    ProductListModule,
    CsIconModule,
  ],
  declarations: [
    UserOrderViewComponent,
  ],
  exports: [
    UserOrderViewComponent,
  ],
})
export class UserOrderViewModule {}
