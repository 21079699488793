<ng-container *ngIf="isHairCrossSellBanner && hairRegimen then hairCrossSellBannerTemplate else faceCrossSellBannerTemplate"></ng-container>

<ng-template #faceCrossSellBannerTemplate>
<section class="tw-p-4 tw-bg-white tw-relative" (click)="openBanner()" *ngIf="crossSellRegimen && bannerStyle.backgroundImage && !incompleteChat && !isNewCrossRegimenBannerActive" >
  <img class="tw-rounded-2.5 tw-h-auto tw-w-full" [src]="this.bannerStyle.backgroundImage" />
  <div class="tw-w-[65%] tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-justify-evenly tw-flex tw-flex-col tw-p-4 tw-ml-4" *ngIf="hairRegimen && !isHairCrossSellBanner">
    <div class="tw-bg-[#77549c] tw-bg-left-top tw-bg-no-repeat tw-bg-clip-padding tw-rounded-[10px] tw-opacity-100 tw-text-center tw-tracking-[0.26px] tw-text-[#ffffff] tw-pt-[2px] tw-w-fit tw-uppercase tw-font-body tw-text-200 tw-px-2 tw-py-1" i18n="@@limitedTimeOffer">
      <span>Limited Period &nbsp;</span><b>Offer</b>
    </div>
    <p class="tw-font-bold tw-h-12 tw-text-left tw-tracking-normal tw-text-gray-900 tw-opacity-100 " style="font-size: 10vw; margin: unset; line-break: auto;" i18n="@@flatOffCross">
      ₹{{regimenData?.regimenPrice - regimenData?.discountedSP}} OFF
    </p>
    <p class="tw-text-left tw-font-normal tw-not-italic tw-normal-nums tw-text-[16px] tw-leading-[22px] font-noto tw-tracking-normal tw-text-gray-900 tw-opacity-100 tw-relative tw-bottom-[10px] tw-font-body tw-text-400 tw-font-body tw-text-400" style="margin: unset; line-break: auto;"  i18n="@@onHaircareKit">
      on your haircare kit
    </p>
    <div class="tw-border tw-border-solid tw-border-[#707070] tw-w-[80%]"></div>
    <span class="tw-font-body tw-text-100" i18n="@@offerValidTillCross"><span>Offer valid till&nbsp;</span><span><span class="fw-700">{{validTillDate | date: 'd' | dateSuffix}}&nbsp;</span><span class="fw-700">{{validTillDate | date: 'MMMM'}}</span></span></span>
  </div>
  <div class="tw-w-[65%] tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-justify-evenly tw-flex tw-flex-col tw-p-4 tw-ml-4" *ngIf="faceRegimen">
    <div class="tw-bg-[#77549c] tw-bg-left-top tw-bg-no-repeat tw-bg-clip-padding tw-rounded-[10px] tw-opacity-100 tw-text-center tw-tracking-[0.26px] tw-text-[#ffffff] tw-pt-[2px] tw-w-fit tw-uppercase tw-font-body tw-text-200 tw-px-2 tw-py-1" i18n="@@limitedTimeOffer">
      <span>Limited Period &nbsp;</span><b>Offer</b>
    </div>
    <p class="tw-font-bold tw-h-12 tw-text-left tw-tracking-normal tw-text-gray-900 tw-opacity-100" style="font-size: 10vw; margin: unset; line-break: auto;" i18n="@@flatOffCross">
      ₹{{regimenData?.regimenPrice - regimenData?.discountedSP}} OFF
    </p>
    <p class="tw-text-left tw-font-normal tw-not-italic tw-normal-nums tw-text-[16px] tw-leading-[22px] font-noto tw-tracking-normal tw-text-gray-900 tw-opacity-100 tw-relative tw-bottom-[10px] tw-font-body tw-text-400 tw-font-body tw-text-400" style="margin: unset; line-break: auto;" i18n="@@onSkincareKit">
      on your skincare kit
    </p>
    <div class="tw-border tw-border-solid tw-border-[#707070] tw-w-[80%]"></div>
    <span class="tw-font-body tw-text-100" i18n="@@offerValidTillCross"><span>Offer valid till&nbsp;</span><span><span class="fw-700">{{validTillDate | date: 'd' | dateSuffix}}&nbsp;</span><span class="fw-700">{{validTillDate | date: 'MMMM'}}</span></span></span>
  </div>
</section>
<div class="tw-flex tw-items-center tw-justify-center tw-h-14 tw-relative tw-bg-orange-100" *ngIf="incompleteChat && crossSellRegimen">
  <div class="tw-font-body tw-text-200 tw-text-black tw-py-2 tw-px-6" i18n="@@completeChatToday">
  <span>Complete your chat today & get second treatment kit at special price of </span><span class="fw-700">₹{{regimenData?.discountedSP}} </span><span>only! </span>
  </div>
</div>
</ng-template>

<ng-template #hairCrossSellBannerTemplate>
  <div class="tw-mt-4" *ngIf="crossSellRegimen && !incompleteChat">
    <hair-thinning-banner *ngIf="isHairThinningCrossSellBanner && userGender === 'Female'" (getDirectHairRegimen)="getDirectHairRegimen()"></hair-thinning-banner>
    <hair-fall-banner *ngIf="isHairFallCrossSellBanner && userGender === 'Female'"  (getDirectHairRegimen)="getDirectHairRegimen()"></hair-fall-banner>
    <hair-fall-male-banner *ngIf="isHairFallCrossSellMaleBanner && userGender === 'Male'" (getDirectHairRegimen)="getDirectHairRegimen()"></hair-fall-male-banner>
    <hair-dandruff-banner *ngIf="isHairDandruffCrossSellBanner && userGender === 'Female'" (getDirectHairRegimen)="getDirectHairRegimen()"></hair-dandruff-banner>
  </div>
  <div class="tw-flex tw-items-center tw-justify-center tw-h-14 tw-relative tw-bg-orange-100" *ngIf="incompleteChat && crossSellRegimen">
    <div class="tw-font-body tw-text-200 tw-text-black tw-py-2 tw-px-6" i18n="@@completeChatToday">
    <span>Complete your chat today & get second treatment kit at special price of </span><span class="fw-700">₹{{regimenData?.discountedSP}} </span><span>only! </span>
    </div>
  </div>
</ng-template>
