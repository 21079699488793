import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  private isPrepaidDiscountShown: boolean = false;
  constructor() {
  }

  getPrepaidDiscountShownState(): boolean {
    return this.isPrepaidDiscountShown;
  }

  prepaidDiscountShown(): void {
    this.isPrepaidDiscountShown = true;
  }
}
