import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { UserOrderAddonComponent } from './user-order-addon.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    LoadingModule,
    ButtonModule,
  ],
  declarations: [
    UserOrderAddonComponent,
  ],
  exports: [
    UserOrderAddonComponent,
  ],
})
export class UserOrderAddonModule {}
