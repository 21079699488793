import { Component } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LocalStorageService } from '@services/local-storage-service';

@Component({
  selector: 'insta-live-info',
  templateUrl: './insta-live-info.html',
  styleUrls: ['./insta-live-info.scss'],
})
export class InstaLiveInfoComponent {
  instaLiveInfo: any = {};
  isUpcoming: boolean = false;
  isLive: boolean = false;
  isExpired: boolean = false;
  isNotified: boolean = false;

  constructor(
    private conn: ConnectionService,
    private broadcast: BroadcastService,
    private eventLogger: EventLoggerService,
    private localStorage: LocalStorageService) {}

  async ngOnInit(): Promise<void> {
    const infoArray = await this.conn.getInstaLiveUpdate();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'insta-live-info' }));
    if (infoArray.length) {
      this.instaLiveInfo = infoArray[0];
      this.findTheLiveStatus();
      const status = this.localStorage.getValue('notify-me');
      if (status) this.isNotified = true;
    }
  }

  findTheLiveStatus(): void {
    if (this.instaLiveInfo) {
      const showFrom = this.instaLiveInfo.get('showFrom');
      const showTill = this.instaLiveInfo.get('showTill');
      const startTime = this.instaLiveInfo.get('startTime');
      const currentTime = new Date();
      if (currentTime > startTime && currentTime < showTill) {
        this.isLive = true;
      } else if (showFrom < currentTime && currentTime < showTill) {
        this.isUpcoming = true;
      } else if (currentTime > showTill) {
        this.isExpired = true;
      }
    }
  }

  redirectToInstagram(): void {
    const instaLink = this.instaLiveInfo?.get('instaLink');
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'insta-live-info-open' }));
    if (instaLink) this.broadcast.broadcast('OPEN_IN_NEW_TAB', { url: instaLink });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  notifyClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'notify-me-insta' }));
    this.localStorage.setValue('notify-me', true);
    setTimeout((): void => {
      this.isNotified = true;
    }, 500);
  }
}
