import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AppConfig } from 'client/app/app.config';
import { ConnectionService } from '@services/connection-service';
import { DataStoreService } from '@services/data-store-service';
import { EventLoggerService } from '@services/event-logger-service';
import { ActivatedRoute } from '@angular/router';
import { WindowRefService } from '@services/window-ref-service';

@Component({
  selector: 'app-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqSectionComponent implements OnInit {
  faqs: any[];
  selectedFaqs: any[] = [];
  selectedFaqIds: string[] = ['PD1gFUfQUw', 'mz40L0ijTC', '2jOHeE1FGg', 'hCVzbasqDl', 'MUfQDuPfvI', 'u7SgiPTWZo', 'mEYqVnFsP8'];
  loading: boolean = false;
  pageRoute: string = this.route.snapshot.queryParams?.tab;

  constructor(
    public appConfig: AppConfig,
    public conn: ConnectionService,
    private dataStore: DataStoreService,
    private changeDetector: ChangeDetectorRef,
    private logger: EventLoggerService,
    private route: ActivatedRoute,
    private windowRef: WindowRefService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.loadFaqs();
    this.changeDetector.detectChanges();
  }

  async loadFaqs(): Promise<any> {
    this.loading = true;
    const data = await this.conn.fetchFAQ();
    this.faqs = data?.faqs?.filter((faq: any): boolean => this.selectedFaqIds?.includes(faq.id));
    this.dataStore.set('FAQS', data);
    this.loading = false;
  }

  expandCollapse(index: any, container: any): any {
    const parent = container.querySelector(`#faq-${index}`);
    const arrow = parent.querySelector('.cs-icon-arrow');
    if (arrow.style.transform === 'rotate(270deg)') {
      arrow.style.transform = 'rotate(90deg)';
    } else {
      arrow.style.transform = 'rotate(270deg)';
    }
    arrow.parentElement.parentElement.nextElementSibling.classList.toggle('tw-hidden');
    this.logger.cleverTapEvent('click', JSON.stringify({ pageName: `FAQ_clicked_${this.pageRoute}page` }));
  }

  viewAllFaqs(): void {
    this.conn.navigateToURL('/faq');
    this.logger.cleverTapEvent('click', JSON.stringify({ pageName: `ViewMore_FAQ_${this.pageRoute}page` }));
  }
}
