import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { LoadingModule } from '@components/loading';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { ToolbarModule } from '@components/toolbar';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { DirectivesModule } from '@directives/directives.module';
import { PopUpModule } from '@components/pop-up';
import { CsIconModule } from '@components/icon';
import { UserWalletViewComponent } from './user-wallet-view/user-wallet-view.component';
import { UserWalletTransactionsComponent } from './user-wallet-transactions/user-wallet-transactions.component';

@NgModule({ imports: [CommonModule,
  ButtonModule,
  LoadingModule,
  ToolbarTabsModule,
  ToolbarModule,
  DirectivesModule,
  CustomPipeModule,
  PopUpModule,
  CsIconModule,
],
declarations: [UserWalletViewComponent, UserWalletTransactionsComponent] })

export class UserWalletModule {}
