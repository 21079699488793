import { Component } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';

@Component({ selector: 'cureskin-star', templateUrl: './cureskin-star.html' })
export class CureskinStarComponent {
  user: any;
  static parameters: any = [BroadcastService, ConnectionService];
  constructor(private broadcast: BroadcastService, private conn: ConnectionService) {
    this.user = this.conn.getActingUser();
  }

  open(url: any): void {
    this.conn.navigateToURL(url);
  }

  async agree(agreed: boolean): Promise<any> {
    this.user.set('interestedInCureSkinStar', agreed);
    await this.user.save();
    if (agreed) {
      this.broadcast.broadcast('NOTIFY', { message: 'Thanks, We will get back.' });
    }
    this.back();
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
