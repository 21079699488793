import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { LoadingModule } from '@components/loading';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { RegimenPropositionModule } from '@shared/regimen-proposition/regimen-proposition.module';
import { ChoosePlanInfoComponent } from './choose-plan-info.component';
import { UserRegimenModule } from '../../user/user-regimen/user-regimen.module';

@NgModule({
  imports: [CommonModule, CsIconModule, ToolbarTabsModule, UserRegimenModule, LoadingModule, RegimenPropositionModule],
  declarations: [ChoosePlanInfoComponent],
  exports: [ChoosePlanInfoComponent],
})

export class ChoosePlanInfoModule {}
