import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { PopUpModule } from '@components/pop-up';
import { CsIconModule } from '@components/icon';
import { DirectivesModule } from '@directives/directives.module';
import { UserReferComponent } from './user-refer.component';

@NgModule({ imports: [
  ButtonModule,
  CommonModule,
  DirectivesModule,
  ToolbarModule,
  ToolbarTabsModule,
  RouterModule,
  CsIconModule,
  PopUpModule,
],
declarations: [UserReferComponent],
exports: [] })

export class UserReferModule {}
