import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/index';
import { Store } from '@ngrx/store';
import { ParseKeys } from 'api-client';
import { fromActions } from '@store/actions';
import { fromSelectors } from '@store/selectors';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { CurrentComponentService } from '@services/current-component';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { BroadcastService } from '@services/broadcast-service';
import { TimeService } from '@services/time-service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NotificationPermissionSheetComponent } from
  '@shared/bottom-sheet-layouts/notification-permission-sheet/notification-permission-sheet.component';
import { ExperimentService } from '@services/experiment';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'user-order-success',
  templateUrl: './user-order-success.html',
})

export class UserOrderSuccessComponent implements OnInit, OnDestroy {
  showSurpriseGift$: Observable<boolean> = this.store.select(fromSelectors.selectOrderSurpriseGiftClaimPending);
  referralCashValue$: Observable<number> = this.store.select(fromSelectors.selectReferralCashValue);
  expectedDeliveryDate: { from?: Date, to?: Date } = {};
  deliveryIntervalInDays: number = 2;
  user: any;
  isNotificationPermissionEnabled: any;
  experiments: any;
  isRebrandingV1: boolean = false;
  isConsulationOrder: boolean = false;
  orderId: string;
  excludeCategory: Array<string> = ['RETENTION'];

  constructor(private route: ActivatedRoute,
    private windowRef: WindowRefService,
    private eventLogger: EventLoggerService,
    private appBridge: AppWebBridgeService,
    private conn: ConnectionService,
    private appConfig: AppConfig,
    private router: Router,
    private localStorageService: LocalStorageService,
    private currentComponent: CurrentComponentService,
    private commonUtilityHelper: CommonUtilityHelper,
    private broadcast: BroadcastService,
    private store: Store,
    private timeService: TimeService,
    private bottomSheet: MatBottomSheet,
    private experimentService: ExperimentService) {
    this.currentComponent.set(this);
    this.isRebrandingV1 = this.experimentService.isRebrandingV1Phase2;
    // this.commonUtilityHelper.showPaperBombAnimation(1000);
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    this.store.dispatch(fromActions.OrderSuccessBegin());
    this.localStorageService.delete('freeProduct');
    this.callGokWickSuccess();
    this.setEventOnProductsOrder();
    this.setEventOnRegimenOrder();
    this.expectedDeliveryDate = { from: this.timeService.addDays(new Date(), 2),
      to: this.timeService.addDays(new Date(), 2 + this.deliveryIntervalInDays) };
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'order-success' }));
    this.experiments = await this.conn.findUserActiveExperiments();
    this.isConsulationOrder = this.route.snapshot.queryParams?.orderType === this.appConfig.Shared.Order.Type.CONSULTATION;
    await this.logPurchaseEvent();
    const notificationExperiment = this.experiments.find((item:any):any => item.key === 'notification_permission_popup');
    if (notificationExperiment) {
      setTimeout((): void => this.openBottomSheet(), 1000);
    }

    if (!this.isRebrandingV1) {
      this.experiments.forEach((exp: any): void => {
        if (exp.key === 'rebranding_v1_phase_2') {
          this.isRebrandingV1 = true;
        }
      });
    }
  }

  async logPurchaseEvent(): Promise<void> {
    const isFemaleUser = this.user?.get('Gender') === this.appConfig.Shared.Gender.FEMALE;
    const isRegimenOrder = this.route.snapshot.queryParams?.orderType === this.appConfig.Shared.Order.Type.REGIMEN;
    const where: any = { user: this.user };
    const select: Array<ParseKeys<Table.Order>> = ['category'];
    const orders = await this.conn.fetchOrders(where, select, []);
    this.orderId = this.route.snapshot.queryParams?.orderId;
    const order = orders.find((each: any): any => each.objectId === this.orderId);
    const isAcquisitionOrder = !this.excludeCategory.includes(order?.get('category'));
    if (isRegimenOrder && isAcquisitionOrder) {
      this.appBridge.logEventInBranchAndFirebaseFromiOS({
        branch: { name: 'PURCHASE' },
        firebase: { name: 'purchase' },
      });
      if (isFemaleUser) {
        this.appBridge.logEventInBranchAndFirebaseFromiOS({
          branch: { name: 'PurchaseW1845' },
          firebase: { name: 'PurchaseW1845' },
        });
      }
    }
  }

  openBottomSheet(): void {
    // call function to check permission status true or false
    // if true then perimssionn already given
    this.isNotificationPermissionEnabled = this.appBridge.requestNotificationStatus();
    if (!this.isNotificationPermissionEnabled) {
      this.bottomSheet.open(NotificationPermissionSheetComponent, {
        data: {
          content: 'order-placed',
          callback: (): void => {
            this.openNativePermissionPopup();
          },
        },
      });
    }
  }

  openNativePermissionPopup(): void {
    this.appBridge.requestNotificationPermission();
  }

  navigateTo(url: string): void {
    this.conn.navigateToURL(url, false, true);
  }
  /**
 * To track product orders
 */
  setEventOnProductsOrder(): void {
    if (this.route.snapshot.queryParams?.orderType === this.appConfig.Shared.Order.Type.PRODUCT) {
      this.eventLogger.trackInFirebaseAndBranch('PRODUCT_CLICKED', {
        transaction_id: this.route.snapshot.queryParams?.orderId,
        currency: 'INR',
        value: this.route.snapshot.queryParams?.amount,
      });
    }
  }
  /**
 * To track retention regimen orders
 */
  setEventOnRegimenOrder(): void {
    if (this.route.snapshot.queryParams?.orderType === this.appConfig.Shared.Order.Type.REGIMEN && this.user.isPaid()) {
      this.eventLogger.trackInFirebaseAndBranch('REGIMEN_CLICKED');
    }
  }

  async handleBackPress(): Promise<boolean> {
    this.conn.navigateToURL('/user?tab=home', false, true);
    return true;
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void {
    this.currentComponent.remove(this);
  }

  private callGokWickSuccess(): void {
    const { gokWik: gokWikParam }: { gokWik: string } = this.route.snapshot.queryParams as { gokWik: string };
    if (!gokWikParam) {
      return;
    }
    const gokWikJSON = JSON.parse(decodeURIComponent(gokWikParam));
    if (!gokWikJSON.request_id) {
      return;
    }
    this.appBridge.requestGokWikSuccess(gokWikJSON);
  }
}
