import { Injectable, NgZone, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { v4 as uuid } from 'uuid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TimeService } from '@services/time-service';
import { fromActions } from '@store/actions';
import { CacheService } from '@services/cache-service';
import { ConcernsWithCount } from 'client/app/report/report.component';
import { prescriptionVar } from 'e2e/src/shared/constants';
import { Table, ApiConnector, ParseKeys, apiObjectToJSONs, RequestQueryPayload, ServerApi, Models } from 'api-client';
import { ServerAPIResponse } from 'client/app/server-api.constants';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { GetMainConcernsConcernClassEnum } from 'api-client/src/server-api';
import { AppConfig } from '../../app/app.config';
import { WindowRefService } from '../window-ref-service';
import { LocalStorageService } from '../local-storage-service';
import { CookieService } from '../cookie-service';
import { BroadcastService } from '../broadcast-service';
import { DataStoreService } from '../data-store-service';
import { AuthConfig } from '../../app/app-constant-types';
import { generateDigest } from '../utility';

@Injectable()
export class ConnectionService {
  private actingUser: any;
  uniqueId: string;
  serverApi: ServerApi;
  private doctorName: string = '';
  isVerified: boolean = false;
  private doctorSignature: string = '';
  regimenRefreshInterval: number = 2 * 60 * 1000;
  allowRegimenForceFetch: boolean = true;
  prescriptionVar: any = prescriptionVar;
  isVariantAlreadySelected: boolean = false;
  tagCacheTableMapping: Record<string, string> = AppConfig.TagCacheTableMapping;
  user: any;
  private appWebBridge: AppWebBridgeService;
  constructor(private appConfig: AppConfig,
    private cookieService: CookieService,
    private windowRef: WindowRefService,
    private localStorage: LocalStorageService,
    private router: Router,
    private httpClientService: HttpClient,
    private broadcast: BroadcastService,
    private zone: NgZone,
    private dataStore: DataStoreService,
    private timeService: TimeService,
    private localStorageService: LocalStorageService,
    private store: Store,
    private cacheService: CacheService,
    private injector: Injector) {
    this.updateUniqueId();
  }

  // ************************************ API UTILITY FUNCTIONS ************************************** //

  /**
   * Initializes the parse sdk with the api server url.
   * 1. Looks for session token in cookies and tries to login with that if user is not logged in.
   * 2. Updates few data in cookies.
   * 3. Fetches the updated user data from api, routes to onboarding is api fails fetching user.
   */
  initialize(): any {
    ApiConnector.initialize('myAppId', `${this.getParseUrl()}/api/parse`, { disableSingleInstance: true });
    ApiConnector.updateInterceptor(true);
    this.serverApi = new ServerApi(undefined, this.getParseUrl());
    this.updateUserFromCookies();
    this.updateCookies();
    this.updateLocalUser();
    this.resetLocalStorageData();
    this.user = this.getActingUser();
  }

  private getAppBridgeService(): AppWebBridgeService {
    if (!this.appWebBridge) {
      this.appWebBridge = this.injector.get(AppWebBridgeService);
    }
    return this.appWebBridge;
  }

  resetLocalStorageData(): void {
    this.localStorage.setJsonValue('CureSkin/orderStages', []);
  }

  get isUserLoggedIn(): boolean {
    return !!(this.getCurrentUser() && this.getCurrentUser().get('sessionToken'));
  }

  get isTestEnvironment(): boolean {
    const { hostname }: { hostname: string } = this.windowRef.nativeWindow.location;
    return hostname.startsWith('test')
      || hostname.startsWith('localhost')
      || hostname.includes('dev-app')
      || this.isDevelopmentEnvironment;
  }

  get isDevelopmentEnvironment(): boolean {
    return this.appConfig.Shared.Server.env === 'development';
  }

  getParseUrl(): string {
    let url;
    switch (this.windowRef.nativeWindow.location.hostname) {
      case 'test-app.cureskin.com': {
        url = 'https://test-api.cureskin.com';
        break;
      }
      case 'app.cureskin.com': {
        url = 'https://api.cureskin.com';
        break;
      }
      case 'staging-app.cureskin.com': {
        url = 'https://staging-api.cureskin.com';
        break;
      }
      case 'localhost': {
        url = 'https://test-api.cureskin.com';
        break;
      }
      case 'canary-app.cureskin.com': {
        url = 'https://canary-api.cureskin.com';
        break;
      }
      case 'rc-app.cureskin.com': {
        url = 'https://rc-api.cureskin.com';
        break;
      }
      default: {
        if (this.windowRef.nativeWindow.location.hostname.includes('.dev-app.cureskin.com')) {
          url = `https://${this.windowRef.nativeWindow.location.hostname.replace('.dev-app.cureskin.com', '.dev-api.cureskin.com')}`;
          break;
        }
        url = `http://${this.windowRef.nativeWindow.location.hostname}:9000`;
      }
    }
    return url;
  }

  getBaseUrl(): string {
    let url = `${this.windowRef.nativeWindow.location.protocol}//${this.windowRef.nativeWindow.location.hostname}`;
    if (this.windowRef.nativeWindow.location.port) url = `${url}:${this.windowRef.nativeWindow.location.port}`;
    return url;
  }

  getAiUrl(): string {
    return 'https://analyze.cureskin.com/uploadImageInstantCheckup';
  }

  userWithoutPrivateMainConcern(): boolean {
    const user = this.getActingUser();
    return this.isUserLoggedIn
    && !this.isInternalUser()
    && !user.get('PrivateMainConcern')
    && user.get('mainConcernMandatory');
  }

  get analyticsUrl(): string {
    let url;
    switch (this.windowRef.nativeWindow.location.hostname) {
      case 'app.cureskin.com': {
        url = 'https://analytics.cureskin.com';
        break;
      }
      case 'localhost': {
        url = `http://${this.windowRef.nativeWindow.location.hostname}:9003`;
        break;
      }
      case 'test-app.cureskin.com':
      default: {
        url = 'https://analytics.cureskin.com';
        break;
      }
    }
    return url;
  }

  login(username: string, password: string): Promise<Table.User> {
    return ApiConnector.userLogIn<Table.User>(username, password);
  }

  getCurrentUser(): any {
    const user = ApiConnector.getCurrentUser() as Table.User & {isPaid?: boolean};
    if (user && !user.isPaid) {
      user.isPaid = this.isPaid.call(user, this);
    }
    return user;
  }

  get getUserLanguage(): string {
    return this.getCurrentUser()?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
  }

  getValueOfLanguageString(languageString: any): string {
    if (languageString.id) return languageString.get(this.getUserLanguage) || languageString.get(this.appConfig.Shared.Languages.EN);
    return languageString[this.getUserLanguage] || languageString[this.appConfig.Shared.Languages.EN];
  }

  /**
   * Parse the relative url and queryparams out of full url string.
   * @param {string} path
   * @returns {[string , object]}
   */
  findRelativePathAndQueryString(path: string): [string, object] {
    const [relativePath, queryString]: Array<string> = (path || '').split('?');
    const queryParams = {};
    if (queryString) {
      const keyValues = queryString.split('&');
      keyValues.forEach((keyValue: string): void => {
        const [key, value]: Array<string> = keyValue.split('=');
        queryParams[key] = decodeURIComponent(value || '');
      });
    }
    return [relativePath, queryParams];
  }

  async redirectToLastKnowLocation(defaultUrl: string = '/', replaceUrl: boolean = undefined): Promise<void> {
    const redirectUrl: string = this.localStorage.getValue('CureSkin/redirectUrl') || defaultUrl;
    this.localStorage.delete('CureSkin/redirectUrl');
    await this.navigateToURL(redirectUrl, undefined, replaceUrl);
  }

  /**
   * Checks for user preferred language and if current website is not in that language code,
   * then it route the user to specific website with same route url.
   * @param {string} url
   * @returns {Promise<void>}
   */
  async checkUserLanguageAndRedirect(url?: string): Promise<void> {
    const user = this.getActingUser();
    if (!user || this.isInternalUser()) return;
    let urlPrefix = `${this.getBaseUrl()}`;
    const redirectTo = this.findRoutePath();
    if (!this.isDevelopmentEnvironment
      && user.get('languagePreference')
      && user.get('languagePreference') !== this.appConfig.Shared.Languages.EN
      && !this.windowRef.nativeWindow.location.pathname.startsWith(`/${user.get('languagePreference')}`)) {
      urlPrefix += `/${user.get('languagePreference')}`;
      if (url) this.windowRef.nativeWindow.location.href = `${urlPrefix}${url}`;
      else if (!url && redirectTo) this.windowRef.nativeWindow.location.href = `${urlPrefix}/${redirectTo}`;
      else this.windowRef.nativeWindow.location.href = `${urlPrefix}/`;
      return;
    }
    if (this.currentWebSiteLanguage === user.get('languagePreference') && !url) return;
    if (url) this.navigateToURL(url);
    else if (!url && redirectTo) this.navigateToURL(`/${redirectTo}`);
  }

  findRoutePath(): any {
    let routePath = this.windowRef.nativeWindow.location.pathname.substr(1) + this.windowRef.nativeWindow.location.search;
    const languages = Object.keys(this.appConfig.Shared.Languages).map((each: any): string => `/${each.toLowerCase()}/`);
    languages.forEach((each: string): void => {
      if (routePath.startsWith(each)) {
        const split = routePath.split(each);
        split.splice(0, 1);
        routePath = split.join('');
      }
    });
    return routePath;
  }

  /**
   * Helper function to find whether user is paid user or not. This can be accesses in 2 ways.
   * 1. this.conn.isPaid(userParseObject);
   * 2. this.userParseObject.isPaid();
   * This function to binded to user parse object in all places where all user object is fetched.
   * For example, getCurrentUser(), findUserByObjectId(), findUserByUserName().
   * @param argument
   * @returns {any}
   */
  isPaid(argument: any): any {
    let scope;
    let user;
    if (argument.className === '_User') {
      scope = this;
      user = argument;
    } else {
      scope = argument;
      user = this;
    }
    const exec = ((): boolean => ![
      scope.appConfig.Shared.User.OrderState.UNPAID,
      scope.appConfig.Shared.User.OrderState.NEW_USER,
    ].includes(user.get('orderState')));
    if (argument.className === '_User') return exec(); // returns boolean. eg: this.conn.isPaid(anyUser)
    return exec; // returns function when its binded. eg: userParseObject.isPaid()
  }

  get s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  updateUniqueId(): void {
    this.uniqueId = this.localStorage.getValue('user/es/id');
    if (this.uniqueId) return;
    this.uniqueId = this.s4 + this.s4 + this.s4 + this.s4 + this.s4;
    this.localStorage.setValue('user/es/id', this.uniqueId);
  }

  addScript(scriptUrl: string, onLoadCallback?: Function): void {
    const scriptTags = this.windowRef.nativeWindow.document.getElementsByTagName('script');
    let scriptAdded: boolean = false;
    for (let i = 0; i < scriptTags.length; i += 1) {
      if (scriptUrl === scriptTags[i].src) {
        scriptAdded = true;
      }
    }
    if (scriptAdded) return;
    const script = this.windowRef.nativeWindow.document.createElement('script');
    script.src = scriptUrl;
    script.defer = true;
    script.type = 'text/javascript';
    if (onLoadCallback instanceof Function) script.onload = onLoadCallback;
    this.windowRef.nativeWindow.document.head.appendChild(script);
  }

  addStyle(styleUrl: string, rel: string = 'stylesheet'): void {
    const linkTags = this.windowRef.nativeWindow.document.getElementsByTagName('link');
    let linkAdded: boolean = false;
    for (let i = 0; i < linkTags.length; i += 1) {
      if (styleUrl === linkTags[i].href) {
        linkAdded = true;
      }
    }
    if (linkAdded) return;
    const link = this.windowRef.nativeWindow.document.createElement('link');
    link.rel = rel;
    if (rel === 'stylesheet') {
      link.type = 'text/css';
    }
    link.href = styleUrl;
    this.windowRef.nativeWindow.document.head.appendChild(link);
  }

  removeStyle(styleUrl: string): void {
    const linkTags = this.windowRef.nativeWindow.document.getElementsByTagName('link');
    for (let i = 0; i < linkTags.length; i += 1) {
      if (styleUrl === linkTags[i].href) {
        const mynode = linkTags[i];
        mynode.parentNode.removeChild(mynode);
      }
    }
  }

  removeScript(url: string): void {
    const linkTags = this.windowRef.nativeWindow.document.getElementsByTagName('script');
    for (let i = 0; i < linkTags.length; i += 1) {
      if (linkTags[i].src.includes(url)) {
        const mynode = linkTags[i];
        mynode.parentNode.removeChild(mynode);
      }
    }
  }

  getSessionLessId(ignoreUserLogin: boolean = false): string {
    if (this.isUserLoggedIn && !ignoreUserLogin) return this.getActingUser().get('username');
    const interApplicationTrackingId = this.localStorage.getValue('user/capture/id');
    if (interApplicationTrackingId) {
      return interApplicationTrackingId;
    }
    return this.cookieService.get('deviceId') || this.uniqueId;
  }

  /**
   * Helper function to navigate to a specific route with a full route string.
   * It parses the route path, queryParams out from url and sends to router.
   * @param {string} url - route string.
   * @param {boolean} newTab - if true, it will open the external site url in iframe.
   * @param {boolean} replaceUrl - over writes the current url in the history state.
   * @returns {Promise<any>}
   */
  async navigateToURL(url: string, newTab: boolean = false, replaceUrl: boolean = undefined): Promise<any> {
    if (newTab) {
      await this.zone.run((): Promise<boolean> => this.router.navigate(['/iframe'], { queryParams: { src: url } }));
      return;
    }
    if (['/',
      this.windowRef.nativeWindow.location.origin,
      'https://test-app.cureskin.com',
      'https://app.cureskin.com',
      'https://staging-app.cureskin.com']
      .every((x: string): boolean => !url.startsWith(x))) {
      await this.zone.run((): Promise<boolean> => this.router.navigate(['/iframe'], { queryParams: { src: url } }));
      return;
    }
    const path = url.startsWith('http') ? `/${url.split('/').splice(3, 100).join('/')}` : url;
    const [relativePath, queryParams]: [string, object] = this.findRelativePathAndQueryString(path);
    /** If requested path is same as current page path,
     * then it routes to /empty module and back to requested path in order to trigger the route change for same url.
     */
    if (this.windowRef.nativeWindow.location.href.endsWith(path)) {
      await new Promise(async (resolve: any, reject: any): Promise<any> => {
        try {
          await this.zone.run(async (): Promise<boolean> => this.router.navigate(['/empty'], { skipLocationChange: true }));
          const relativePathArray = ['', '/'].includes(relativePath) ? ['/'] : relativePath.split('/');
          await this.zone.run(async (): Promise<boolean> => this.router.navigate(relativePathArray, { queryParams }));
          resolve();
        } catch (err) {
          reject(err);
        }
      });
      return;
    }
    const relativePathArray = ['', '/'].includes(relativePath) ? ['/'] : relativePath.split('/');
    await this.zone.run((): Promise<boolean> => this.router.navigate(relativePathArray, { queryParams, replaceUrl }));
  }

  // ************************************ API ************************************** //

  /**
   * Custom Parse api call interceptor.
   */
  async apiWall(requestPromise: () => Promise<any>): Promise<any> {
    try {
      return await requestPromise();
    } catch (error) {
      if (error.code === 209 || error.code === 206) {
        this.logout();
        await this.router.navigate(['/onboarding/number']);
      } else if (error.code === 100 && error.message.includes('Unable to connect to the Parse API')) {
        this.broadcast.broadcast('CHECK_NETWORK_CONNECTION');
      }
      return Promise.reject(error);
    }
  }

  updateCookies(): void {
    this.updateInstallationId();
    if (this.isUserLoggedIn) {
      this.cookieService.setUser(this.getCurrentUser());
    }
    this.cookieService.set('Parse/myAppId/url', this.windowRef.nativeWindow.location.hostname);
  }

  updateInstallationId(): void {
    const installationId = this.cookieService.get('installationId');
    if (!installationId) return;
    this.localStorage.setValue('Parse/myAppId/installationId', installationId);
  }

  /**
   * Fetcfhes the session token from cookie and tries to login the user from that if user s not logged in.
   * @returns {Promise<any>}
   */
  async updateUserFromCookies(): Promise<any> {
    if (!this.cookieService.get('token')) return;
    if (this.isUserLoggedIn && this.cookieService.get('token') === this.getCurrentUser().getSessionToken()) {
      this.cookieService.delete('token');
      return;
    }
    const sessionToken = this.cookieService.get('token');
    try {
      await this.loginWithTokenAndUpdateUserInfo(sessionToken);
      this.updateCookies();
      await this.redirectToLastKnowLocation();
      this.windowRef.nativeWindow.location.reload();
    } catch (err) {
      // eslint-disable-next-line angular/log,no-console
      console.log(err);
    } finally {
      this.cookieService.delete('token');
    }
  }

  logout(): void {
    if (this.isUserLoggedIn) {
      ApiConnector.userLogout();
      this.cookieService.deleteAll();
      this.localStorage.clear();
    }
  }

  /**
   * Logins in the user with the sessionToken and update the user data after login.
   * Calls the 'NOTIFY_LOGIN' broadcast which performs all the user object dependent process.
   * @param {string} sessionToken
   * @param {{loginSource?: string}} userData
   * @returns {Promise<void>}
   */
  async loginWithTokenAndUpdateUserInfo(sessionToken: string, userData: { loginSource?: string } = {}): Promise<void> {
    try {
      await ApiConnector.become(sessionToken, { sessionToken });
      const user = this.getCurrentUser();
      this.localStorage.delete('CureSkin/mobileNumber');
      this.localStorage.delete('CureSkin/language');
      this.cookieService.setUser(user);
      await this.updateUserInformationAfterLogin(userData);
      this.broadcast.broadcast('NOTIFY_LOGIN');
      return Promise.resolve();
    } catch (error) {
      this.cookieService.delete('token');
      return Promise.reject(error.toString());
    }
  }

  /**
   * If user preferred language is empty or different from what he is on,
   * then it adds the new language code to user data and calls to update that also.
   * @param {{loginSource?: string}} userData
   * @returns {Promise<void>}
   */
  async updateUserInformationAfterLogin(userData: { loginSource?: string } = {}): Promise<void> {
    const user = this.getCurrentUser();
    const data: any = { ...userData };
    const currentLanguage = this.currentWebSiteLanguage;
    if (user && !user.get('languagePreference')) data.languagePreference = currentLanguage;
    else if (user && user.get('languagePreference') !== currentLanguage) data.languagePreference = currentLanguage;
    await this.updateUserData(data);
  }

  /**
   * Updates experimental flags of user in user table 'experimentLogs' (column).
   * @param {object} data - list of flagnames with boolean|string value denoting status of flag.
   */
  updateExperimentLogsInUser(data: { [key: string]: string | boolean }): void {
    const user = this.getActingUser();
    if (!user) return;
    const experimentLogs = user.get('experimentLogs') ?? {};
    Object.keys(data).forEach((key: string): void => {
      experimentLogs[key] = data[key] ?? true;
    });
    this.updateUserData({ experimentLogs });
  }

  /**
   * Updates the data of user in user table.
   * @param {object} userData - list of key|value to be updated in user table.
   * @returns {Promise<any>}
   */
  async updateUserData(userData: object): Promise<any> {
    if (!this.isUserLoggedIn || !Object.keys(userData).length || this.isInternalUser()) return;
    const { sessionToken }: { sessionToken: string } = this.localStorage.getJsonValue('Parse/myAppId/currentUser');
    const user = await this.getActingUser();
    Object.keys(userData).forEach((key: string): void => {
      if (userData[key] === undefined) {
        user.unset(key);
        return;
      }
      user.set(key, userData[key]);
    });
    await user.save();
    if (!sessionToken) return;
    const localStorageUser = this.localStorage.getJsonValue('Parse/myAppId/currentUser');
    localStorageUser.sessionToken = sessionToken;
    this.localStorage.setJsonValue('Parse/myAppId/currentUser', localStorageUser);
  }

  isInternalUser(): boolean {
    if (!this.isUserLoggedIn) return false;
    return !!this.getCurrentUser().get('type');
  }

  initiateOrderPayment(payload_: { orderId: any,
    gateway: string,
    customTab: boolean,
    paymentType: string,
    paymentId?: string }): Promise<any> {
    const payload: { [key: string]: unknown } = payload_;
    payload.username = this.getActingUser().get('username');
    payload.uniqueId = uuid();
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('initiateOrderPayment', payload));
  }

  /**
   * Updates the regimen in localstorage for faster response on next time.
   * 1. Compare the local regimen with fetched regimen. It uses only ['morning', 'night'] keys of regimen to compare changes.
   * @param {object} params
   * @param {boolean} notifyUpdate - if true, checks is regimen is updated and sets flag in localstorage.
   * @returns {Promise<any>}
   */
  updateRegimensLocally(params: Record<string, unknown>, notifyUpdate?: boolean,
    include: ParseKeys<Table.Regimen>[] = []): Promise<any> {
    return this.apiWall(async (): Promise<Table.Regimen['json'][]> => {
      // instead of this, we'll use findOne
      // const regimens = await this.runCloudFunction('fetchRegimens', params) as Array<any>;
      const regimens = await ApiConnector.find(Table.Regimen, {
        where: { forUser: this.getActingUser() },
        include,
        option: {
          // translate - true or false => regimen page true home page false
          // includeExpiredRegimen => for showing welcome back
          context: { translate: true },
        } as Parse.FullOptions,
      });
      const jsonRegimens = apiObjectToJSONs(regimens);
      const jsonRegimensCopy = apiObjectToJSONs(regimens);
      const localRegimen = this.localStorage.getRegimens(this.getCurrentUser());
      this.localStorage.setRegimens(jsonRegimens, this.getCurrentUser());
      if (notifyUpdate) {
        const keysToCompare = ['morning', 'night'];
        localRegimen.forEach((each_:Table.Regimen['json']): void => {
          const each = each_;
          Object.keys(each_).forEach((key: string): void => {
            if (!keysToCompare.includes(key)) delete each[key];
          });
        });
        jsonRegimensCopy.forEach((each_: any): void => {
          const each = each_;
          Object.keys(each_).forEach((key: string): void => {
            if (!keysToCompare.includes(key)) delete each[key];
          });
        });
        const isRegimenChanged = JSON.stringify(localRegimen).localeCompare(JSON.stringify(jsonRegimensCopy));
        if (isRegimenChanged !== 0) this.localStorage.setValue('regimenUpdateVisited', false);
        else {
          this.allowRegimenForceFetch = false;
          setTimeout((): boolean => this.allowRegimenForceFetch = true, this.regimenRefreshInterval);
        }
      }
      return jsonRegimens;
    }).catch((): any[] => this.localStorage.getRegimens(this.getCurrentUser()));
  }

  /**
   * Fetches the regimen from api or serves local cache of regimens from localstorage.
   * 1. Calls api to fetch for latest regimen if cache is served.
   * 2. 'allowRegimenForceFetch' boolean controls the frequency of regimen fetch.
   *    Even if its force fetch, fetches only after every 2 mins. Time can customized as per need to avoid frequent fetch noise.
   * 3. 'byPassLocalFetch' to bypass the local fetch of regimen altogether.
   * @param username
   * @param regimenId
   * @param {boolean} force - is to ignore local caches and fetch from api.
   * @returns {Promise<any>}
   */
  // Note: P0 - use interface so that we can pass named parameters
  fetchRegimens(regimenId?: any, force?:boolean, byPassLocalFetch?:boolean, expired?: boolean,
    include:ParseKeys<Table.Regimen>[] = []): Promise<any> {
    return this.apiWall(async (): Promise<any> => {
      const user = this.getActingUser();
      const params: any = { username: user?.get('username'), includeExpiredRegimen: !expired };
      if ((user && this.isInternalUser())) {
        params.regimenId = regimenId;
      }
      const regimens = this.localStorage.getRegimens(this.getCurrentUser());
      const nonExpiredRegimen = this.localStorage.getRegimens(this.getCurrentUser()).filter((each: any): boolean => !each.expired);
      if (!nonExpiredRegimen.length || (force && this.allowRegimenForceFetch) || byPassLocalFetch) {
        try {
          return this.updateRegimensLocally(params, true, include);
        } catch (err) {
          return Promise.resolve(regimens);
        }
      }
      this.updateRegimensLocally(params, true, include).catch((err: any): Promise<void> => Promise.reject(err));
      return Promise.resolve(regimens);
    });
  }

  reSendOTP(mobileNumber: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => {
      const deviceId = this.cookieService.get('deviceId') || this.uniqueId;
      const time = new Date().toISOString();
      const digest = await generateDigest(mobileNumber, time);
      return ApiConnector.cloudRun('retryOTP', { mobileNumber, deviceId, time, digest });
    });
  }

  verifyOTP(payload_: AuthConfig): Promise<any> {
    return this.apiWall((): Promise<any> => {
      const payload: AuthConfig = payload_;
      payload.deviceId = payload_.deviceId || this.cookieService.get('deviceId') || this.uniqueId;
      return ApiConnector.cloudRun('verifyOTP', payload as unknown as Record<string, unknown>);
    });
  }

  allocateRegimen(regimenId:string, context:{}): Promise<any> {
    const username = this.getActingUser().get('username');
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('findOrCreateAndSendPersonalizedCopy', { regimenId, username, context }));
  }

  requestOTP(userMobileNumber: string, source: string, params: Record<string, unknown> = {}): Promise<any> {
    return this.apiWall(async (): Promise<any> => {
      const deviceId = this.cookieService.get('deviceId') || this.uniqueId;
      const time = new Date().toISOString();
      const digest = await generateDigest(userMobileNumber, time);
      return ApiConnector.cloudRun('requestOTP', { mobileNumber: userMobileNumber, deviceId, source, time, digest, ...params });
    });
  }

  fetchInstantCheckupByImageUrl(user: any, url: string): Promise<any> {
    return this.apiWall((): Promise<any> => {
      const where = { user, imagePath: url };
      return ApiConnector.findOne(Table.InstantCheckup, { where, project: ['objectId'] });
    });
  }

  async fetchFAQ(objectId?: any): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('fetchFaqWithCategories', { where: { objectId } }));
  }

  async disablePreviousConsultationSessions(concernClass?: string): Promise<any> {
    return this.apiWall(async (): Promise<any> => {
      const username = this.getActingUser().get('username');
      const result = await ApiConnector.cloudRun('restartTree', { username, class: concernClass });
      if (result.status === 'success') {
        return this.updateUserData({ PrivateMainConcern: undefined, PrivateMainConcernClass: undefined });
      }
      return Promise.resolve();
    });
  }

  async saveUserMainConcern(concerns: Array<any>): Promise<any> {
    return this.apiWall(async (): Promise<any> => {
      await this.updateUserData({ concernList: concerns, PrivateMainConcern: concerns[0] });
    });
  }

  async saveUserSecondaryConcerns(concerns: Array<string>): Promise<any> {
    return this.apiWall(async (): Promise<any> => {
      await this.updateUserData({ concernList: concerns });
    });
  }

  async findUserActiveExperiments(_username?: string): Promise<any> {
    let username = _username;
    if (!_username) {
      username = this.getActingUser()?.get('username');
    }
    if (!this.isUserLoggedIn) return Promise.resolve([]);
    const activeExperiments = this.localStorage.getExperiments(this.getCurrentUser());
    if (activeExperiments.length) return activeExperiments;

    return this.apiWall(async (): Promise<any> => {
      try {
        const userActiveExperiments: Array<any> = (await ApiConnector.cloudRun('findUserActiveExperiments', { username })) as Array<any>;
        const userActiveExperimentsJSONArray = JSON.parse(JSON.stringify(userActiveExperiments));
        this.store.dispatch(fromActions.ExperimentsUpdate({ experiments: userActiveExperimentsJSONArray }));
        this.localStorage.setExperiments(userActiveExperimentsJSONArray, this.getCurrentUser());
        return userActiveExperimentsJSONArray;
      } catch (e) {
        return this.localStorage.getJsonValue('CureSkin/activeExperiments', '[]');
      }
    });
  }

  async findMainConcernsOptions(where: object): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('fetchMainConcern', { payload: where }));
  }

  async findMainWithSecondaryConcerns(payload:
    { concernClass: GetMainConcernsConcernClassEnum; shouldTranslateConcernsList: boolean }): Promise<any[]> {
    try {
      const user = this.getActingUser();
      const sessionToken = user?.get('sessionToken');

      if (!sessionToken) {
        return [];
      }

      const response = await this.apiWall(
        (): Promise<any> => this.serverApi.getMainConcerns(payload.concernClass, payload.shouldTranslateConcernsList, sessionToken));

      return response?.data?.mainConcerns ?? [];
    } catch (error) {
      console.error('Error fetching main concerns:', error);
      return [];
    }
  }

  createConsultationOrder(amount: number): Promise <any> {
    return this.apiWall(async (): Promise<any> => {
      const oldOrder = await ApiConnector.findOne(Table.Order, {
        where: {
          type: this.appConfig.Shared.Order.Type.CONSULTATION,
          amount,
          stage: this.appConfig.Shared.Order.Stage.ONLINE_PAYMENT_PENDING,
        },
        descending: 'createdAt',
      });
      if (oldOrder) {
        return oldOrder;
      }
      const order = new Table.Order();
      order.set('type', this.appConfig.Shared.Order.Type.CONSULTATION);
      order.set('amount', amount);
      order.set('noMessageToUser', true);
      order.set('user', this.getActingUser());
      order.set('stage', this.appConfig.Shared.Order.Stage.INITIAL);
      order.set('paymentType', this.appConfig.Shared.Order.PaymentType.ONLINE);
      return order.save();
    });
  }

  async findAllProductsForUser(tag?: Array<any>, responseArray?: boolean, excludeTag?: string[]): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun(
      'findAllProductsForUser',
      {
        username: this.getActingUser().get('username'),
        tag,
        responseArray,
        excludeTag,
      }));
  }

  async findProductsForUser(payload: { tag?: Array<any>,
    responseArray?: boolean, excludeTag?: string[], limit?: number }): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun(
      'findAllProductsForUser',
      {
        username: this.getActingUser().get('username'),
        tag: payload.tag,
        responseArray: payload.responseArray,
        excludeTag: payload.excludeTag,
        ...(payload.limit && { limit: payload.limit }),
      }));
  }

  cachedFnWrapper<T, V>(
    cb: (...args: T[]) => V,
    key: string,
  ): (...args: T[]) => Promise<V> {
    return async (...args: T[]): Promise<V> => {
      let result: V;
      const cachedValue = this.cacheService.retrieve(key);
      if (cachedValue) {
        result = cachedValue;
      } else {
        result = await this.executeCallbackAndCacheResult((): V => cb(...args), key);
      }
      return result;
    };
  }

  private async executeCallbackAndCacheResult<T, V>(
    cb: (...args: T[]) => V,
    key: string,
  ): Promise<V> {
    let result = await cb();
    result = JSON.parse(JSON.stringify(result));
    this.cacheService.store(key, result);
    return result;
  }

  findNextFollowUp(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.FollowUp, {
      where: { user: this.getActingUser(), State: ['WAITING_FOR_IMAGE', 'PENDING'] },
      descending: 'createdAt',
    }));
  }

  findBodyProds(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Catalog, {
      where: { marketingTags: { $in: 'BODY' }, isCommonAddon: true },
    }));
  }

  findSaleProds(limit?: number): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Catalog, {
      where: { isSaleProduct: true },
      limit,
    }));
  }

  findBogoSaleProds(limit?: number): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.AddOnProduct, {
      where: { user: this.getActingUser(), isBogoProduct: true },
      include: ['product'],
      project: ['product'],
      limit,
    }));
  }

  findKitsProducts(limit?: number): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Catalog, {
      where: { isKitProduct: true },
      limit,
    }));
  }

  findHoliSaleProds(limit?: number): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Catalog, {
      where: { isHoliProduct: true },
      limit,
    }));
  }

  findUserCallStatus(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.PendingCall, {
      where: {
        user: this.getActingUser(),
        status: { $eq: 'CallCompleted' },
        createdAt: { $gte: this.timeService.removeMonths(new Date(), 4) },
      },
      project: ['status'],
    }),
    );
  }

  async findOnePendingCall(payload: RequestQueryPayload<Table.PendingCall>): Promise<any> {
    return ApiConnector.findOne(Table.PendingCall, payload);
  }

  async getCountOfUserPendingCall(payload: RequestQueryPayload<Table.PendingCall>): Promise<number> {
    return ApiConnector.count(Table.PendingCall, payload);
  }
  findUserReacquisitionCall(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.PendingCall, {
      where: {
        user: this.getActingUser(),
        type: this.appConfig.Shared.PendingCall.Type.RE_ACQUISITION_CALL,
        requestTime: { $gte: this.timeService.removeMonths(new Date(), 4) },
      },
      project: ['status'],
    }));
  }

  createPendingCallForUser(): Promise <any> {
    return this.apiWall(async (): Promise<any> => {
      const pendingCall = new Table.PendingCall();
      const user = this.getActingUser();
      pendingCall.set('type', this.appConfig.Shared.PendingCall.Type.UNPAID_USER_DOCTOR_CALL);
      pendingCall.set('user', user);
      pendingCall.set('status', 'Requested');
      pendingCall.set('teams', ['doctor']);
      pendingCall.set('uniqueId', `unpaid_user_doctor_call_${user.id}`);
      return pendingCall.save();
    });
  }

  getPinCodeInfo(pinCode: number, context: Record<string, string> = {}): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.PinCodes, {
      where: { pinCode },
      option: { context } as Parse.FullOptions,
    }));
  }

  async fetchNonDeliveredOrders({ user }: any): Promise<any> {
    return this.apiWall((): Promise<any> => {
      const where: any = {
        user,
        stage: [...this.appConfig.Shared.Order.Stage.AfterShipment, ...this.appConfig.Shared.Order.Stage.BeforeShipment],
      };
      return ApiConnector.findOne(Table.Order, { where, descending: 'createdAt' });
    });
  }

  public async updateLocalUser(): Promise<boolean> {
    if (!this.isUserLoggedIn) return false;
    try {
      await this.apiWall(async (): Promise<any> => this.updateCurrentLocalUser());
    } catch (err) {
      if (err.code === 209 || err.code === 206) {
        this.logout();
        await this.router.navigate(['/onboarding/number']);
        return false;
      }
      const errorName = `iosWhiteScreenIssueUpdateUser${err.code}`;
    }
    return true;
  }

  async updateCurrentLocalUser(): Promise<any> {
    const { sessionToken }: { sessionToken: string } = this.localStorageService.getJsonValue('Parse/myAppId/currentUser');
    let user = ApiConnector.getCurrentUser();
    if (!user) return user;
    user = await ApiConnector.getCurrentUser().fetch();
    if (!sessionToken) return user;
    const localStorageUser = this.localStorageService.getJsonValue('Parse/myAppId/currentUser');
    localStorageUser.sessionToken = sessionToken;
    this.localStorageService.setJsonValue('Parse/myAppId/currentUser', localStorageUser);
    return user;
  }

  fetchOrderStages(forceFetch?: boolean): Promise<any> {
    if (!forceFetch) {
      const localOrderStages = this.localStorage.getJsonValue('CureSkin/orderStages', '[]');
      if (localOrderStages.length) return Promise.resolve(localOrderStages);
    }
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.OrderStage,
      { where: {}, include: ['possibleStageChange'] })
      .then((orderStages: any): Promise<any> => {
        const orderStagesJSON = JSON.parse(JSON.stringify(orderStages));
        this.localStorage.setJsonValue('CureSkin/orderStages', orderStagesJSON);
        return Promise.resolve(orderStagesJSON);
      }));
  }

  async findUserByObjectId(userObjectId?: string, force?: boolean, context?: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => {
      const objectId = userObjectId || this.getActingUser()?.id;
      const userHash: any = this.dataStore.get('USERS');
      if (!force && userHash[objectId]) {
        return userHash[objectId];
      }
      const user = await ApiConnector.findOne(Table.User, {
        where: { objectId: userObjectId },
        include: ['allocatedDoctor', 'allocatedOperator'],
        option: { context } as Parse.FullOptions,
      }) as Table.User & {isPaid:boolean};
      userHash[objectId] = user;
      if (user) user.isPaid = this.isPaid.call(user, this);
      this.dataStore.set('USERS', userHash);
      return user;
    });
  }

  async findUserPropertyByObjectId(userObjectId?: string, project: string[] = []): Promise<any> {
    return ApiConnector.findOne(Table.User, { where: { objectId: userObjectId }, project });
  }

  async findUserConfirmationsPending(id?: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => {
      const where = { userConfirmed: false, user: this.getActingUser(), objectId: id };
      if (!id) delete where.objectId;
      return ApiConnector.findOne(Table.UserConfirmation, { where, descending: 'createdAt' });
    });
  }

  fetchOrders(where: object = {},
    project: Array<ParseKeys<Table.Order>> = [],
    include: Array<ParseKeys<Table.Order>> = ['products', 'services', 'regimen', 'regimen.products' as 'regimen', 'coupons', 'user'],
    limit: number = 50): Promise<any[]> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Order,
      { where, project, descending: 'createdAt', include, limit }));
  }
  fetchOrderById(where: object = {},
    project: Array<ParseKeys<Table.Order>> = [],
    include: Array<ParseKeys<Table.Order>> = ['products', 'services', 'regimen', 'regimen.products' as 'regimen', 'coupons', 'user']):
    Promise<any[]> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.Order,
      { where, project, descending: 'createdAt', include }));
  }

  cancelOrder(orderId: any, cancelReason?: string): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('cancelOrder', { orderId, cancelReason }));
  }

  async updateRegimenService(regimenId: string, serviceType: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('updateRegimenService', { regimenId, serviceType }));
  }

  async applyDiscountWith(couponCode: string, orderId: string): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('applyCoupon', { couponCode, orderId }));
  }

  // Cloud function to remove the coupon
  async removeDiscountCoupon(couponCode: string, orderId: string): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('removeCoupon', { couponCode, orderId }));
  }

  async findRegimenById(id: any, project: Array<ParseKeys<Table.Regimen>> = []): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.Regimen,
      { where: { objectId: id }, project, include: ['products'] }));
  }

  async fetchAssistantById(id: any, imageCompareLinkFlag?: boolean): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.Assistant, { where: { objectId: id, imageCompareLinkFlag } }));
  }

  async fetchConfig(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.Config, { where: {} }));
  }

  findProductsById(productId: Array<any>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Catalog, { where: { objectId: productId } }));
  }

  findCatalogWithKey(productId: Array<any>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Catalog, { where: { objectId: productId } }));
  }

  deleteInstantCheckup(instantCheckupObjectId: any, confirm?: boolean): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('deleteInstantCheckUp',
      { instantCheckupObjectId, confirm }));
  }

  fetchShopProducts(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('findAllShopProducts', {
      username: this.getActingUser().get('username'),
      responseArray: true,
    }));
  }

  fetchOrdersCount(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.count(Table.Order, { where: { user: this.getActingUser() } }));
  }

  async updateOrderBeforeShipment(params: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('updateOrderBeforeShipment', params));
  }

  async findOrderWithSignedURL(id: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('findOrderWithSignedURL', { id }));
  }

  findOrderStages(where: object = {}, include: Array<any> = []): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.OrderConsultationLog, { where, include }));
  }

  async findConsultationSessionChat(payload: RequestQueryPayload<Table.ConsultationSessionChat>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.ConsultationSessionChat, payload));
  }

  async findFollowUpChat(payload: RequestQueryPayload<Table.FollowUpChat>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.FollowUpChat, payload));
  }

  async findOrderChat(payload: RequestQueryPayload<Table.OrderChat>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.OrderChat, payload));
  }

  async findOneOrderChat(payload: RequestQueryPayload<Table.OrderChat>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.OrderChat, payload));
  }

  async likeFeed(feedId: string): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('likeFeed', { feedId }));
  }

  async findRegimenByDate(payload: RequestQueryPayload<Table.Regimen>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.Regimen, payload));
  }

  async getAssistantMessageWithLimit(payload: RequestQueryPayload<Table.Assistant>): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.Assistant, payload));
  }

  fetchPrescription(id: any, regimenId: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('fetchPrescription', { id, regimenId }));
  }

  createUserImageUploadUrl(extension: any, username: any, source: string): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('createUserImageUploadUrl',
      { extension, username, source }));
  }

  findCheckoutPrice(payload: { type: any; regimenId: any; products: any, services: any }): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('fetchCheckoutPrice', payload));
  }

  checkoutAndCreateOrder(payload: any): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('checkoutAndCreateOrder', payload));
  }

  fetchInstantCheckup(payload: { userId?: string,
    id?: string[],
    limit?: number,
    imagePath?: string,
    project?: string[],
    type?: string[],
    dateRange?: { startDate?: Date, endDate?: Date } }): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('previousInstantCheckup', payload));
  }

  findPaymentById(objectId: string): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.Payment, {
      where: { objectId },
      include: ['order'],
      project: ['order.amount' as 'order', 'order.type' as 'order'],
    }));
  }

  getArticleById(objectId: any, context?: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.ArticleData, {
      where: { objectId },
      include: ['product', 'params.articleImagesLanguageString' as 'params'],
      option: { context } as Parse.FullOptions,
    }));
  }

  unCancelUserOrder(orderId: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('uncancelUserOrder', { orderId }));
  }

  updateLastActiveTime(tabName: string): void {
    if (this.isInternalUser()) return;
    const json = this.localStorage.getJsonValue('CureSkin/lastActiveTime', '{}');
    json[tabName] = { __type: 'Date', iso: new Date() };
    this.localStorage.setJsonValue('CureSkin/lastActiveTime', json);
  }

  async switchUserToState(stateId: string, context: Record<string, unknown> = {}): Promise<any> {
    if (this.isInternalUser()) {
      return undefined;
    }
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('switchUserToState', { stateId, context }));
  }

  async fetchRewards(where: any = {}): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector
      .find(Table.Reward, { where, include: ['cashTransaction', 'fromUser'], descending: 'createdAt' }));
  }

  async getInstaLiveUpdate(): Promise<any> {
    const where = {};
    return this.apiWall((): Promise<any> => ApiConnector
      .find(Table.InstaLiveReminder, { where, descending: 'createdAt', limit: 1 }));
  }

  async claimReward(rewardId: any, contactDetails?: any): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('claimReward', { rewardId, contactDetails }));
  }

  async afterFirstRegimenPurchaseOffer(): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('afterFirstRegimenPurchaseOffer', {}));
  }

  async isAfterPurchaseProductOfferAvailable(): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('isAfterPurchaseProductOfferAvailable', {}));
  }

  async fetchAssistantTask(assistantId: any): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('findAssistantTask', { assistantId }));
  }

  async getCureSkinCashTransactions(username?: string, project?: Array<ParseKeys<Table.CashTransaction>>): Promise<any> {
    const user = this.getActingUser();
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.CashTransaction, {
      where: { user },
      include: ['reward', 'order'],
      project,
      descending: 'createdAt',
    }));
  }

  async fetchCashBalance(): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun(
      'fetchCashBalance',
      { username: this.getActingUser()?.get('username') }));
  }

  async updateUserLastSeenTime(): Promise<any> {
    return this.apiWall(async (): Promise<any> => {
      const user = this.getActingUser();
      if (!user || this.isInternalUser()) return Promise.resolve();
      let cache = this.dataStore.get('CHAT_CACHE');
      if (!cache) cache = await ApiConnector.findOne(Table.AttendToChatCache, { where: { user } });
      if (!cache) return Promise.resolve();
      this.dataStore.set('CHAT_CACHE', cache);
      cache.set('lastMessageSeenTime', new Date());
      return cache.save();
    });
  }

  async fetchFeedbackQuestions(condition: any): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.FeedbackQuestion, {
      where: { ...condition },
      ascending: 'rank',
    }));
  }

  async fetchConsultationSessions(payload: RequestQueryPayload<Table.ConsultationSession>): Promise<Array<any>> {
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.ConsultationSession, payload));
  }

  async findConsultationSession(concernClass: any, project: string[] = []): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.ConsultationSession, {
      where: { username: this.getActingUser().get('username'), PrivateMainConcernClass: concernClass, archive: false },
      project,
      descending: 'createdAt',
    }));
  }

  async getLanguageStringsById(id: string[] = []): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.LanguageString, { where: { objectId: id } }));
  }

  async triggerTree(stateId: any): Promise<any> {
    const username = this.getActingUser().get('username');
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('switchUserToState', { stateId, username }));
  }

  fetchDietService(): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.Service,
      { where: { type: 'DIET_CONSULTATION' }, descending: 'amount' }));
  }

  get currentWebSiteLanguage(): string {
    let currentLanguage = this.appConfig.Shared.Languages.EN;
    Object.keys(this.appConfig.Shared.Languages)
      .map((key: string): string => this.appConfig.Shared.Languages[key])
      .forEach((language: string): void => {
        if (!this.windowRef.nativeWindow.location.pathname.startsWith(`/${language}`)) return;
        currentLanguage = language;
      });
    return currentLanguage;
  }

  async getUserBlogs(payload: RequestQueryPayload<Table.UserBlog> = { where: {} }): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.UserBlog, payload));
  }

  async executeCloudFunction(functionName: string, payload: Object = {}): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun(functionName, payload as Record<string, unknown>));
  }

  async getInstantCheckupConcernDetectionCount(type: string[], limit?: number): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('getInstantCheckupConcernDetectionCount', { type, limit }));
  }

  async fetchFollowUpReportById(objectId: string, project: Array<ParseKeys<Table.FollowUpReport>> = []): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.FollowUpReport, {
      where: { objectId },
      include: ['doctor', 'treatmentProgress.concernLanguageString' as 'treatmentProgress'],
      project,
      option: { context: { translate: true } } as Parse.FullOptions,
    }));
  }

  async fetchFollowUpReports(payload: RequestQueryPayload<Table.FollowUpReport>): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.FollowUpReport, payload));
  }

  async countFollowUpReports(payload: RequestQueryPayload<Table.FollowUpReport>): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.count(Table.FollowUpReport, payload));
  }

  async findRecentFollowUp(where: Record<string, unknown> = {}): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.FollowUp, {
      where: { user: this.getActingUser(), ...where },
      descending: 'followUpCount',
    }));
  }

  async fetchNotification(payload: RequestQueryPayload<Table.Notification>): Promise<Array<any>> {
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.Notification, payload));
  }

  async fetchFeedback(payload: RequestQueryPayload<Table.Feedback>): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.Feedback, payload));
  }

  async fetchLatestActiveFollowup(): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(
      Table.FollowUp,
      {
        where: {
          user: this.getActingUser(),
          State: [this.appConfig.Shared.Followup.State.PENDING, this.appConfig.Shared.Followup.State.SKIP],
        },
        descending: 'nextFollowUpDate',
      }));
  }

  async shouldShowFollowupBanner(): Promise<boolean> {
    const followup = await this.fetchLatestActiveFollowup();
    if (!followup
      || followup.get('followUpCount') <= 1
      || ![this.appConfig.Shared.Followup.State.PENDING,
        this.appConfig.Shared.Followup.State.SKIP].includes(followup.get('State'))) return false;
    const followupDate = this.timeService.resetTime(new Date(followup.get('nextFollowUpDate'))).getTime();
    const todayPlus2 = this.timeService.resetTime(this.timeService.addDays(new Date(), 2)).getTime();
    const today = this.timeService.resetTime(new Date()).getTime();
    return followupDate <= today || todayPlus2 >= followupDate;
  }

  async requestFollowUp(context: Record<string, unknown>): Promise<any> {
    return this.apiWall((): Promise<any> => this.switchUserToState('v4_followup:', context));
  }

  async optForRegimenDoctorCall(regimenId: string, opted: boolean): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('optForRegimenDoctorCall', { regimenId, opted }));
  }

  async findDoctors(): Promise<any[]> {
    const where = { type: this.appConfig.Shared.User.Type.DOCTOR, inactive: false };
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.User, { where }));
  }

  async findOrCreateConsultationSession(concernClass: string, select: string[] = []): Promise<any> {
    let session = await this.findConsultationSession(concernClass, select);
    if (session) return session;
    session = new Table.ConsultationSession();
    session.set('PrivateMainConcernClass', concernClass);
    session.set('username', this.getActingUser().get('username'));
    session.set('archive', false);
    await session.save();
    return session;
  }

  async findAllActiveCoupons(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Coupon,
      { where: { active: true, showInPaymentPage: true } }));
  }

  async fetchFeeds(): Promise<any> {
    const storageKey = 'explorePosts';
    const storedData = localStorage.getItem(storageKey);
    if (storedData) {
      return Promise.resolve(JSON.parse(storedData));
    }
    const response = await this.apiWall(async (): Promise<any> => ApiConnector.cloudRun('getPersonalisedExplorePosts'),
    );

    localStorage.setItem(storageKey, JSON.stringify(response));

    return response;
  }

  async fetchExplorePostById(id: string): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.Feed, { where: { objectId: id } }));
  }

  createFileUploadUrl(param: Record<string, unknown>): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('createFileUploadUrl', param));
  }

  async uploadFile({ file, bucket, username, source }: { file: any, bucket: string, username?: string, source?: string })
    : Promise<{ publicURL: string; signedGetURL: string; }> {
    const extension: string = file.name.split('.').pop();
    const name: string = `${uuid()}.${extension}`;
    this.broadcast.broadcast('UPLOADING_PROGRESS', { show: true, percentageLoaded: 0 });
    const payload = await this.createFileUploadUrl({ name, bucket, username, source, extension });
    const formData = new FormData();
    Object.keys(payload.fields).forEach((field: string): void => formData.append(field, payload.fields[field]));
    formData.append('file', file);
    const headers = new HttpHeaders();
    await new Promise((resolve: Function): void => {
      const httpRequest = this.httpClientService.post(payload.url, formData, { headers,
        responseType: 'text',
        observe: 'events',
        reportProgress: true });
      const subscription = httpRequest.subscribe((event: any): void => {
        if (event.type === 0) this.broadcast.broadcast('UPLOADING_PROGRESS', { show: true, percentageLoaded: 0, subscription });
        if (event.type === 1) {
          this.broadcast.broadcast('UPLOADING_PROGRESS',
            { show: event.total !== event.loaded,
              percentageLoaded: Math.floor((event.loaded / event.total) * 100),
              subscription });
        }
        if (event.type === 4) {
          resolve(event);
          subscription.unsubscribe();
        }
      });
    });
    return {
      publicURL: payload.publicURL,
      signedGetURL: payload.getSignedURL,
    };
  }

  async fetchAddressById(id: string): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.AddressBook, { where: { objectId: id } }));
  }

  async fetchAllAddress(user: unknown): Promise<Array<any>> {
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.AddressBook,
      { where: { user }, descending: 'createdAt' }));
  }

  async fetchDefaultAddress(user: unknown): Promise<Array<any>> {
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.AddressBook,
      { where: { user, default: true } }));
  }

  async fetchAddressTags(tag: string): Promise<Array<any>> {
    return this.apiWall(async (): Promise<any> => {
      const languageTags = await ApiConnector.find(Table.LanguageStringTag, { where: { name: tag } });
      return ApiConnector.find(Table.LanguageString, { where: { tags: languageTags } });
    });
  }

  applyGiftCard(code: string): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('applyGiftCard', { code }));
  }

  getDetailedLocationFromCoordinates(data: { [key: string]: unknown }): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('getDetailedLocationFromCoordinates', data));
  }

  findFollowupById(id: string, context: Record<string, unknown>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.FollowUp, {
      where: { objectId: id },
      option: { context } as Parse.FullOptions,
    }));
  }

  async createFollowUp(): Promise<any> {
    if (this.isInternalUser()) return {};
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('createFollowUp', {}));
  }

  async trackInFirebaseAndBranch(eventName: string, eventData?: { [key: string]: unknown }): Promise<any> {
    if (this.getAppBridgeService().requestOSInformation() === 'iOS') {
      return Promise.resolve();
    }
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('trackEventInBranchAndFirebase', { eventName, eventData }));
  }

  fetchSupportCategory(payload: RequestQueryPayload<Table.SupportCategory> = { where: {} },
    context: Record<string, any> = {}): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.SupportCategory, {
      ...payload,
      option: { context } as Parse.FullOptions,
    }));
  }

  fetchSupportQuestions(payload: RequestQueryPayload<Table.SupportQuestion>, context: Record<string, any> = {}): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.SupportQuestion, {
      include: ['questionLanguageString', 'responseHtmlLanguageString', 'responseTitleLanguageString'],
      option: { context: { translate: true, response: true, ...context } } as Parse.FullOptions,
      ...payload,
    }));
  }

  fetchSupportQuestionById(payload: RequestQueryPayload<Table.SupportQuestion>, context?: Record<string, any>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.SupportQuestion, {
      option: { context: { response: true, translate: true, ...context } } as Parse.FullOptions,
      ...payload,
    }));
  }

  fetchSupportTickets(payload: RequestQueryPayload<Table.SupportTicket>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.SupportTicket,
      {
        ...payload,
        include: ['supportQuestion', 'supportQuestion.questionLanguageString' as 'supportQuestion'],
        option: { context: { translate: true } } as Parse.FullOptions,
      }));
  }

  findSupportTicketById(id: string, context: Record<string, unknown>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.SupportTicket, {
      where: { objectId: id },
      option: { context } as Parse.FullOptions,
    }));
  }

  fetchSupportChat(payload: RequestQueryPayload<Table.SupportChat>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.SupportChat, payload));
  }

  fetchSupportTicketCount(user: any): Promise<number> {
    return this.apiWall(async (): Promise<any> => ApiConnector.count(Table.SupportTicket,
      { where: { user } }));
  }

  fetchQuizQuestions(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.QuizQuestion,
      {
        where: { },
        limit: 1000,
        include: ['answer', 'optionsArray', 'questionLanguageString', 'wrongAnswer'],
        option: { context: { translate: true } } as Parse.FullOptions,
      }));
  }

  fetchUserAnswers(user: Table.User): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.QuizAnswers,
      {
        where: { user },
        limit: 1000,
        include: ['questionId'],
        descending: 'createdAt',
      }));
  }

  async createTicket(supportQuestionId: string, title?: string, extraData?: Record<string, any>): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('findOrCreateSupportTicket',
      { supportQuestionId, title, extraData }));
  }

  async updateUserLastActiveDetails(supportTicketId: string): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('updateUserLastActiveDetails', { supportTicketId }));
  }

  fetchReminderById(id: string): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.Reminder, { where: { objectId: id } }));
  }

  async updateSupportTicketFeedback(supportTicketId: string, feedback: boolean): Promise<{ message: string,
    ticketStatusChanged: boolean }> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun(
      'updateSupportTicketFeedback',
      { supportTicketId, feedback },
      { context: { skipMessage: true } }));
  }

  async findUnReadNotifications(): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.count(Table.Notification, {
      where: {
        user: this.getActingUser(),
        viewed: false,
        active: true,
      },
    }));
  }

  async findLastDeliveredOrder(where: Record<string, unknown> = {}): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.Order, {
      where: { user: this.getActingUser(), ...where },
      include: ['regimen'],
      descending: 'deliveredOn',
    }));
  }

  async updateActingUser({ userId, username }: { userId: any; username: any }): Promise<void> {
    if (!this.isInternalUser()) {
      return;
    }
    const payload: RequestQueryPayload<Table.User> = { where: {} };
    if (userId) {
      payload.where.objectId = userId;
    } else if (username) {
      payload.where.username = username;
    } else {
      return;
    }
    this.actingUser = await ApiConnector.findOne(Table.User, payload);
    if (this.actingUser && !this.actingUser.isPaid) {
      this.actingUser.isPaid = this.isPaid.call(this.actingUser, this);
    }
  }

  getActingUser(): any {
    if (this.actingUser) {
      return this.actingUser;
    }
    return this.getCurrentUser();
  }

  updateRegimenPrice(payload: any): Promise<any> {
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('updateRegimenPriceAndAddExtraProducts', payload));
  }

  async fetchUserRegimens(payload: RequestQueryPayload<Table.Regimen>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Regimen, payload));
  }

  async fetchExperimentByKey(key: string): Promise<void> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.Experiment, {
      where: { key, enable: true },
    }));
  }

  getCart(): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.cloudRun('getCart', {}));
  }

  addProductToCart(payload: any): Promise<any> {
    let cart: any;
    if (payload.productId) {
      cart = this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('addProductToCart', payload));
    }
    return cart;
  }

  // async addProductToCart(payload: any): Promise<any> {
  //   try {
  //     const user = this.getActingUser();
  //     const sessionToken = user?.get('sessionToken');
  //     const params = { ...payload, userId: this.user.id };
  //     if (!sessionToken) {
  //       return [];
  //     }

  //     const response = await this.apiWall(
  //       (): Promise<any> => this.serverApi.addProductToCart(params, sessionToken));
  //     return response?.data?.data ?? [];
  //   } catch (error) {
  //     console.error('Error fetching cart', error);
  //     return [];
  //   }
  // }

  removeProductFromCart(payload: any): Promise<any> {
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('removeProductFromCart', payload));
  }

  trackVoiceNotesAndScrollEvents(payload: any): Promise<any> {
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('trackVoiceNotesAndScrollEvents', payload));
  }

  async findProductsByText(text: string): Promise<any[]> {
    const username = this.getActingUser().get('username');
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('findProductsByText', { username, text }));
  }

  async advancedSearchProductsByText(text: string): Promise<any[]> {
    const username = this.getActingUser().get('username');
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('advancedSearchProductsByText', { username, text }));
  }

  async findProducts(where: any): Promise<any[]> {
    return this.apiWall((): Promise<any[]> => ApiConnector.find(Table.Catalog, {
      where: { ...where, inventoryStatus: this.appConfig.Shared.Inventory.Type.AVAILABLE },
    }));
  }

  async findQuestionById(id: Array<string>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.QuizQuestion, {
      where: { objectId: id },
      include: ['answer', 'optionsArray', 'questionLanguageString', 'wrongAnswer'],
      option: { context: { translate: true } } as Parse.FullOptions,
    }));
  }

  findCatalogWithAlternateProduct(productId: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.Catalog, { where: { objectId: productId },
      include: ['alternateProduct'] }));
  }

  replaceDiscontinuedProduct(oldProductId: string, newProductId: string): any {
    const username = this.getActingUser().get('username');
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('replaceDiscontinuedProduct',
      { oldProductId, newProductId, username }));
  }
  // Refer and Earn
  referNewUserWithNumber(referredUserName: string, referredMobileNumber: string): Promise<any> {
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('referNewUserWithNumber',
      { referredMobileNumber, referredUserName }));
  }
  fetchLatestActiveSupportTickets(payload: RequestQueryPayload<Table.SupportTicket>): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.SupportTicket,
      {
        ...payload,
        include: ['supportQuestion', 'supportQuestion.questionLanguageString' as 'supportQuestion'],
        option: { context: { translate: true } } as Parse.FullOptions,
        descending: 'createdAt',
      }));
  }

  fetchImageFromMediaLink(combinedProductIds: string): Promise<any> {
    return this.apiWall((): Promise<any> => ApiConnector.find(Table.MediaLink,
      {
        where: { uniqueId: combinedProductIds },
        project: ['link'],
      },
    ));
  }

  updateRegimenInfo(regimenId: any, variantId: string): Promise<any> {
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('changeRegimenVariant', { regimenId, variantId }));
  }

  fetchProgressiveProduct(): Promise<any> {
    const user = this.getActingUser();
    return this.apiWall((): Promise<any> => ApiConnector.findOne(Table.ProgressiveTreatment,
      { where: { user, active: true, completed: false }, include: ['product'] }));
  }

  getQualifyText(doctor: any): string {
    return !doctor?.get('forDoctor') ? doctor?.get('doctorQualification') : 'Physician and skin care expert';
  }

  /*
    create an interface with type of value for each concerns
  */
  getSkinReportInfo(mainConcernDetectedByAI?: string, concernsWithCount?: ConcernsWithCount): Promise<any> {
    const payload = {
      mainConcernDetectedByAI,
      concernsWithCount,
    };
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('getUserSkinReportParameters', payload));
  }

  createPendingCall(payload: { type: string, message: string}): Promise<any> {
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('createPendingCall', payload));
  }

  trackEventInCleverTap(eventName: string, username: string, eventData: any = {}): Promise<any> {
    return this.apiWall((): Promise<boolean> => ApiConnector.cloudRun('trackEventInCleverTap', { eventName, eventData, username }));
  }

  getClassBasedOnLanguagePreference(): string {
    const user = this.getActingUser();
    const userLanguagePreference = user?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    return userLanguagePreference !== 'hi' ? 'tw-text-100' : 'tw-text-300';
  }

  async checkForPrescriptionStatus(orderId: any): Promise<any> {
    try {
      const order = new Table.Order();
      order.id = orderId;

      const results = await this.findLastVerifiedConsultation(order);

      if (results && results?.get('createdBy')) {
        const createdBy = results.get('createdBy');
        this.setDoctorInfo(createdBy);
      } else {
        this.setDefaultDoctorInfo();
      }
    } catch (error) {
      this.handlePrescriptionCheckError(error);
    }
  }

  private async findLastVerifiedConsultation(order: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.findOne(Table.OrderConsultationLog, {
      where: { order, stage: 'DR_VERIFIED' },
      descending: 'createdAt',
      include: ['createdBy'],
    }));
  }

  private setDoctorInfo(createdBy: any): void {
    this.doctorName = createdBy?.get('DoctorDisplayName') || prescriptionVar.name;
    this.doctorSignature = createdBy?.get('doctorSignature') || prescriptionVar.signature;
  }

  private setDefaultDoctorInfo(): void {
    this.doctorName = prescriptionVar.name;
    this.doctorSignature = prescriptionVar.signature;
  }

  private handlePrescriptionCheckError(error: any): void {
    this.broadcast.broadcast('Error checking prescription verification');
    throw error;
  }

  get getdoctorName(): string {
    return this.doctorName;
  }

  get getDoctorSignature(): string {
    return this.doctorSignature;
  }

  async getOrderId(regimenId: string): Promise<any> {
    /* eslint-disable */
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.Order, {
      where: { regimenId: regimenId },
      project: ['objectId'] }));
  }

  async getPastRegimens(user: any): Promise<any> {
    return this.apiWall(async (): Promise<any> => ApiConnector.find(Table.RegimenHistory, { where: { forUser: user, updateVersion: 0 } }));
  }

  getRegimenVariantSelection(): boolean {
    return this.isVariantAlreadySelected;
  }

  setRegimenVariantSelection(): void {
    this.isVariantAlreadySelected = true;
  }

  async logPrivacyPolicyDate(): Promise<void> {
    const user = this.getActingUser();
    if (user) {
      const today = new Date();
      const userPolicyAcceptedDates = user?.get('termsOfServiceAcceptedDates') || [];
      if (userPolicyAcceptedDates.includes(today)) return;

      const dateArray = [today];
      user.set('termsOfServiceAcceptedDates', [...userPolicyAcceptedDates, ...dateArray]);
      try {
        await user.save();
      } catch (error) {
        console.error('Error saving user:', error);
      }
    }
  }

  async findAllSectionWiseProductsServerAPI(useCache: boolean): Promise<Record<string, Array<Models.Catalog>>> {
    const cacheKey = this.tagCacheTableMapping.SHOP_SECTION;
    this.user = this.getActingUser();
    const sessionToken = this.user.get('sessionToken');

    if (!sessionToken) {
      return {};
    }

    const cb = (): ServerAPIResponse['findAllSectionWiseProducts'] => this.serverApi.findAllSectionWiseProducts(sessionToken);

    if (useCache) {
      const cachedFindAllProducts = this.cachedFnWrapper(cb, cacheKey);
      const response = await cachedFindAllProducts();
      return response.data?.result ?? {};
    }

    const response = await this.executeCallbackAndCacheResult(cb, cacheKey);
    return response.data?.result ?? {};
  }

  async findSectionProductsServerAPI(section: string, useCache: boolean): Promise<Array<Models.Catalog>> {
    const cacheKey = this.tagCacheTableMapping[section];
    this.user = this.getActingUser();
    const sessionToken = this.user.get('sessionToken');

    if (!sessionToken || !section) {
      return [];
    }

    const cb = (): ServerAPIResponse['findSectionProducts'] => this.serverApi.findSectionProducts(section, sessionToken);

    if (useCache) {
      if (!cacheKey) return [];
      const cachedFindAllProducts = this.cachedFnWrapper(cb, cacheKey);
      const response = await cachedFindAllProducts();
      return response.data?.result ?? [];
    }

    const response = await this.executeCallbackAndCacheResult(cb, cacheKey);
    return response.data?.result ?? [];
  }
}
