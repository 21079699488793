import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { TestimonialCaseStudiesComponent } from './testimonial-case-studies.component';
import { TestimonialCaseStudiesInfoComponent } from './testimonial-case-studies-info/testimonial-case-studies-info.component';

@NgModule({
  declarations: [TestimonialCaseStudiesComponent,
    TestimonialCaseStudiesInfoComponent],
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
  ],
  exports: [TestimonialCaseStudiesComponent],
})

export class TestimonialCaseStudiesModule { }
