import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { TestimonialsDescComponent } from './testimonials-description.component';

@NgModule({
  declarations: [TestimonialsDescComponent],
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
  ],
  exports: [TestimonialsDescComponent],
})
export class TestimonialsDescModule { }
