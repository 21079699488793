import { NgModule } from '@angular/core';
import { ButtonModule } from '@components/button';
import { PopUpModule } from '@components/pop-up';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { OnboardingMobileOtpModule } from './onboarding-otp/onboarding-mobile-otp.module';
import { OnboardingMobileNumberModule } from './onboarding-number/onboarding-mobile-number.module';
import { OnboardingGenderModule } from './onboarding-gender/onboarding-gender.module';
import { OnboardingConcernModule } from './onboarding-concern/onboarding-concern.module';
import { OnboardingTakePhotoModule } from './onboarding-take-photo/onboarding-take-photo.module';
import { OnboardingCheckupDetectionsModule } from './onboarding-checkup-detections/onboarding-checkup-detections.module';
import { OnboardingLocationComponent } from './onboarding-location/onboarding-location.component';

@NgModule({
  imports: [
    ButtonModule,
    CsIconModule,
    PopUpModule,
    OnboardingMobileOtpModule,
    OnboardingMobileNumberModule,
    OnboardingGenderModule,
    OnboardingConcernModule,
    OnboardingTakePhotoModule,
    OnboardingCheckupDetectionsModule,
    CommonModule,
  ],
  declarations: [
    OnboardingLocationComponent,
  ],
  exports: [OnboardingLocationComponent],
})
export class OnboardingModule { }
