import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@components/loading';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { PopUpModule } from '@components/pop-up';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { CsIconModule } from '@components/icon';
import { DiscontinuedPopupModule } from '@shared/discontinued-popup/discontinued-popup.module';
import { AovSaleModule } from '@shared/aov-sale/aov-sale.module';
import { SelfReorderWarningComponent } from './self-reorder-warning/self-reorder-warning.component';
import { UserOrderReorderComponent } from './user-order-reorder.component';

export function playerFactory(): LottiePlayer {
  return player;
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ToolbarModule,
    ButtonModule,
    PopUpModule,
    LoadingModule,
    CsIconModule,
    DiscontinuedPopupModule,
    AovSaleModule,
    [LottieModule.forRoot({ player: playerFactory })],
  ],
  declarations: [UserOrderReorderComponent, SelfReorderWarningComponent],
  exports: [UserOrderReorderComponent],
})
export class UserOrderReorderModule {}
