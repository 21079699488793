import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerModule } from '@components/video-player/video-player.module';
import { VideoFeedComponent } from './video-feed.component';

@NgModule({
  declarations: [VideoFeedComponent],
  imports: [
    CommonModule,
    VideoPlayerModule,
  ],
  exports: [VideoFeedComponent],
})
export class VideoFeedModule { }
