import { Component } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { CurrentComponentService } from '@services/current-component';
import { WindowRefService } from '@services/window-ref-service';
import { ElasticService } from '@services/elastic-search';
import { EventLoggerService } from '@services/event-logger-service';

declare const GAME_HELPER;

@Component({ selector: 'game', templateUrl: './game.html', styleUrls: ['./game.scss'] })
export class GameComponent {
  fpsAnimation: any;
  fpsString: string;
  prevFPS: number;
  constructor(private conn: ConnectionService,
    private broadcasterService: BroadcastService,
    private currentComponentService: CurrentComponentService,
    private windowService: WindowRefService,
    private eventLogger: EventLoggerService,
    private elasticService: ElasticService) {
  }
  async ngOnInit(): Promise<any> {
    this.conn.addScript('/assets/js/game-script.js', (): void => { this.startGame(); });
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'game' }));
    const decimalPlaces = 2;
    const updateEachSecond = 1;
    const decimalPlacesRatio = 10 ** decimalPlaces;
    let timeMeasurements = [];

    let fps = 0;

    const tick = (): void => {
      timeMeasurements.push(performance.now());
      const msPassed = timeMeasurements[timeMeasurements.length - 1] - timeMeasurements[0];
      if (msPassed >= updateEachSecond * 1000) {
        fps = Math.round((timeMeasurements.length / msPassed) * 1000 * decimalPlacesRatio) / decimalPlacesRatio;
        timeMeasurements = [];
      }
      if (fps !== this.prevFPS) this.fpsString += `#${String(fps)}`;
      this.prevFPS = fps;
      this.fpsAnimation = requestAnimationFrame((): void => tick());
    };
    tick();

    this.windowService.nativeWindow.onpopstate = (): void => {
      GAME_HELPER.exit();
      this.conn.removeScript('/assets/js/game-script.js');
    };
  }

  startGame(): void {
    GAME_HELPER.init();
  }

  playAgain(event: any): void {
    GAME_HELPER.playAgain(event);
  }

  exitGame(event: any): void {
    GAME_HELPER.exit(event);
    this.back();
  }

  async handleBackPress(): Promise<boolean> {
    GAME_HELPER.exit();
    return false;
  }

  back(): void {
    cancelAnimationFrame(this.fpsAnimation);
    this.elasticService.log({ track: 'GAME_FPS', fps: this.fpsString.split('#') });
    GAME_HELPER.exit();
    this.broadcasterService.broadcast('NAVIGATION_BACK');
  }
}
