import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'shop-order-banners',
  templateUrl: './shop-order-banners.html',
  styleUrls: ['./shop-order-banners.scss'],
})
export class ShopBannersComponent {
  @Input('productsInCartArray') productsInCartArray: any;
  @Input('numberOfProductsInCart') numberOfProductsInCart: any;
  @Input('cartDiscount') cartDiscount: any;
  @Input('totalSp') totalSp: number;
  @Output() goToCart: EventEmitter<any> = new EventEmitter();
}
