import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading/index';
import { ToolbarModule } from '@components/toolbar/index';
import { ButtonModule } from '@components/button';
import { UserDietComponent } from './user-diet.component';

@NgModule({
  imports: [CommonModule, LoadingModule, ToolbarModule, ButtonModule],
  declarations: [UserDietComponent],
  exports: [],
})
export class UserDietModule {}
