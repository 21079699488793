import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { LoadingModule } from '@components/loading';
import { DoctorProfileModule } from '@shared/doctor-profile/doctor-profile.module';
import { CsIconModule } from '@components/icon';
import { DoctorViewComponent } from './doctor-view/doctor-view.component';
import { DoctorListComponent } from './doctor-list/doctor-list.component';

@NgModule({
  imports: [CommonModule,
    ToolbarModule,
    ButtonModule,
    LoadingModule,
    DoctorProfileModule,
    CsIconModule,
  ],
  declarations: [DoctorViewComponent, DoctorListComponent],
  exports: [],
})
export class DoctorModule {}
