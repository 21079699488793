import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { TestimonialsWidgetModule } from '@shared/testimonials/testimonials-widget/testimonials-widget.module';
import { TestimonialWallModule } from '@shared/testimonials/testimonial-wall/testimonial-wall.modules';
import { VideoFeedModule } from '@shared/testimonials/video-feed/video-feed.module';
import { TestimonialCaseStudiesModule } from '@shared/testimonials/testimonial-case-studies/testimonial-case-studies.module';
import { TestimonialsNewComponent } from './testimonials-new.component';

@NgModule({
  declarations: [TestimonialsNewComponent],
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    TestimonialsWidgetModule,
    TestimonialWallModule,
    VideoFeedModule,
    TestimonialCaseStudiesModule,
  ],
  exports: [TestimonialsNewComponent],
})
export class TestimonialsNewModule { }
