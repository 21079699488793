<section class="tw-bg-blue-100 tw-p-5 tw-mx-4 tw-border tw-border-gray-200 tw-rounded-xl tw-overflow-hidden tw-mb-6" style="background: white !important">
  <div class="tw-flex tw-items-center tw-gap-2 tw-mb-2">
    <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-black tw-tracking-normal" i18n="@@completeOrder">
      Complete your order
    </h2>
  </div>
  <div class="tw-flex tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg" (click)="handleClick()">
    <div class="tw-flex-none tw-flex tw-justify-center tw-items-center tw-border-r tw-border-gray-200 tw-box-content" [ngClass]='{ "tw-w-2/5": productsInCart[0]?.rebrandedImageWithBackground , "tw-p-5 tw-pl-5 tw-w-24 tw-bg-orange-100": !productsInCart[0]?.rebrandedImageWithBackground}'>
      <img class="tw-max-w-full" [ngClass]='{ "tw-max-h-full tw-min-w-full tw-min-h-full": productsInCart[0]?.rebrandedImageWithBackground , "tw-max-h-40 tw-object-contain": !productsInCart[0]?.rebrandedImageWithBackground }' [src]="productsInCart[0]?.rebrandedImageWithBackground || productsInCart[0]?.productUnboxedImage || productsInCart[0]?.rebrandedImageWithoutBackground" />
    </div>
    <div class="tw-flex-1 tw-px-4 tw-flex tw-flex-col tw-items-start tw-gap-2 tw-relative tw-pb-5" (click)="handleClick()" [ngClass]='{"tw-pt-5": !productsInCart[0]?.isDoctorAdded, "tw-pt-8": productsInCart[0]?.isDoctorAdded}'>
      <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-h-6 tw-absolute tw-top-2 tw-right-0 tw-rounded-l-full tw-bg-gray-100 tw-px-1" *ngIf="productsInCart[0]?.isDoctorAdded">
        <cs-icon class="tw-h-3 tw-w-3 tw-text-white tw-bg-green-400 tw-rounded-full" [iconName]='"done"'></cs-icon>
        <p class="tw-font-body tw-text-black tw-text-100 tw-text-blue-500">
          Dr. Suggested
        </p>
      </div>
      <div>
        <h3 class="tw-font-body tw-font-bold tw-text-black tw-tracking-normal">
          {{productsInCart[0]?.title}}
        </h3>
      </div>
      <div class="tw-flex-1"></div>
      <p class="tw-font-body tw-text-300 tw-tracking-normal tw-text-black">
        <span class="tw-font-bold">Total</span><span class="tw-line-through">&ngsp;&ngsp; ₹{{productsInCart[0]?.mrp}}&ngsp;</span><span class="tw-font-bold tw-text-orange-400">₹{{productsInCart[0]?.price}}</span>
      </p>
      <button class="tw-m-0 tw-min-w-unset tw-h-8 tw-px-4 tw-flex tw-items-center tw-gap-2 tw-bg-green-400" (click)="handleClick()">
        <span class="tw-normal-case tw-font-body tw-text-200 tw-tracking-normal tw-text-white tw-whitespace-break-spaces" i18n="@@buyNow">Buy Now</span><cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]='"chevron_right"'></cs-icon>
      </button>
    </div>
  </div>
</section>