<div class="tw-w-full tw-h-full tw-block tw-border-spacing-0 tw-overflow-hidden tw-font-semibold tw-font-body">
  <div class="tw-flex tw-h-full tw-flex-col tw-flex-nowrap">
    <div class="tw-flex-initial tw-w-full">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@checkup">checkup</span>
      </div>
    </div>
    <div class="tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto tw-w-full tw-p-6">
      <div class="tw-text-500 tw-font-bold tw-text-center tw-mt-20" i18n="@@chooseYourConcern"> Choose your concern </div>
      <div class="tw-flex tw-justify-center">
        <div class="tw-rounded-5 tw-bg-gray-200 tw-px-4 tw-py-[10px]" i18n="@@face" [ngClass]='{ "tw-bg-orange-400 tw-text-white": selectedConcern === "face"}' (click)='changeConcern("face")'>Face</div>
        <div class="tw-ml-5 tw-rounded-5 tw-bg-gray-200 tw-px-4 tw-py-[10px]" i18n="@@hair" [ngClass]='{ "tw-bg-orange-400 tw-text-white": selectedConcern === "hair"}' (click)='changeConcern("hair")'>Hair</div>
      </div>
      <div class="tw-text-center tw-text-sm tw-font-medium" i18n="@@yourDoctorWillDoCheckup"> Doctor will analyse your photo and do a checkup </div>
      <div class="tw-text-500 tw-font-bold tw-text-center tw-mt-2" i18n="@@isThereAnythingYouWishToTellYourDoctor"> Is there anything you wish to tell your doctor? </div>
      <textarea class="tw-w-full tw-rounded-3 tw-bg-gray-100 tw-h-56 tw-mt-6" placeholder="Write here" [(ngModel)]="userQuery" i18n-placeholder="@@writeHere"></textarea>
      <cs-button class="tw-mt-11" [class]="'primary tw-w-full'" (callback)="submit()">
        <cs-button-text i18n="@@submit">Submit</cs-button-text>
      </cs-button>
      <div class="tw-mt-3 tw-text-center" i18n="@@skipNow" (click)="skip()"> Skip now ></div>
    </div>
  </div>
</div>