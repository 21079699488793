<section class="tw-bg-blue-100 tw-p-5 tw-mx-4 tw-border tw-border-gray-200 tw-rounded-xl tw-overflow-hidden tw-mb-6" style="background: white !important">
    <div class="tw-flex tw-gap-6 tw-mb-2">
        <span [ngClass]='{"tw-w-[28%]": user?.get("languagePreference") === "hi" }'>
            <img src="/assets/icons/orange_tick_new.svg" class="tw-absolute tw-left-[70px]" id="tick">
            <img class="tw-flex-none tw-h-12 tw-w-12" src="https://storage.googleapis.com/heallocontent/app/img/dr-charu-main-concern.png" />
        </span>
        <span *ngIf="user?.get('languagePreference') !== 'hi'">
            <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-black tw-tracking-normal">
                Doctor added product
            </h2>
            <p class="tw-font-body tw-text-300 tw-text-black">
                basis your treatment
            </p>
        </span>
        <span *ngIf="user?.get('languagePreference') === 'hi'">
            <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-black tw-tracking-normal" i18n="@@drAddedProdTreatment">
                Doctor added product basis your treatment
            </h2>
        </span>
    </div>
    <div class="tw-flex tw-bg-white tw-rounded-lg" (click)="handleClick()">
        <div class="tw-relative tw-flex-none tw-w-[41%]">
            <img class="tw-max-w-full tw-rounded-2" [ngClass]='{ "tw-max-h-full tw-min-w-full tw-min-h-full": productsInCart[0]?.rebrandedImageWithBackground , "tw-max-h-40 tw-object-contain": !productsInCart[0]?.rebrandedImageWithBackground }' [src]="productsInCart[0]?.rebrandedImageWithBackground || productsInCart[0]?.productUnboxedImage || productsInCart[0]?.rebrandedImageWithoutBackground" />
            <div class="tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-flex tw-items-center tw-justify-center tw-gap-2 tw-bg-orange-100 tw-rounded-br-2 tw-rounded-bl-2 tw-px-2" *ngIf="productsInCart[0]?.isDoctorAdded">
                <cs-icon class="tw-h-3 tw-w-3 tw-text-bold tw-text-orange-400" [iconName]='"done"'></cs-icon>
                <p class="tw-font-body tw-text-orange-400 tw-text-200 tw-whitespace-nowrap" i18n="@@drAdded">
                    Dr. Added
                </p>
            </div>
        </div>
        <div class="tw-flex-1 tw-px-4 tw-flex tw-flex-col tw-items-start tw-gap-2 tw-relative tw-pb-5" (click)="handleClick()" [ngClass]='{"tw-pt-5": !productsInCart[0]?.isDoctorAdded, "tw-pt-8": productsInCart[0]?.isDoctorAdded}'>
            
            <div>
                <h3 class="tw-font-body tw-font-bold tw-text-black tw-tracking-normal">
                    {{productsInCart[0]?.title}}
                </h3>
            </div>
            <div class="tw-flex-1"></div>
            <p class="tw-font-body tw-text-300 tw-tracking-normal tw-text-black">
                <span class="tw-line-through">&ngsp;&ngsp; ₹{{productsInCart[0]?.mrp}}&ngsp;</span><span class="tw-font-bold tw-text-orange-400">₹{{productsInCart[0]?.price}}</span>
            </p>
            <button class="tw-m-0 tw-min-w-unset tw-h-10 tw-px-4 tw-rounded-3 tw-flex tw-items-center tw-gap-2 tw-bg-teal-600" (click)="handleClick()">
            <span class="tw-normal-case tw-font-body tw-text-300 tw-tracking-normal tw-text-white tw-whitespace-break-spaces" i18n="@@buyNow">Buy Now</span>
            </button>
        </div>
    </div>
    <div class="tw-mt-5" *ngIf="productsInCart[0]?.scriptAudioUrl">
        <c-audio class="c-audio" src="{{productsInCart[0]?.scriptAudioUrl}}" [trackEvent]="trackEventAudio" eventPrefix="OrderCreatedDoctor" (emitTrackEvent)="handleClickOne($event)"></c-audio>
    </div>
</section>
