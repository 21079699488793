import { NgModule } from '@angular/core';
import { ToolbarModule } from '@components/toolbar';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { SingleSelectorModule } from '@shared/single-selector/single-selector.module';
import { ButtonModule } from '@components/button';
import { PopUpModule } from '@components/pop-up';
import { ProductListModule } from '@shared/product-list/product-list.module';
import { TranslateStringsModule } from '@components/translate-string';
import { CsIconModule } from '@components/icon';
import { UserNotificationComponent } from './user-notification.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { NotificationDialogComponent } from './notification-popup/notification.dialog.component';
import { MultilineEllipsisModule } from './multiline-ellipsis/multiline-ellipsis.module';
import { CAudioModule } from './c-audio/c-audio.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    TranslateStringsModule,
    DirectivesModule,
    ProductListModule,
    ToolbarModule,
    SingleSelectorModule,
    ButtonModule,
    PopUpModule,
    MultilineEllipsisModule,
    CAudioModule,
    CsIconModule,
  ],
  declarations: [UserNotificationComponent, NotificationCardComponent, NotificationDialogComponent],
  exports: [UserNotificationComponent, NotificationCardComponent, NotificationDialogComponent],
})
export class UserNotificationModule { }
