import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { UserOrderFailureComponent } from './user-order-failure.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ButtonModule,
  ],
  declarations: [
    UserOrderFailureComponent,
  ],
  exports: [
    UserOrderFailureComponent,
  ],
})
export class UserOrderFailureModule {}
