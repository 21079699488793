import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { LoadingModule } from '@components/loading';
import { OnboardingGenderComponent } from './onboarding-gender.component';

@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
    FormsModule,
    ButtonModule,
    LoadingModule,
  ],
  declarations: [OnboardingGenderComponent],
  exports: [OnboardingGenderComponent],
})
export class OnboardingGenderModule {}
