import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { LoadingModule } from '@components/loading';
import { ToolbarModule } from '@components/toolbar';
import { SwiperModule } from 'swiper/angular';
import { IntroductionComponent } from './introduction.component';

@NgModule({
  imports: [CommonModule, ButtonModule, SwiperModule, LoadingModule, ToolbarModule],
  declarations: [IntroductionComponent],
  exports: [],
})
export class IntroductionModule {}
