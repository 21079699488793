import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { BrowserModule } from '@angular/platform-browser';
import { MultilineEllipsisComponent } from './multiline-ellipsis.component';

@NgModule({
  imports: [FormsModule, CommonModule, BrowserModule, DirectivesModule],
  declarations: [MultilineEllipsisComponent],
  exports: [MultilineEllipsisComponent],
})
export class MultilineEllipsisModule {}
