import { ConnectionService } from '@services/connection-service';
import { inject, Injectable } from '@angular/core';
import { Onboarding } from './onboarding';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private user: Record<string, any> = inject(ConnectionService).getActingUser();
  private isUserWithoutMainConcern: boolean = inject(ConnectionService).userWithoutPrivateMainConcern();

  getService(): Onboarding {
    return new Onboarding(JSON.parse(JSON.stringify(this.user)), this.isUserWithoutMainConcern);
  }
}
