import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { OnboardingTakePhotoComponent } from './onboarding-take-photo.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoadingModule,
    ButtonModule,
  ],
  declarations: [OnboardingTakePhotoComponent],
  exports: [OnboardingTakePhotoComponent],
})
export class OnboardingTakePhotoModule {
}
