<div class="hbox">
  <div class="flex-box">
  <div class="flex-heading">
    <toolbar (onAction)="back()"></toolbar>
  </div>
  <div class="flex-content" id="gameBoard">
    <img id="intro" src="https://cdn.cureskin.com/app/img/game-intro.gif" />
    <div id="loading">
    <svg class="circle-loader" width="40" height="40" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="15"></circle>
    </svg>
    </div>
    <canvas id="board"></canvas><canvas id="gameStatus"></canvas><audio id="bg-mp3" loop="">
    <source src="/assets/sounds/bg.wav" />
    </audio><audio id="jump-mp3">
    <source src="/assets/sounds/jump.mp3" />
    </audio><audio id="crash-mp3">
    <source src="/assets/sounds/crash.mp3" />
    </audio>
    <div id="gameOver">
    <div id="card">
      <img src="https://cdn.cureskin.com/app/game/rounded.png" />
      <div id="scoreCard">
      <span>Your have Scored</span><span id="score">543</span>
      </div>
      <div id="playAgain" (click)="playAgain($event)">Play Again</div>
      <div id="exit" (click)="exitGame($event)">Exit</div>
    </div>
    </div>
  </div>
  </div>
</div>