<div class="tw-mb-5 tw-relative">
    <ng-container *ngIf="productsInCartArray?.length > 1">
        <div class="tw-p-0 tw-mx-4 tw-border tw-border-gray-200 tw-rounded-xl tw-overflow-hidden tw-bg-white">
            <div class="tw-flex tw-flex-col tw-px-5 tw-py-4">
                <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-black tw-tracking-normal" i18n="@@completeOrder">
                    Complete your order
                </h2>
                <p class="tw-font-body tw-text-gray-500 tw-text-200" *ngIf="numberOfProductsInCart === 1" i18n="@@oneProductInCart">
                    1 Product in your cart
                </p>
                <p class="tw-font-body tw-text-gray-500 tw-text-200" *ngIf="numberOfProductsInCart > 1" i18n="@@multipleProductsInCart">
                    {{numberOfProductsInCart}} Products in your cart
                </p>
            </div>
            <div class="tw-grid tw-grid-flow-col tw-gap-6 tw-w-full tw-px-6 tw-overflow-auto tw-auto-cols-[minmax(30vw,1fr)]" (click)="goToCart.emit()">
                <div class="tw-relative tw-flex tw-flex-col tw-gap-2 [&:not(:last-child)]:after:tw-content-['+'] [&:not(:last-child)]:after:tw-text-gray-700 [&:not(:last-child)]:after:tw-font-bold [&:not(:last-child)]:after:tw-text-400 [&:not(:last-child)]:after:tw-absolute [&:not(:last-child)]:after:tw-top-[39%] [&:not(:last-child)]:after:tw-right-[-17px]" *ngFor="let product of productsInCartArray">
                    <div class="tw-flex tw-item-center tw-justify-center tw-relative tw-rounded-2xl tw-overflow-hidden">
                        <img class="tw-max-w-full tw-max-h-full tw-object-contain" [src]="product?.rebrandedImageWithBackground || product?.rebrandedImageWithoutBackground || product?.productUnboxedImage" />
                        <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1 tw-h-6 tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-bg-gray-100" *ngIf="product?.isDoctorAdded">
                            <cs-icon class="tw-h-3 tw-w-3 tw-text-white tw-bg-green-400 tw-rounded-full" [iconName]='"done"'></cs-icon>
                            <p class="tw-font-body tw-text-100 tw-text-blue-500">
                                Dr. Suggested
                            </p>
                        </div>
                    </div>
                    <h2 class="tw-flex-none tw-font-body tw-font-bold tw-text-200 tw-text-center tw-leading-tight tw-mb-1">
                        {{product?.title}}
                    </h2>
                </div>
            </div>
            <div class="tw-flex-auto tw-flex tw-flex-col tw-p-5 tw-pb-2 tw-bg-white">
                <div class="tw-flex">
                    <button class=" tw-bg-green-400 tw-border-none tw-relative tw-text-gray-50 tw-capitalize tw-text-[14px] tw-overflow-visible tw-mb-[10px] shine loop tw-m-0 tw-mx-auto tw-w-full tw-min-w-0 tw-box-border tw-h-11" (click)="goToCart.emit()">
                        <div class="tw-absolute tw-flex tw-items-center tw-justify-center tw-left-4" *ngIf="+cartDiscount > 5">
                            <svg class="tw-w-15 tw-h-15 tw-relative tw-animate-spin_slow tw-invert-[0.35] tw-sepia[0.69] tw-saturate-[1,30,300] tw-hue-rotate-[349deg] tw-brightness-[0.86] tw-contrast-[0.88]" xmlns="http://www.w3.org/2000/svg" width="64.443" height="67.447" viewBox="0 0 64.443 67.447">
                                <path id="Path_23051" data-name="Path 23051" d="M87.025,208.059,79.5,203.146l-2.347-8.676a3.039,3.039,0,0,0-3.088-2.243l-8.976.449-7-5.639a3.042,3.042,0,0,0-3.817,0l-7,5.639-8.975-.449a3.031,3.031,0,0,0-3.088,2.243l-2.347,8.676-7.525,4.912a3.042,3.042,0,0,0-1.179,3.63l3.2,8.4-3.2,8.4a3.042,3.042,0,0,0,1.179,3.63l7.525,4.913,2.347,8.675a3.034,3.034,0,0,0,3.088,2.243l8.975-.449,7,5.639a3.041,3.041,0,0,0,3.817,0l7-5.639,8.976.449a3.036,3.036,0,0,0,3.088-2.243l2.347-8.675,7.525-4.913a3.042,3.042,0,0,0,1.179-3.63l-3.2-8.4,3.2-8.4A3.042,3.042,0,0,0,87.025,208.059Z" transform="translate(-23.961 -186.363)" fill="#D66736"></path>
                            </svg><span class="tw-absolute tw-text-400 tw-text-white tw-uppercase tw-z-20 tw-font-bold tw-leading-none">{{cartDiscount}}% <br />
                                off</span>
                        </div>
                        <span class="tw-text-center tw-ml-[5px] tw-font-bold tw-font-body tw-text-300 tw-tracking-normal tw-normal-case"><span i18n="@@buyNow">Buy Now</span><span>&nbsp;&#64; ₹{{totalSp}}</span></span>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="productsInCartArray.length === 1">
        <section class="tw-bg-blue-100 tw-p-5 tw-mx-4 tw-border tw-border-gray-200 tw-rounded-xl tw-overflow-hidden" style="background: white !important">
            <div class="tw-flex tw-items-center tw-gap-2 tw-mb-2">
                <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-black tw-tracking-normal" i18n="@@completeOrder">
                    Complete your order
                </h2>
            </div>
            <div class="tw-flex tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg" (click)="goToCart.emit()">
                <div class="tw-flex-none tw-flex tw-justify-center tw-items-center tw-border-r tw-border-gray-200 tw-box-content" [ngClass]='{ "tw-w-2/5": productsInCartArray[0]?.rebrandedImageWithBackground , "tw-p-5 tw-pl-5 tw-w-24 tw-bg-orange-100": !productsInCartArray[0]?.rebrandedImageWithBackground}'>
                    <img class="tw-max-w-full" [ngClass]='{ "tw-max-h-full tw-min-w-full tw-min-h-full": productsInCartArray[0]?.rebrandedImageWithBackground , "tw-max-h-40 tw-object-contain": !productsInCartArray[0]?.rebrandedImageWithBackground }' [src]="productsInCartArray[0]?.rebrandedImageWithBackground || productsInCartArray[0]?.productUnboxedImage || productsInCartArray[0]?.rebrandedImageWithoutBackground" />
                </div>
                <div class="tw-flex-1 tw-px-4 tw-flex tw-flex-col tw-items-start tw-gap-2 tw-relative tw-pb-5" (click)="goToCart.emit()" [ngClass]='{"tw-pt-5": !productsInCartArray[0]?.isDoctorAdded, "tw-pt-8": productsInCartArray[0]?.isDoctorAdded}'>
                    <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-h-6 tw-absolute tw-top-2 tw-right-0 tw-rounded-l-full tw-bg-gray-100 tw-px-1" *ngIf="productsInCartArray[0]?.isDoctorAdded">
                        <cs-icon class="tw-h-3 tw-w-3 tw-text-white tw-bg-green-400 tw-rounded-full" [iconName]='"done"'></cs-icon>
                        <p class="tw-font-body tw-text-100 tw-text-blue-500">
                            Dr. Suggested
                        </p>
                    </div>
                    <div>
                        <h3 class="tw-font-body tw-font-bold tw-text-black tw-tracking-normal">
                            {{productsInCartArray[0]?.title}}
                        </h3>
                    </div>
                    <div class="tw-flex-1"></div>
                    <p class="tw-font-body tw-text-300 tw-tracking-normal tw-text-black">
                        <span class="tw-font-bold">Total</span><span class="tw-line-through">&nbsp;&nbsp; ₹{{productsInCartArray[0]?.mrp}}&nbsp;</span><span class="tw-font-bold tw-text-orange-400">₹{{productsInCartArray[0]?.price}}</span>
                    </p>
                    <button class="tw-m-0 tw-min-w-unset tw-h-8 tw-px-4 tw-flex tw-items-center tw-gap-2 tw-bg-green-400" (click)="goToCart.emit()">
                        <span class="tw-normal-case tw-font-body tw-text-200 tw-tracking-normal tw-text-white tw-whitespace-break-spaces" i18n="@@buyNow">Buy Now</span><cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]='"chevron_right"'></cs-icon>
                    </button>
                </div>
            </div>
        </section>
    </ng-container>
</div>
