import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { SwiperModule } from 'swiper/angular';
import { TestimonialsWidgetComponent } from './testimonials-widget.component';

@NgModule({
  declarations: [TestimonialsWidgetComponent],
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    CustomPipeModule,
    SwiperModule,
  ],
  exports: [TestimonialsWidgetComponent],
})
export class TestimonialsWidgetModule { }
