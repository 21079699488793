import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserOrderModule } from './user-order/user-order.module';
import { UserViewModule } from './user-view/user-view.module';
import { UserCheckoutModule } from './user-checkout/user-checkout.module';
import { UserPrescriptionViewModule } from './user-prescription/user-prescription-view.module';
import { UserProfileModule } from './user-profile/user-profile.module';
import { UserFollowupModule } from './user-followup/user-followup.module';
import { UserReferModule } from './user-refer/user-refer.module';
import { UserWalletModule } from './user-wallet/user-wallet.module';
import { UserFollowupReportModule } from './user-followup-report/user-followup-report.module';
import { UserDietModule } from './user-diet/user-diet.module';
import { UserRegimenModule } from './user-regimen/user-regimen.module';
import { UserAddressModule } from './user-address/user-address.module';
import { UserLiveModule } from './user-live/user-live.module';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    UserOrderModule,
    UserViewModule,
    UserCheckoutModule,
    UserPrescriptionViewModule,
    UserProfileModule,
    UserFollowupModule,
    UserAddressModule,
    UserReferModule,
    UserWalletModule,
    UserFollowupReportModule,
    UserDietModule,
    UserRegimenModule,
    UserLiveModule,
  ],
})
export class UserModule { }
