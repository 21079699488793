import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { ButtonModule } from '@components/button';
import { OnboardingMobileNumberComponent } from './onboarding-mobile-number.component';

@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
    FormsModule,
    LoadingModule,
    ButtonModule,
  ],
  declarations: [OnboardingMobileNumberComponent],
  exports: [OnboardingMobileNumberComponent],
})
export class OnboardingMobileNumberModule {
}
