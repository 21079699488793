import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({ selector: 'radio-btn', templateUrl: './radio-btn.html', styleUrls: ['./radio-btn.scss'] })
export class RadioBtnComponent {
  @Input('cssClass') cssClass: string;
  @Input('checked') checked: boolean;
  @Input('disabled') disabled: boolean;
  @Output('checkedChange') checkedChange: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  changeState(event: any): void {
    if (this.disabled) {
      event.stopPropagation();
      return;
    }
    this.checked = true;
    this.checkedChange.emit(this.checked);
    event.stopPropagation();
  }
}
