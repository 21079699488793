import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OnboardingService } from '@services/onboarding/onboarding.service';

const onboardingPages: string[] = ['/login', '/logout', '/language', '/onboarding/number', '/onboarding/otp'];
const exceptionUrls: string[] = ['/user/instantcheckup', '/logout', '/empty', 'force', 'iframe'];

/**
 * If user onboarding is not complete then redirect user to page where they left the app.
 *
 * @param route
 * @param state
 * @returns
 */
export const onboardingGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any => {
  const isExceptionUrl: any = exceptionUrls.includes(route.url.toString());
  const onboardingSerivce = inject(OnboardingService).getService();

  const validateOnboardingUrl: OnboardingPageUrl | null = onboardingSerivce.validateOnboarding();

  if (isExceptionUrl) return true;

  return validateOnboardingUrl ? createUrlTreeFromSnapshot(route, [validateOnboardingUrl]) : true;
};
