import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonialWallComponent } from './testimonial-wall.component';

@NgModule({
  declarations: [TestimonialWallComponent],
  imports: [CommonModule],
  exports: [TestimonialWallComponent],
})
export class TestimonialWallModule { }
