import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '@directives/directives.module';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { TranslateStringsModule } from '@components/translate-string';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { UserOrderListComponent } from './user-order-list.component';
import { OrderListComponent } from './order-list/order-list.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    ToolbarTabsModule,
    TranslateStringsModule,
    ToolbarModule,
    LoadingModule,
    RouterModule,
    CsIconModule,
  ],
  declarations: [
    UserOrderListComponent,
    OrderListComponent,
  ],
  exports: [
    UserOrderListComponent,
  ],
})
export class UserOrderListModule {}
