import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';

import { UserLiveComponent } from './user-live/user-live.component';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
  ],
  declarations: [
    UserLiveComponent,
  ],
  exports: [
    UserLiveComponent,
  ],
})
export class UserLiveModule { }
