<div class="tw-block tw-border-spacing-0 tw-w-full tw-flex-1 tw-overflow-x-hidden tw-h-screen">
  <div class="tw-flex tw-flex-col tw-flex-1 tw-h-full">
    <div class="tw-w-full tw-flex-initial">
      <toolbar (onAction)="back()">CureSkin Star</toolbar>
    </div>
    <div class="tw-w-full tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto">
      <img class="tw-w-full tw-block tw-max-w-[400px] tw-m-auto"
           src="https://cdn.cureskin.com/app/img/cureskin-star-1.jpg" />
      <img class="tw-w-full tw-block tw-max-w-[400px] tw-m-auto"
           src="https://cdn.cureskin.com/app/img/cureskin-star-2.jpg" />
      <img class="tw-w-full tw-block tw-max-w-[400px] tw-m-auto"
           src="https://cdn.cureskin.com/app/img/cureskin-star-3.jpg" />
      <img class="tw-w-full tw-block tw-max-w-[400px] tw-m-auto"
           src="https://cdn.cureskin.com/app/img/cureskin-star-4.jpg" />
      <img class="tw-w-full tw-block tw-max-w-[400px] tw-m-auto"
           src="https://cdn.cureskin.com/app/img/cureskin-star-5.jpg" />
      <img class="tw-w-full tw-block tw-max-w-[400px] tw-m-auto"
           src="https://cdn.cureskin.com/app/img/cureskin-star-6.jpg" />
      <img class="tw-w-full tw-block tw-max-w-[400px] tw-m-auto"
           src="https://cdn.cureskin.com/app/img/cureskin-star-7.jpg" />
      <div class="tw-p-5 tw-text-center">
        <div class="tw-text-blue-500"
             (click)='open("/cureskin-star/terms")'>
          By clicking yes, you are agreeing with terms and conditions<span class="pL5 bold">Learn more.</span>
        </div>
        <div class="tw-w-full tw-mt-5">
          <cs-button [class]="'primary tw-w-full'"
                     (callback)="agree(true)"><cs-button-text>Yes! Count me in</cs-button-text></cs-button>
        </div>
        <div class="tw-w-full tw-mt-5">
          <cs-button [class]="'secondary tw-w-full'"
                     (callback)="agree(false)"><cs-button-text>May be later</cs-button-text></cs-button>
        </div>
      </div>
    </div>
  </div>
</div>