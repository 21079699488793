import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { DataStoreService } from '@services/data-store-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { TimeService } from '@services/time-service';
import SwiperCore, { Scrollbar, SwiperOptions } from 'swiper';

SwiperCore.use([Scrollbar]);

@Component({
  selector: 'user-followup',
  templateUrl: './user-followup.html',
  // styleUrls: ['./user-followup.scss'],
  styles: [':host {@apply tw-block }'],
})
export class UserFollowupComponent {
  followUp: any = {};
  loading: boolean = true;
  orderPlaced: boolean = false;
  reports: Array<any> = [];
  user: any;
  @Input('hideTitle') hideTitle: boolean;
  showTakeFollowUpBanner: boolean;
  activeRegimenFound: boolean;
  isFollowUpReady: boolean = false;

  carouselConfig: SwiperOptions = {
    slidesPerView: 1,
    autoHeight: true,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    pagination: true,
    loop: true,
  };

  constructor(private conn: ConnectionService,
    private router: Router,
    private dataStore: DataStoreService,
    private broadcast: BroadcastService,
    private eventLogger: EventLoggerService,
    private timeService: TimeService,
    private changeDetectionRef: ChangeDetectorRef) {
  }

  async ngOnInit(): Promise<any> {
    this.user = await this.conn.getActingUser();
    await this.fetchFollowup();
    const regimens: any[] = await this.conn.fetchRegimens();
    this.orderPlaced = !!regimens.find((each: any): boolean => each.orderPlaced);
    this.activeRegimenFound = !!regimens.find((each: any): boolean => each.active);
    await this.loadFollowupReports();
    if (this.activeRegimenFound) this.showTakeFollowUpBanner = await this.conn.shouldShowFollowupBanner();
    this.loading = false;
    this.conn.updateLastActiveTime('FOLLOW_UP_TAB');
  }

  async loadFollowupReports(): Promise<any> {
    const where: any = { user: this.user, isSecondaryComparison: false };
    this.reports = await this.conn.fetchFollowUpReports({ where, descending: 'createdAt', include: ['doctor'] });
  }

  async fetchFollowup(): Promise<any> {
    let followUp: any = this.dataStore.get('FOLLOW_UP');
    if (!followUp) {
      followUp = await this.conn.findNextFollowUp();
      this.dataStore.set('FOLLOW_UP', followUp);
    }
    if (followUp) {
      this.followUp = followUp;
      this.followUp.date = followUp.get('effectiveFollowUpDate') || followUp.get('nextFollowUpDate');
    }

    // Initially this.followup is empty object {}, so check if object exist and has a get function.
    // eslint-disable-next-line
    if (!this.followUp || typeof this.followUp?.get !== 'function' || JSON.stringify(this.followUp) === '{}') return;

    if (this.followUp?.get('nextFollowUpDate')
      && this.timeService.differenceInDays(this.followUp?.get('nextFollowUpDate'), new Date()) <= 2) {
      this.isFollowUpReady = true;
      return;
    }

    this.isFollowUpReady = this.followUp?.get('isPreviousFollowUpSkipped') && !this.followUp?.get('ready');

    this.changeDetectionRef.detectChanges();
  }

  async requestFollowUp(): Promise<any> {
    try {
      const followUp: any = this.dataStore.get('FOLLOW_UP');
      if (followUp) {
        await this.conn.requestFollowUp({ followUpId: followUp.id });
      }
      this.conn.navigateToURL('/user?tab=chat');
    } catch (err) {
      this.broadcast.broadcast('NOTIFY', { message: err.toString() });
    }
  }

  startFollowUp(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'followUp-page-start-followUp' }));
    this.router.navigate([`/chatV2/${this.followUp.id}`], {
      queryParams: { type: 'followUp' },
    });
  }

  startFollowUpLong(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'followUp-long-page-start-followUp' }));
    this.router.navigate([`/chatV2/${this.followUp.id}`], {
      queryParams: { type: 'followUp' },
    });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  buyRegimen(): void {
    this.router.navigate(['/user'], { queryParams: { tab: 'regimen' } });
  }
}
