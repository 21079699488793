import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { DirectivesModule } from '@directives/directives.module';
import { GameComponent } from './game.component';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
    DirectivesModule,
  ],
  declarations: [GameComponent],
  exports: [],
})
export class GameModule {}
