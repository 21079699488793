import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';

declare global {
  function degpegInit(param: any): Promise<any>;
}

@Component({
  selector: 'app-user-live',
  templateUrl: './user-live.component.html',
  styleUrls: ['./user-live.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserLiveComponent implements OnInit {
  user: any;
  constructor(
    private windowRef: WindowRefService,
    private broadcastService: BroadcastService,
    private conn: ConnectionService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.user = await this.conn.getActingUser();

    setTimeout((): void => {
      if (this.windowRef.nativeWindow.document.getElementById('degpegLiveTv')) {
        degpegInit({ username: this.user.get('PatientName') });
      }
    }, 100);
  }

  back(): void {
    this.broadcastService.broadcast('NAVIGATION_BACK');
  }
}
