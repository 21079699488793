/* eslint-disable no-underscore-dangle */
import { Component, Input, OnInit } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { Table } from 'api-client';
import { Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../../../app.config';
import { FollowUpReport } from '../../../../app-constant-types';

@Component({
  selector: 'notification-card',
  templateUrl: './notification-card.html',
  styleUrls: ['./notification-card.scss'],
})
export class NotificationCardComponent implements OnInit {
  private _notification: any;
  private cart: any;
  @Input('notification') set notification(notification: any) {
    if (!notification) return;
    this._notification = notification;
    const urlSplit = notification?.openURL?.split('/');
    let orderId: string = urlSplit[urlSplit.length - 1];
    if (orderId.includes('?')) {
      orderId = orderId.split('?')[0];
    }
    if (notification?.category === 'order' && orderId) {
      (async (): Promise<void> => {
        this.order = await this.conn.findOrderWithSignedURL(orderId);
        this.showPayButton = [
          this.appConfig.Shared.Order.Stage.INITIAL,
          this.appConfig.Shared.Order.Stage.ONLINE_PAYMENT_PENDING,
        ].includes(this.order.get('stage'));
        this.productInfo = this.order.get('products')?.map((item: any): Object => item);
        this.getCart();
      })();
    }
  }

  get notification(): any {
    return this._notification;
  }

  async getCart(): Promise<void> {
    this.cart = await this.conn.getCart();
    this.getAddtoCartProducts();
  }

  getAddtoCartProducts(): void {
    if (!this.productInfo || !this.cart) return;
    const cartProductIds = this.cart.get('lineItems')?.map((product: any):any => product.productId);
    this.productInfo = this.productInfo.filter((product: any):any => !cartProductIds.includes(product.id));
  }

  isInternalUser: boolean;
  text: string;
  timeStamp: any;
  notificationImage: string = '/assets/images/general.svg';
  ui: { dialog: { open: boolean } } = { dialog: { open: false } };
  order: any;
  showPayButton: boolean = false;
  cancelOrderAllowed: boolean;
  productInfo: any[] = [];
  followUpReport: FollowUpReport;

  constructor(
    public appConfig: AppConfig,
    private conn: ConnectionService,
    private connectionService: ConnectionService,
    private broadcastService: BroadcastService,
    private eventLoggerService: EventLoggerService,
    private router: Router,
  ) {
    this.isInternalUser = this.connectionService.isInternalUser();
  }

  async ngOnInit(): Promise<void> {
    this.getValidTimeStamp();
    this.notificationImage = this.getNotificationImage();
    this.text = this.isInternalUser ? (this.notification.messageInEnglish || this.notification.message)
      : (this.notification.message || this.notification.messageInEnglish);
    if (!this.text) return;
    this.text = this.text
      .replace(/\n+/g, '')
      .replace(/<br>/g, '')
      .replace(/<\/br>/g, '')
      .replace(/<li>/g, '')
      .replace(/<\/li>/g, '');
  }

  async markNotificationViewed(): Promise<void> {
    if (this.connectionService.isInternalUser() || this.notification.viewed) {
      return;
    }
    this.notification.viewed = true;
    const notification = new Table.Notification();
    notification.id = this.notification.objectId;
    await notification.save({ viewed: true });
  }

  onClickReadMore(): void {
    this.markNotificationViewed();
    if (this.showPayButton) {
      this.navigateToURL();
      return;
    }
    this.ui.dialog.open = true;
  }

  closeReadMore(): void {
    this.ui.dialog.open = false;
  }

  getValidTimeStamp(): void {
    const todayDate: any = new Date();
    const createdDate: any = new Date(this.notification.createdAt);
    const differenceInMilliSecond = todayDate - createdDate;
    const differenceInMinute = Math.floor(differenceInMilliSecond / 60000);
    this.timeStamp = differenceInMinute;
    if (differenceInMinute < 1) {
      this.timeStamp = 'Now';
    }
    if (differenceInMinute < 60 && differenceInMinute >= 1) {
      this.timeStamp = `${Math.floor(differenceInMinute)} m`;
    }
    if (differenceInMinute > 60) {
      this.timeStamp = `${Math.floor(differenceInMinute / 60)} h`;
    }
    if (differenceInMinute > 60 && Number(this.timeStamp.split(' ')[0]) >= 24) {
      const days = Math.floor(Number(this.timeStamp.split(' ')[0]) / 24);
      this.timeStamp = `${days} DAY`;
    }
  }

  private getNotificationImage(): string {
    switch (this.notification.category) {
      case this.appConfig.Shared.Notification.Category.Regimen: {
        return '/assets/images/notification-regimen.svg';
      }
      case this.appConfig.Shared.Notification.Category.Order: {
        return '/assets/images/notification-order.svg';
      }
      case this.appConfig.Shared.Notification.Category.Ticket: {
        return '/assets/images/notification-ticket.svg';
      }
      case this.appConfig.Shared.Notification.Category.FollowUp: {
        return '/assets/images/notification-followup.svg';
      }
      case this.appConfig.Shared.Notification.Category.Referral: {
        return '/assets/images/notification-referal.svg';
      }
      case this.appConfig.Shared.Notification.Category.Doctor: {
        return '/assets/images/notification-doctor.svg';
      }
      case this.appConfig.Shared.Notification.Category.Cash: {
        return '/assets/images/notification-cash-allocation.svg';
      }
      case this.appConfig.Shared.Notification.Category.General:
      default: return '/assets/images/notification-general.svg';
    }
  }

  navigateToURL(): void {
    if (this.notification.openURL.includes('&redirectTo=browser')) {
      const url = this.notification.openURL.substring(0, this.notification.openURL.indexOf('&redirectTo=browser'));
      this.broadcastService.broadcast('OPEN_IN_NEW_TAB', { url });
    }
    if (this.notification.track) {
      this.eventLoggerService.trackEvent(this.notification.track.event, this.notification.track.data);
    }
    if (this.notification.category?.includes('order')) {
      setTimeout(async (): Promise<void> => {
        this.productInfo?.forEach(async (product:any):Promise<any> => {
          await this.conn.addProductToCart({ productId: product?.id, quantity: 1, section: 'user-notification' });
        });
        this.router.navigate(['cart']);
      }, 100);
    }
  }

  async trackEvent(event: string): Promise<void> {
    let trackingTable;
    let objectId;
    if (this.notification.questionId === 'newFollowUpReport') {
      objectId = this.notification.openURL.split('/').splice(-1)[0].split('?')[0];
      trackingTable = 'followUpReport';
    }
    if (this.notification.questionId === 'doctorMessage') {
      objectId = this.notification.track.data.orderChatId;
      trackingTable = 'orderChat';
    }
    if (!trackingTable || !objectId) return;
    switch (event) {
      case 'play': {
        await this.conn.trackVoiceNotesAndScrollEvents({ objectId,
          trackingTable,
          eventName: 'audioPlayed' });
        break;
      }
      case 'completed': {
        await this.conn.trackVoiceNotesAndScrollEvents({ objectId,
          trackingTable,
          eventName: 'audioFinished' });
        break;
      }
      default:
    }
  }
}
