<div class="hbox">
  <loading class="pos-fix vh-center" *ngIf="loading"></loading>
  <div class="flex-box">
    <div class="flex-heading">
      <toolbar (onAction)="back()" i18n="@@followupReminder">Monthly Check-up Reminder</toolbar>
    </div>
    <div class="flex-content" *ngIf="!loading">
      <div class="doctor-image">
        <img *ngIf="doctor" [src]='doctor.get("doctorDisplayImage") || doctor.get("DoctorImageThumbnail") || "https://cdn.cureskin.com/app/img/dp-dr-charu.jpg"' />
        <div class="mT20 bold fontL2">{{doctor.get('DoctorDisplayName')}}</div>
      </div>
      <div class="info" i18n="@@cureskinTreatmentInlcudes">
        Cureskin treatment kit includes monthly checkups, where we review your
        progress
      </div>
      <section class="banners" (click)="requestFollowUp()">
        <img src="https://cdn.cureskin.com/app/img/take-followup.png" />
      </section>
    </div>
    <div class="flex-footer p20" *ngIf="!loading">
      <cs-button (callback)="requestFollowUp()" [class]="'primary w100'"><cs-button-text i18n="@@takeCheckup">Take Check-up</cs-button-text></cs-button>
    </div>
  </div>
</div>