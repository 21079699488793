import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutes, declarations } from './app-routes';
import { UserModule } from './user/user.module';
import { AboutModule } from './about/about.module';
import { ArticleModule } from './article/article.module';
import { ProductDescriptionModule } from './product-description/product-description.module';
import { EmptyModule } from './empty/empty.module';
import { FaqsModule } from './faqs/faqs.module';
import { FeedbackModule } from './feedback/feedback.module';
import { HelpModule } from './help/help.module';
import { IntroductionModule } from './introduction/introduction.module';
import { LoginDoctorModule } from './login/doctor/login-doctor.module';
import { LogoutModule } from './logout/logout.module';
import { MainConcernModule } from './main-concern/main-concern.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { IframeModule } from './iframe/iframe.module';
import { NoticeModule } from './notice/notice.module';
import { VideoModule } from './video/video.module';
import { CureskinStarModule } from './cureskin-star/cureskin-star.module';
import { GameModule } from './game/game.module';
import { DoctorModule } from './doctor/doctor.module';
import { SupportModule } from './support/support.module';
import { ChatV2Module } from './chatV2/chatV2.module';
import { UserCheckupModule } from './user/user-checkup/user-checkup.module';
import { ProductCartModule } from './cart/cart.module';
import { InstaLiveInfoModule } from './insta-live-info/insta-live-info.module';
import { OnboardingLanguageModule } from './onboarding-language/onboarding-language.module';
import { QuizGameModule } from './quiz-game/quiz-game.module';
import { TestimonialsNewModule } from './testimonials-new/testimonials-new.module';
import { TestimonialsDescModule } from './testimonials-description/testimonials-description.module';
import { ReportModule } from './report/report.module';
import { ReAcquisitionTakePhotoModule } from './re-acquisition-flow/re-acquisition-take-photo/re-acquisition-take-photo.module';
import { ReAcquisitionUserQueryModule } from './re-acquisition-flow/re-acquisition-user-query/re-acquisition-user-query.module';
import { ReAcquisitionUserWaitingForCallModule } from
  './re-acquisition-flow/re-acquisition-waiting-for-call/re-acquisition-waiting-for-call.module';
import { InstantCheckupModule } from './instant-checkup/instant-checkup.module';

@NgModule({
  providers: [],
  imports: [
    RouterModule.forRoot(AppRoutes),
    AboutModule,
    ArticleModule,
    CureskinStarModule,
    DoctorModule,
    EmptyModule,
    FaqsModule,
    FeedbackModule,
    GameModule,
    HelpModule,
    IframeModule,
    IntroductionModule,
    LogoutModule,
    LoginDoctorModule,
    MainConcernModule,
    NoticeModule,
    OnboardingModule,
    SupportModule,
    UserModule,
    VideoModule,
    ChatV2Module,
    UserCheckupModule,
    ProductDescriptionModule,
    QuizGameModule,
    ProductCartModule,
    InstaLiveInfoModule,
    OnboardingLanguageModule,
    TestimonialsNewModule,
    TestimonialsDescModule,
    ReportModule,
    ReAcquisitionTakePhotoModule,
    ReAcquisitionUserQueryModule,
    ReAcquisitionUserWaitingForCallModule,
    InstantCheckupModule,
  ],
  declarations,
  exports: [RouterModule],
})
export class AppRoutingModule {}
