import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { ReportCardComponent } from './report-card.component';

@NgModule({
  imports: [CommonModule, LoadingModule, DirectivesModule, CustomPipeModule, ButtonModule],
  declarations: [ReportCardComponent],
  exports: [ReportCardComponent],
})
export class ReportCardModule {}
