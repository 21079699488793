import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { CsIconModule } from '@components/icon';
import { ButtonModule } from '@components/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NotificationPermissionSheetModule } from
  '@shared/bottom-sheet-layouts/notification-permission-sheet/notification-permission.module';
import { UserOrderSuccessComponent } from './user-order-success.component';

@NgModule({
  imports: [
    RouterModule,
    CsIconModule,
    CommonModule,
    ToolbarModule,
    ButtonModule,
    MatBottomSheetModule,
    NotificationPermissionSheetModule,
  ],
  declarations: [
    UserOrderSuccessComponent,
  ],
  exports: [
    UserOrderSuccessComponent,
  ],
})
export class UserOrderSuccessModule {}
