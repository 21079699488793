<div class="tw-p-0 tw-mx-4 tw-border tw-border-gray-200 tw-rounded-xl tw-overflow-hidden tw-bg-white tw-mb-6">
  <div class="tw-flex tw-flex-col tw-px-5 tw-py-4">
    <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-black tw-tracking-normal" i18n="@@completeOrder">
      Complete your order
    </h2>
    <p class="tw-font-body tw-text-gray-500 tw-text-200" *ngIf="toolbarIcons[1].cartValue === 1" i18n="@@oneProductInCart">
      1 Product in your cart
    </p>
    <p class="tw-font-body tw-text-gray-500 tw-text-200" *ngIf="toolbarIcons[1].cartValue > 1" i18n="@@multipleProductsInCart">
      {{ toolbarIcons[1].cartValue }} Products in your cart
    </p>
  </div>
  <div class="tw-grid tw-grid-flow-col tw-gap-6 tw-w-full tw-px-6 tw-overflow-auto tw-auto-cols-[minmax(30vw,1fr)]" (click)="handleClick()">
    <div class="tw-relative tw-flex tw-flex-col tw-gap-2 [&amp;:not(:last-child)]:after:tw-content-['+'] after:tw-text-gray-700 after:tw-font-bold after:tw-text-400 after:tw-absolute after:tw-top-[33%] after:tw-right-[-17px]" *ngFor="let product of productsInCart">
      <div class="tw-flex tw-item-center tw-justify-center tw-relative tw-rounded-2xl tw-overflow-hidden">
        <img class="tw-max-w-full tw-max-h-full tw-object-contain" [src]="
      product?.rebrandedImageWithBackground ||
      product?.rebrandedImageWithoutBackground ||
      product?.productUnboxedImage
      " />
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1 tw-h-6 tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-bg-gray-100" *ngIf="product?.isDoctorAdded">
          <cs-icon class="tw-h-3 tw-w-3 tw-text-white tw-bg-green-400 tw-rounded-full" [iconName]="'done'"></cs-icon>
          <p class="tw-font-body tw-text-black tw-text-100 tw-text-blue-500">
            Dr. Suggested
          </p>
        </div>
      </div>
      <h2 class="tw-flex-none tw-font-body tw-font-bold tw-text-200 tw-text-center tw-leading-tight tw-mb-1">
        {{ product?.title }}
      </h2>
      <!--p.tw-flex-none.tw-font-body.tw-text-100.tw-text-center {{product?.quantity}} {{product?.quantityUnit}}-->
    </div>
  </div>
  <div class="tw-flex-auto tw-flex tw-flex-col tw-p-2 tw-bg-white">
    <div class="tw-flex">
      <button class="buy-now-btn shine loop tw-m-0 tw-w-full tw-bg-green-400 tw-min-w-0 tw-box-border tw-h-11" (click)="handleClick()">
        <div class="svg-container" *ngIf="cartDiscount > 5">
          <svg class="anim-image orange-spin tw-invert-[0.35] tw-sepia[0.69] tw-saturate-[1,30,300] tw-hue-rotate-[349deg] tw-brightness-[0.86] tw-contrast-[0.88]" xmlns="http://www.w3.org/2000/svg" width="64.443" height="67.447" viewBox="0 0 64.443 67.447">
            <path id="Path_23051" data-name="Path 23051" d="M87.025,208.059,79.5,203.146l-2.347-8.676a3.039,3.039,0,0,0-3.088-2.243l-8.976.449-7-5.639a3.042,3.042,0,0,0-3.817,0l-7,5.639-8.975-.449a3.031,3.031,0,0,0-3.088,2.243l-2.347,8.676-7.525,4.912a3.042,3.042,0,0,0-1.179,3.63l3.2,8.4-3.2,8.4a3.042,3.042,0,0,0,1.179,3.63l7.525,4.913,2.347,8.675a3.034,3.034,0,0,0,3.088,2.243l8.975-.449,7,5.639a3.041,3.041,0,0,0,3.817,0l7-5.639,8.976.449a3.036,3.036,0,0,0,3.088-2.243l2.347-8.675,7.525-4.913a3.042,3.042,0,0,0,1.179-3.63l-3.2-8.4,3.2-8.4A3.042,3.042,0,0,0,87.025,208.059Z" transform="translate(-23.961 -186.363)" fill="#D66736"></path>
          </svg><span class="off">{{ cartDiscount }}% <br />
            off</span>
        </div>
        <span class="btn-txt tw-font-body tw-lowercase tw-text-300 tw-tracking-normal tw-normal-case"><span i18n="@@buyNow">Buy Now</span><span>&nbsp;&#64; ₹{{ totalSp }}</span></span>
      </button>
    </div>
  </div>
</div>