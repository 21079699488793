import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { CsIconModule } from '@components/icon';
import { FaqsComponent } from './faqs.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ToolbarModule,
    LoadingModule,
    CustomPipeModule,
    CsIconModule,
  ],
  declarations: [FaqsComponent],
  exports: [],
})
export class FaqsModule {}
