import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { AboutComponent } from './about.component';

@NgModule({
  imports: [CommonModule,
    ToolbarModule,
    LoadingModule,
    CsIconModule,
  ],
  declarations: [AboutComponent],
  exports: [],
})
export class AboutModule {}
