<div class="tw-w-full tw-h-full tw-block tw-border-spacing-0 tw-overflow-hidden tw-font-semibold tw-font-body" tw-w-full tw-h-full tw-block tw-border-spacing-0 tw-overflow-hidden tw-font-semibold tw-font-body>
  <div class="tw-flex tw-h-full tw-flex-col tw-flex-nowrap">
    <div class="tw-flex-initial tw-w-full">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@checkup">checkup</span>
      </div>
    </div>
    <div class="tw-p-6 bg-light-yellow tw-relative h55">
      <div class="tw-mt-14">
        <img class="tw-m-auto tw-rounded-full" src="/assets/images/sand-watch-loader.gif" width="80px" height="80px" />
      </div>
      <div class="tw-text-center tw-text-sm tw-font-medium tw-mt-1.5" i18n="@@yourCheckupIsInProcess"> Your checkup is in progress </div>
      <div class="tw-text-500 tw-font-bold tw-text-center tw-mt-2" i18n="@@drMahimaWillCallYouWithin2-3hr"> Dr. Mahima will call your within 2-3hrs </div>
      <section class="tw-py-4 tw-bg-white tw-rounded-xl tw-shadow-z1 tw-absolute tw-overflow-hidden tw-bottom-[-100px] tw-mr-6">
        <div class="tw-bg-grid-layout-doctor tw-bg-right tw-bg-repeat-y tw-absolute tw-top-0 tw-bottom-0 tw-left-60 tw-right-[-300px]"></div>
        <div class="tw-z-10 tw-relative">
          <div class="tw-px-6 tw-py-2 tw-mb-3 tw-bg-orange-100 tw-rounded-tr-lg tw-rounded-br-lg tw-w-content tw-flex tw-flex-row tw-items-center tw-gap-2">
            <cs-icon class="tw-w-5 tw-h-5 tw-text-orange-500" [iconName]="'check_circle'"></cs-icon>
            <p class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-400" i18n="@@yourSkinSpecialist"> Your skin specialist </p>
          </div>
          <div class="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-pl-6 tw-pr-4 tw-justify-between">
            <div>
              <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-900 tw-mb-1"> Dr. Mahima </h2>
              <p class="tw-font-body tw-text-300 tw-text-gray-400 tw-mb-2"> MBBS, MD - Dermatlogist, Skin Specialist </p>
              <p class="tw-font-body tw-font-bold tw-text-200 tw-text-gray-900" i18n="@@hasTreated1000CureskinPatients"> Has treated 1000+ <br />Cureskin patients </p>
            </div>
            <img class="tw-flex-none tw-h-28 tw-w-28 tw-rounded-full tw-bg-green-100" src="https://storage.googleapis.com/heallo-other-user-files/doctor_api_mahima_aggarwal_12052022_1670250785248.jpg" alt="Doctor Image" />
          </div>
        </div>
      </section>
    </div>
    <div class="tw-w-full tw-flex-[0_1_0] tw-px-6">
      <cs-button class="tw-mt-[150px]" [class]="'primary tw-w-full'" (callback)="submit()">
        <cs-button-text i18n="@@okay">okay</cs-button-text>
      </cs-button>
    </div>
  </div>
</div>