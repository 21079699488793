import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { DirectivesModule } from '@directives/directives.module';
import { UserViewComponent } from './user-view.component';
import { UserProfileModule } from '../user-profile/user-profile.module';
import { UserViewRegimenModule } from './user-regimen/user-view-regimen.module';
import { UserAssistantModule } from './user-assistant/user-assistant.module';
import { UserHomeModule } from './user-home/user-home.module';
import { UserExploreModule } from './user-explore/user-explore.module';
import { UserNotificationModule } from './user-notification/user-notification.module';
import { UserShopModule } from './user-shop/user-shop.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    CsIconModule,
    DirectivesModule,
    LoadingModule,
    UserProfileModule,
    UserViewRegimenModule,
    UserAssistantModule,
    UserHomeModule,
    UserExploreModule,
    UserNotificationModule,
    UserShopModule,
  ],
  declarations: [UserViewComponent],
})
export class UserViewModule {}
