import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserOrderReorderModule } from './user-order-reorder/user-order-reorder.module';
import { UserOrderPaymentModule } from './user-order-payment/user-order-payment.module';
import { UserOrderListModule } from './user-order-list/user-order-list.module';
import { UserOrderSuccessModule } from './user-order-success/user-order-success.module';
import { UserOrderFailureModule } from './user-order-failure/user-order-failure.module';
import { UserOrderAddonModule } from './user-order-addon/user-order-addon.module';
import { UserOrderViewModule } from './user-order-view/user-order-view.module';

@NgModule({
  imports: [
    CommonModule,
    UserOrderPaymentModule,
    UserOrderListModule,
    UserOrderViewModule,
    UserOrderSuccessModule,
    UserOrderFailureModule,
    UserOrderReorderModule,
    UserOrderPaymentModule,
    UserOrderAddonModule,
  ],
  declarations: [],
  exports: [],
})
export class UserOrderModule {}
