import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowRefService } from '@services/window-ref-service';
import { ConnectionService } from '@services/connection-service';
import { DataStoreService } from '@services/data-store-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'user-followup-reminder',
  templateUrl: './user-followup-reminder.html',
  styleUrls: ['./user-followup-reminder.scss'],
})
export class UserFollowupReminderComponent {
  loading: boolean = true;
  user: any;
  doctor: any;
  static parameters: Array<any> = [
    DomSanitizer,
    WindowRefService,
    ConnectionService,
    Router,
    DataStoreService, ActivatedRoute, AppConfig, BroadcastService];
  constructor(private dom: DomSanitizer,
              private window: WindowRefService,
              private conn: ConnectionService,
              private router: Router, private dataStore: DataStoreService, private route: ActivatedRoute,
              private appConfig: AppConfig, private broadcast: BroadcastService) {
  }

  async ngOnInit(): Promise<any> {
    this.user = await this.conn.getActingUser();
    this.doctor = this.user.get('allocatedDoctor');
    this.loading = false;
  }

  async requestFollowUp(): Promise<any> {
    try {
      const followUp = await this.conn.findNextFollowUp();
      if (followUp) {
        await this.conn.requestFollowUp({ followUpId: followUp.id });
      }
      this.conn.navigateToURL('/user?tab=chat');
    } catch (err) {
      this.broadcast.broadcast('NOTIFY', { message: err.toString() });
    }
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
