import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { DirectivesModule } from '@directives/directives.module';
import { InstaLiveInfoComponent } from './insta-live-info.component';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
    DirectivesModule,
  ],
  declarations: [InstaLiveInfoComponent],
  exports: [InstaLiveInfoComponent],
})
export class InstaLiveInfoModule {}
