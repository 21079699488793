import { Component, Input } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';

@Component({ selector: 'terms', templateUrl: './cureskin-star-terms.html' })
export class CureskinStarTermsComponent {
  static parameters: Array<any> = [BroadcastService];
  constructor(private broadcast: BroadcastService) {}

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
