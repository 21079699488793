<section class="reminder-action n-p-s-section" (click)="openFeedback()">
  <div class="n-p-s-box card text-center">
  <div class="npsBoxText">
    <span class="w40">
    <div class="fw-700" [ngClass]="{ 'tw-text-400': checkIfLanguageWithUIIssues, 'tw-text-600': !checkIfLanguageWithUIIssues}" i18n="@@tellUsYourFeedback">
      Tell Us Your Feedback
    </div>
    </span><span class="icons"><img class="icon" src="/assets/images/nps-1.png" /><img class="icon" src="/assets/images/nps-2.png" /><img class="icon" src="/assets/images/nps-3.png" /><img class="icon" src="/assets/images/nps-4.png" /><span class="multiple-icon-container"><img class="icon-header icon" src="/assets/images/nps-6.png" /><img class="icon" src="/assets/images/nps-5.png" /><img class="icon-footer icon" src="/assets/images/nps-hand.png" /></span></span>
  </div>
  <div>
    <div class="small-btn share" i18n="@@shareHere">Share Here</div>
  </div>
  </div>
</section>
