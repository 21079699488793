<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<loading class="pos-abs vh-center" *ngIf="loading"></loading><ng-container *ngIf="reports?.length && !loading">
  <section class="tw-bg-teal-200 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-px-6 tw-pb-6 tw-pt-24">
    <h1 class="tw-font-body tw-font-bold tw-text-700 tw-mb-3" i18n="@@checkupsNew">Checkups</h1>
    <p class="tw-font-body tw-text-300" i18n="@@cureskinTreatmentInlcudes">
      Cureskin treatment kit includes monthly checkups, where we review your
      progress
    </p>
    <div class="followup-date tw-pt-4" *ngIf="followUp.date">
      <h2 class="tw-font-body tw-font-bold tw-text-500" i18n="@@yourNextCheckupIsDueOn">
        Your next monthly checkup is due on<span>
          {{followUp.date | date: 'd MMM yyyy'}}</span>
      </h2>
    </div>
  </section>
  <section class="tw-flex tw-col tw-px-6 tw-gap-4 tw-pt-5" *ngFor="let report of reports">
    <report-card class="tw-flex-1" [data]="report"></report-card>
  </section>
</ng-container><ng-container *ngIf="!loading && !reports?.length">
  <section class="tw-bg-teal-200 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-px-6 tw-pb-6 tw-pt-24">
    <h1 class="tw-font-body tw-font-bold tw-text-700 tw-mb-3" i18n="@@neverMissYourDoctorCheckup">
      NEVER MISS YOUR DOCTOR CHECKUP
    </h1>
  </section>
  <section class="tw-flex tw-flex-col tw-pl-6 tw-py-8">
    <h2 class="tw-font-body tw-font-bold tw-text-500 tw-mb-3 tw-mr-[35%]" i18n="@@whatWillHappenInCheckup">
      What will happen in doctor checkup?
    </h2>
    <div class="tw-flex tw-items-center tw-gap-3">
      <div class="tw-font-body tw-text-300" i18n="@@ourDoctorsWillMonitorYour">
        Our doctors will monitor your skin/hair progress and will share your
        latest skin report. Your doctor checkup report will include a voice note
        from the doctor, a comparison report of your skin/hair progress and next
        steps to the treatment. 
      </div>
      <img class="tw-max-h-48 tw-mr-[-10%]" src="https://storage.googleapis.com/heallocontent/app/img/hand_with_tab.png" alt="" />
    </div>
  </section>
  <section class="tw-px-6 tw-pt-8 tw-bg-green-100">
    <h2 class="tw-font-body tw-font-bold tw-text-700 tw-mb-8" i18n="@@HowItWorks">
      How it works?
    </h2>
    <div class="tw-flex tw-flex-col tw-items-start tw-gap-6 tw-mb-5">
      <div class="tw-flex tw-items-start tw-gap-5">
        <div class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-uppercase tw-bg-green-300 tw-rounded-full tw-px-2 tw-py-1">
          Step 1
        </div>
        <div class="tw-flex-1 tw-font-body tw-text-300" i18n="@@take23PhotoOfYour">
          Take 2-3 photos of your face/scalp, and answer a few questions.
        </div>
      </div>
      <div class="tw-flex tw-items-start tw-gap-5">
        <div class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-uppercase tw-bg-green-300 tw-rounded-full tw-px-2 tw-py-1">
          Step 2
        </div>
        <div class="tw-flex-1 tw-font-body tw-text-300" i18n="@@ourDoctorWillReviewYourImprovement">
          Our doctor will review your improvement (it will take 2 days).
        </div>
      </div>
      <div class="tw-flex tw-items-start tw-gap-5">
        <div class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-uppercase tw-bg-green-300 tw-rounded-full tw-px-2 tw-py-1">
          Step 3
        </div>
        <div class="tw-flex-1 tw-font-body tw-text-300" i18n="@@getACheckupReportAndAVoiceMessage">
          Get a checkup report and a voice message from the doctor on your
          progress.
        </div>
      </div>
      <div class="tw-flex tw-items-start tw-gap-5">
        <div class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-uppercase tw-bg-green-300 tw-rounded-full tw-px-2 tw-py-1">
          Step 4
        </div>
        <div class="tw-flex-1 tw-font-body tw-text-300" i18n="@@followTheChnagesAdvisedBy">
          Follow the changes advised by your doctor and get faster results.
        </div>
      </div>
    </div>
    <div class="tw-rounded-4 tw-px-5 tw-py-4 tw-bg-green-300 tw-relative tw-flex tw-flex-col tw-items-start tw-gap-3 tw-justify-center tw-mb-[-20%] tw-min-h-[120px]">
      <ng-container *ngIf="!followUp.date">
        <h2 class="tw-font-body tw-font-bold tw-text-500" i18n="@@youDontHaveAnyCheckUpsDue">
          You don't have any checkup scheduled right now
        </h2>
      </ng-container><ng-container *ngIf="followUp.date">
        <h2 class="tw-font-body tw-font-bold tw-text-500" i18n="@@yourNextCheckupIsDueOn">
          Your next monthly checkup is due on<span>
            {{followUp.date | date: 'd MMM yyyy'}}</span>
        </h2>
        <h2 class="tw-font-body tw-text-300" *ngIf="isFollowUpReady" i18n="@@toStartYourCheckup">
          to start your checkup
        </h2>
        <cs-button *ngIf="isFollowUpReady" [class]="'primary btn-xxxs btn-xxxs-padding'" (click)="startFollowUp()"><cs-button-text class="tw-px-4 tw-flex tw-items-center"><span i18n="@@clickHere">Click Here</span><cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon></cs-button-text></cs-button>
      </ng-container>
    </div>
  </section>
  <section class="tw-bg-gray-100 tw-px-8 tw-pb-10 tw-pt-24 tw-flex tw-gap-9">
    <div class="tw-flex-1">
      <h2 class="tw-font-body tw-font-bold tw-text-700 tw-mb-2">6 Lac+</h2>
      <p class="tw-font-body tw-text-300" i18n="@@peopleTookAdvantage">
        people took advantage of this service
      </p>
    </div>
    <div class="tw-flex-none tw-border-l tw-border-gray-300"></div>
    <div class="tw-flex-1">
      <h2 class="tw-font-body tw-font-bold tw-text-700 tw-mb-2">95%</h2>
      <p class="tw-font-body tw-text-300" i18n="@@sawBetterResults">
        saw better results with regular checkup
      </p>
    </div>
  </section>
  <section class="tw-px-6 tw-py-9">
    <div class="tw-flex-none tw-self-center">
      <img class="tw-max-w-56 tw-max-h-28 tw-mx-auto tw-mb-4 tw-rounded-[100px]" src="https://storage.googleapis.com/heallocontent/app/img/checkup_pill_2.png" alt="" />
      <p class="tw-font-body tw-text-600 tw-text-center" i18n="@@seeImprovementIn3Checkups">
        See improvement in <b>3 checkups</b>
      </p>
    </div>
  </section>
  <section class="tw-bg-gray-100 tw-pt-8 tw-pb-10">
    <h2 class="tw-font-body tw-font-bold tw-text-700 tw-text-center tw-mb-4" i18n="@@whatCustomersSay">What customers say</h2>
    <div class="tw-pl-4 tw-flex tw-gap-4 tw-overflow-x-auto">
      <div class="tw-bg-purple-100 tw-flex tw-flex-col tw-px-6 tw-pb-6 tw-pt-8 tw-rounded-4 tw-min-w-[70vw]">
        <img class="tw-flex-none tw-mb-4 tw-h-16 tw-w-16 tw-rounded-full" src="https://storage.googleapis.com/heallocontent/app/img/testimonial_suman.jpg" alt="" />
        <p class="tw-flex-none tw-font-body tw-text-300 tw-mb-3" i18n="@@testimonialTwoYearsAgoIStartedTreatment">
          2 years ago, I started using the Cureskin treatment kit and the
          results are WOW! The regular checkups with doctors helped me get my
          clear and smooth skin back.
        </p>
        <p class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@sumanBanglore">
          Suman, Bangalore
        </p>
        <div class="tw-flex tw-items-center">
          <cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon>
        </div>
      </div>
      <div class="tw-bg-blue-100 tw-flex tw-flex-col tw-px-6 tw-pb-6 tw-pt-8 tw-rounded-4 tw-min-w-[70vw]">
        <img class="tw-flex-none tw-mb-4 tw-h-16 tw-w-16 tw-rounded-full" src="https://storage.googleapis.com/heallocontent/app/img/testimonial_kartik.jpg" alt="" />
        <p class="tw-flex-none tw-font-body tw-text-300 tw-mb-3" i18n="@@testimonialFreeHairAnalysisIdentifiedTreatment">
          Free hair analysis identified the root cause of my hair fall. Whereas,
          the doctor-approved diet, monthly checkups, and hair Treatment kit
          helped me control it completely.
        </p>
        <p class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@kartikWarangal">
          Kartik, Warangal
        </p>
        <div class="tw-flex tw-items-center">
          <cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon>
        </div>
      </div>
      <div class="tw-bg-teal-100 tw-flex tw-flex-col tw-px-6 tw-pb-6 tw-pt-8 tw-rounded-4 tw-min-w-[70vw]">
        <img class="tw-flex-none tw-mb-4 tw-h-16 tw-w-16 tw-rounded-full" src="https://storage.googleapis.com/heallocontent/app/img/testimonial_aaditya.jpg" alt="" />
        <p class="tw-flex-none tw-font-body tw-text-300 tw-mb-3" i18n="@@testimonialMyPigmentationStartedTreatment">
          My pigmentation started reducing after a few weeks of using the
          Cureskin treatment kit. 5 stars to the doctor's support and their
          service of free checkups.
        </p>
        <p class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@adityaKanpur">
          Aditya, Kanpur
        </p>
        <div class="tw-flex tw-items-center">
          <cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gray-300" [iconName]='"star"'></cs-icon>
        </div>
      </div>
      <div class="tw-bg-green-100 tw-flex tw-flex-col tw-px-6 tw-pb-6 tw-pt-8 tw-rounded-4 tw-min-w-[70vw]">
        <img class="tw-flex-none tw-mb-4 tw-h-16 tw-w-16 tw-rounded-full" src="https://storage.googleapis.com/heallocontent/app/img/testimonial_lata.jpg" alt="" />
        <p class="tw-flex-none tw-font-body tw-text-300 tw-mb-3" i18n="@@testimonialSoGratefulThat">
          So grateful that I came across Cureskin during my pregnancy. Loved the
          support and guidance from the doctor's team throughout the pregnancy.
        </p>
        <p class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@lataMurshidabad">
          Lata, Murshidabad
        </p>
        <div class="tw-flex tw-items-center">
          <cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gray-300" [iconName]='"star"'></cs-icon>
        </div>
      </div>
    </div>
  </section>
</ng-container>