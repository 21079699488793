<div class="weekStrip tw-bg-white tw-text-center tw-m-auto tw-mb-6 tw-font-body tw-text-200 tw-pt-2">
    <span i18n="@@yourTreatment1">Your Treatment :</span><span><span class="tw-font-bold tw-mr-1">&nbsp;WEEK 4</span><img class="tw-inline-flex tw-mb-[2px]" src="/assets/icons/teal-tick-circle.svg" /></span>
</div>
<div class="title tw-text-center tw-text-600 tw-font-body tw-font-bold tw-mb-1 tw-m-auto tw-mb-2">
    <p i18n="@@weekFourJourney">You are in WEEK 4 of your journey.</p>
</div>
<div class="subTitle tw-text-center tw-mb-4 tw-font-body tw-text-300">
    <p i18n="@@useTreatmentKit">Continue using your treatment kit for the best result!</p>
</div>
<div class="tw-text-center tw-mb-4">
    <cs-button class="tw-flex-none" 
    [class]="'primary btn-xxxs btn-xxxs-padding'" 
    (callback)="handleCTA()" 
    [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
      <cs-button-text i18n="@@knowMore" style="padding: 0 10px">Know More</cs-button-text>
    </cs-button>
</div>
<swiper class="tw-pl-6 tw-pb-14 tw-pt-16" [config]="carouselConfigWeeklyJourney">
  <ng-container>
    <div class="bgClass firstCard tw-mr-3 tw-p-1" *swiperSlide>
        <section class="cust-wid">
            <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/skin-healing.png" />
            <div class="header tw-mt-11">
              <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@weekFourCardOne">
                Skin healing happens in 3 stages!
              </div>
            </div>
            <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-absolute tw-bottom-0 tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCardCTA('WaGYgN5j8w')">
              <span class="tw-lowercase" i18n="@@learnMore">Learn More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
            </div>
          </section>
    </div>
    <!-- 2 -->
    <section class="cust-wid tw-mr-2" *swiperSlide>
        <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/question_mark.png" />
        <div class="header tw-mt-11">
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@weekFourCardTwo">
            Are you experiencing mild dullness on your face?
          </div>
        </div>
        <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-absolute tw-bottom-0 tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCardCTA('KtzLcPhSZB')">
          <span class="tw-lowercase" i18n="@@readMore">Read More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
        </div>
      </section>
      <!-- 3 -->
    <section class="cust-wid tw-mr-2" *swiperSlide>
        <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/girl-drinking.png" />
        <div class="header tw-mt-11">
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@weekFourCardThree">
            Holistic benefits of drinking water!
          </div>
        </div>
        <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-absolute tw-bottom-0 tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCardCTA('6wvnoXzi2G')">
          <span class="tw-lowercase" i18n="@@learnMore">Learn More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
        </div>
      </section> 
  </ng-container> 
   
