import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { ToolbarModule } from '@components/toolbar';
import { ReportCardModule } from '@shared/report-card/report-card.module';
import { CsIconModule } from '@components/icon';
import { SwiperModule } from 'swiper/angular';
import { UserFollowupReminderComponent } from './user-followup-reminder/user-followup-reminder.component';
import { UserFollowupComponent } from './user-followup/user-followup.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LoadingModule,
    ButtonModule,
    ToolbarModule,
    CsIconModule,
    ReportCardModule,
    SwiperModule,
  ],
  declarations: [UserFollowupComponent, UserFollowupReminderComponent],
  exports: [UserFollowupComponent],
})
export class UserFollowupModule {
}
