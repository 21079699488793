import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-complete-your-multiple-order',
  templateUrl: './complete-your-multiple-order.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class CompleteYourMultipleOrderComponent implements OnInit {
  @Input('toolbarIcons') toolbarIcons:any;
  @Output('goToCart') goToCart:EventEmitter<void> = new EventEmitter();
  @Input('productsInCart') productsInCart:any;
  @Input('cartDiscount') cartDiscount:number;
  @Input('totalSp') totalSp:any;
  ngOnInit(): void {
  }
  handleClick():void {
    this.goToCart.emit();
  }
}
