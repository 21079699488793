<div class="hbox font-redHat-R" *ngIf="!isExpired">
  <div class="flex-box">
  <div class="flex-heading">
    <toolbar (onAction)="back()"><img class="db m-auto" src="/assets/images/logo_white.svg" width="160px" /></toolbar>
  </div>
  <img class="insta-img" [src]="instaLiveInfo?.get('descriptionImage')" />
  <div class="flex-box-1" *ngIf="isLive">
    <div class="live">
    <div class="icon blinking"></div>
    <div class="txt">LIVE</div>
    </div>
    <div class="watch-now" (click)="redirectToInstagram()">
    <div class="play"></div>
    <div class="info">Watch Now On Instagram</div>
    </div>
  </div>
  <div class="container" *ngIf="isUpcoming">
    <div class="read-more notified" *ngIf="isNotified" style="padding: 20px; width: 180px">
    You will be notified
    </div>
    <div class="read-more" *ngIf="!isNotified" (click)="notifyClick()">
    Notify me
    </div>
  </div>
  </div>
</div>