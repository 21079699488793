<ng-container *ngIf="isMultiConcernUI; then multiConcernTemplate else originalTemplate"></ng-container>

<ng-template #originalTemplate>
  <!-- Original template -->
  <div class="tw-flex tw-flex-col tw-h-full tw-bg-teal-200 tw-bg-repeat-x tw-bg-top tw-overflow-y-auto">
    <loading class="tw-fixed tw-top-[50%] tw-left-[50%] -tw-translate-x-1/2 -tw-translate-y-1/2" *ngIf="ui.loading"></loading>
    <div class="tw-flex-none">
      <div id ="backButton" class="tw-fixed tw-left-6 tw-top-6 tw-z-20 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
        <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
      </div>
      <div class="tw-flex tw-flex-col tw-px-6 tw-pt-[90px]" #container="" *ngIf="!searchEnabled">
        <img class="tw-w-20 tw-h-20 tw-mb-2" src="https://cdn.cureskin.com/app/img/dr_charu_v1.png" />
        <h1 class="tw-font-body tw-font-bold tw-text-800 tw-text-gray-800 tw-mb-2" i18n="@@chooseYourConcern"> Choose your concern </h1>
        <div class="tw-font-body tw-text-300 tw-text-gray-800 tw-mb-8" *ngIf="concernClass === appConfig.Shared.Regimen.Class.FACE" i18n="@@WhatAreYouLookingForSkinNew"> Let's start with your most important skin concern </div>
        <div class="tw-font-body tw-text-300 tw-text-gray-800 tw-mb-8" *ngIf="concernClass === appConfig.Shared.Regimen.Class.HAIR" i18n="@@WhatAreYouLookingForHairNew"> Let's start with your most important hair concern </div>
      </div>
    </div>
    <div class="tw-flex-1" *ngIf="!ui.loading">
      <div class="tw-bg-white tw-px-6 tw-pb-24 tw-min-h-full" [ngClass]='{ "tw-overflow-scroll tw-pt-[90px]": searchEnabled, "tw-rounded-tr-5 tw-rounded-tl-5 tw-pt-[34px]": !searchEnabled }'>
        <div class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-flex-nowrap" *ngIf="searchEnabled">
          <div class="tw-shadow-[0_3px_10px_#E8E8E8] tw-rounded-[10px] tw-bg-gray-50 tw-px-[20px] tw-py-[15px] tw-flex tw-items-center tw-flex-row tw-flex-wrap tw-mx-[5px] tw-my-[10px] tw-box-border" *ngIf="concernClass === appConfig.Shared.Regimen.Class.FACE" (click)='openTab("/user/instantCheckup/preview?tag=FRONT_FACE")'>
            <div class="tw-flex tw-items-center tw-justify-center tw-flex-row tw-flex-wrap">
              <div class="tw-relative tw-w-10 tw-h-10 tw-mr-5 tw-flex tw-items-center tw-justify-center before:tw-content-[''] before:tw-absolute before:tw-w-3 before:tw-h-3 before:tw-border-0 before:tw-border-solid before:tw-border-[#EDB0B0] after:tw-content-[''] after:tw-absolute after:tw-w-3 after:tw-h-3 after:tw-border-0 after:tw-border-solid after:tw-border-[#EDB0B0]  before:tw-left-0 before:tw-top-0 before:tw-border-l-2 before:tw-border-t-2 after:tw-right-0 after:tw-top-0 after:tw-border-r-2 after:tw-border-t-2 ">
                <em class="before:tw-content-[''] before:tw-absolute before:tw-w-3 before:tw-h-3 before:tw-border-0 before:tw-border-solid before:tw-border-[#EDB0B0] after:tw-content-[''] after:tw-absolute after:tw-w-3 after:tw-h-3 after:tw-border-0 after:tw-border-solid after:tw-border-[#EDB0B0] before:tw-left-0 before:tw-bottom-0 before:tw-border-l-2 before:tw-border-b-2 after:tw-right-0 after:tw-bottom-0 after:tw-border-r-2 after:tw-border-b-2 "></em>
                <div class="tw-text-[23px] tw-flex tw-items-center tw-justify-center tw-text-blue-900"></div>
              </div>
            </div>
            <div class="tw-flex-grow">
              <div class="tw-font-body tw-font-bold tw-text-[20px]" i18n="@@stillConfusedNew"> Still confused ? </div>
              <div class="tw-text-gray-400 tw-font-body tw-tracking-[0.3px] tw-text-[12px]" i18n="@@takePhotoAndAnalyze"> Take photo and analyse your skin </div>
            </div>
          </div>
          <div class="tw-relative tw-w-full tw-h-[45px] tw-max-w-[400px] tw-inline-block tw-text-gray-800 tw-text-[15px] tw-overflow-hidden tw-border-none tw-mx-auto tw-my-[15px] tw-box-border tw-whitespace-nowrap tw-px-2 tw-py-[13px] tw-rounded-[50px] tw-text-center">
            <input id="search" class="tw-w-full tw-absolute tw-top-0 tw-right-0 tw-h-[45px] tw-z-10 tw-px-10 tw-py-3 tw-inline-block tw-font-body" id="search" (keyup)="onSelectionChange()" [(ngModel)]="searchInput" autocomplete="off" (blur)="logSearch()" placeholder="Search" i18n-placeholder="@@search" />
            <cs-icon class="tw-absolute tw-top-[12px] tw-left-[12px] tw-w-[20px] tw-h-[20px] tw-text-gray-400" [iconName]='"search_icon"' style="z-index: 10;"></cs-icon>
          </div>
        </div>
        <div class="tw-text-center tw-p-5 tw-text-[12.4px] tw-text-blue-900 tw-text-blue-500" *ngIf="mainConcernsCopy.length === 0" i18n="@@RelatedConcernNotFound"> Related Concern not found ! </div>
        <div class="tw-max-w-[500px] tw-m-auto" *ngIf="concernClass &amp;&amp; classBasedConcerns[concernClass].length">
          <div class="tw-text-left tw-mb-4" *ngIf="concernSections.CARE.length">
            <div class="tw-pb-4 tw-uppercase tw-tracking-[1.7px]" *ngIf="concernClass === appConfig.Shared.Regimen.Class.FACE" [ngClass]="conn.getClassBasedOnLanguagePreference()" i18n="@@careKitsForSkinGoals"> Achieve Skin Goals </div>
            <div class="tw-pb-4 tw-uppercase tw-tracking-[1.7px]" [ngClass]="conn.getClassBasedOnLanguagePreference()" *ngIf="concernClass === appConfig.Shared.Regimen.Class.HAIR" i18n="@@careKitsForHairGoals"> Care for Hair </div>
            <div class="tw-flex tw-flex-row tw-gap-2">
              <div class="tw-flex-1 tw-px-4 tw-py-0 tw-box-border tw-w-[47%] tw-h-[75px] tw-flex tw-items-center tw-justify-center tw-text-center tw-overflow-hidden tw-leading-[1.3] tw-break-words tw-bg-gray-80 tw-text-gray-800  tw-max-h-16 tw-m-0 tw-rounded-2.5" *ngFor="let item of concernSections.CARE; let i = index;" [ngClass]='{ "tw-bg-gray-800 tw-text-gray-50 tw-animate-anim_click": (selectedConcerns.includes(item.key)) ,"tw-text-gray-800 tw-bg-gray-80" : !(selectedConcerns.includes(item.key))  }' (click)="addRemoveProblem(item)" style="box-shadow: none;">
                <span class="tw-font-body tw-text-300">{{ item.key }}</span>
              </div>
            </div>
          </div>
          <div class="tw-text-left" *ngIf="concernSections.TREATMENT.length">
            <div class="tw-py-3 tw-uppercase tw-tracking-[1.7px]" [ngClass]="conn.getClassBasedOnLanguagePreference()" i18n="@@treatmentKitsForProblems"> Solve Specific Problems </div>
            <div class="tw-grid tw-grid-cols-2 tw-gap-2">
              <div class="tw-flex tw-items-center tw-justify-center tw-bg-gray-80 tw-max-h-fit tw-rounded-2.5 tw-font-body tw-text-300 tw-text-gray-800 tw-py-5 tw-px-6 tw-text-center" *ngFor="let item of concernSections.TREATMENT; let i = index;" [ngClass]='{ "tw-bg-gray-800 tw-text-gray-50 tw-animate-anim_click": (selectedConcerns.includes(item.key)) ,"tw-text-gray-800 tw-bg-gray-80" : !(selectedConcerns.includes(item.key)) }' (click)="addRemoveProblem(item)">
                <span class="tw-font-body tw-text-300">{{item.key}}</span>
              </div>
              <div id="showAllButton" class="tw-flex tw-items-center tw-justify-center tw-bg-gray-80 tw-rounded-2.5 tw-font-body tw-text-300 tw-text-gray-800 tw-py-5 tw-text-center" *ngIf="!searchEnabled &amp;&amp; concernSections.TREATMENT.length === 6" (click)="enableSearch()" i18n="@@showAll" style="grid-column: 1 / -1"> Show All </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-flex-none tw-p-5 bg-white" *ngIf="selectedConcerns.length">
      <cs-button id="continueBtn" [class]="'tw-w-full primary'" [disabled]="!selectedConcerns.length" (callback)="onMainConcernSave()" [loading]="ui.saveInProcess">
        <cs-button-text i18n="@@continueNew">Continue</cs-button-text>
      </cs-button>
    </div>
  </div>
</ng-template>

<ng-template #multiConcernTemplate>
  <!-- Multi-concern template -->
  <div class="tw-flex tw-flex-col tw-h-full tw-bg-teal-200">
    <!-- Header section -->
    <ng-container *ngIf="showSecondaryConcerns; then secondaryConcernsHeader else primaryConcernsHeader"></ng-container>
    <!-- Primary concerns header -->
    <ng-template #primaryConcernsHeader>
      <div class="tw-relative">
        <div id="backButton"
          class="tw-ml-6 tw-mt-6 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl"
          (click)="!ui.saveInProcess && back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <h1 class="tw-font-body tw-font-bold tw-text-700 tw-text-gray-800 tw-mt-8 tw-mb-6 tw-ml-6 tw-max-w-[55%]"
          i18n="@@chooseYourMostImportantConcern">Choose your most important concern</h1>
        <img class="tw-absolute tw-h-[200px] tw-right-[-16px] tw-bottom-[-40px]" src="/assets/images/dr-charu.png"
          alt="Dr Charu" />
      </div>
    </ng-template>
    <!-- Secondary concerns header -->
    <ng-template #secondaryConcernsHeader>
      <div class="tw-relative tw-min-h-12 tw-max-h-12 tw-h-12">
        <div id="backButton"
          class="tw-absolute tw-ml-6 tw-mt-6 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl tw-z-20"
          (click)="!ui.saveInProcess && handleBackToPrimaryConcerns()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
        </div>
      </div>
    </ng-template>
    <!-- Body section -->
    <div
      class="tw-grid tw-grid-rows-[1fr_auto] tw-h-full tw-bg-white tw-px-6 tw-pt-6 tw-pb-4 t tw-rounded-tr-5 tw-rounded-tl-5 tw-z-10 tw-overflow-hidden"
      [ngClass]='{"tw-grid-rows-[auto_1fr_auto]": showSecondaryConcerns}'>
      <ng-container *ngIf="ui.loading; then loadingTemplate else concernsSelectionTemplate"></ng-container>
      <!-- Loader -->
      <ng-template #loadingTemplate>
        <loading class="tw-justify-center tw-items-start"></loading>
      </ng-template>
      <!-- Concerns selection -->
      <ng-template #concernsSelectionTemplate>
        <!-- Switch between primary concerns list and secondary concrens list -->
        <ng-container
          *ngIf="showSecondaryConcerns; then secondaryConcernsContent else primaryConcernsContent"></ng-container>
        <!-- Primary concerns list -->
        <ng-template #primaryConcernsContent>
          <div class="tw-overflow-x-hidden tw-overflow-y-auto">
            <ul class="tw-flex tw-flex-col tw-gap-4 tw-overflow-y-auto">
              <li class="tw-flex tw-gap-2" *ngFor="let item of concernSections.TREATMENT; let i = index;"
                (click)="handleAddPrimaryConcern(item)">
                <div *ngIf="!selectedConcerns.includes(item.key)"
                  class="tw-w-5 tw-h-5 tw-m-[6px] tw-bg-gray-50 tw-border-2 tw-border-solid tw-border-gray-400 tw-rounded-[50%] tw-shrink-0">
                </div>
                <div *ngIf="selectedConcerns.includes(item.key)" class="tw-w-5 tw-h-5 tw-m-[6px] tw-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <rect width="20" height="20" rx="10" fill="#0E796C" />
                    <path
                      d="M12.9548 6.46302L8.9625 10.6128L6.85387 8.89778L5.49316 10.5734L8.37221 12.9126L9.14263 13.5368L9.8287 12.8226L14.507 7.9643L12.9551 6.46289L12.9548 6.46302Z"
                      fill="white" />
                  </svg>
                </div>
                <div class="tw-flex tw-flex-col">
                  <span
                    class="tw-font-body tw-text-400 tw-leading-[29px] tw-text-gray-800 tw-font-semibold">{{item.key}}</span>
                  <span class="tw-font-body tw-text-200 tw-text-gray-400 tw-font-regular">{{item.description || ''}}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-1 tw-bg-gray-50 tw-mt-auto tw-pt-2">
            <cs-button
              [class]="'tw-w-full primary ' + (selectedConcerns.length && selectedConcernsDetails?.[0]?.value !== 'NO_CONCERN' ? 'tw-bg-teal-600' : 'tw-bg-gray-300')"
              [loading]="ui.saveInProcess && selectedConcerns.length && selectedConcernsDetails?.[0]?.value !== 'NO_CONCERN'"
              [disabled]="ui.saveInProcess || !selectedConcerns.length"
              (callback)="handleShowSecondaryConcernsScreen()">
              <cs-button-text class="tw-normal-case tw-text-500 tw-font-bold tw-text-gray-50"
                i18n="@@next">Next</cs-button-text>
            </cs-button>
            <cs-button [class]="'tw-w-full secondary tw-border-none'"
              [loading]="ui.saveInProcess && selectedConcerns.length && selectedConcernsDetails?.[0]?.value === 'NO_CONCERN'"
              [disabled]="ui.saveInProcess" (callback)="handleSkipPrimaryConcern()">
              <cs-button-text class="tw-normal-case tw-text-400 tw-font-bold tw-text-teal-600"
                i18n="@@iDontHaveAnyConcern">I don't have any concern</cs-button-text>
            </cs-button>
          </div>
        </ng-template>
        <!-- Secondary concerns list -->
        <ng-template #secondaryConcernsContent>
          <div class="tw-mt-6 tw-mb-8">
            <h1 class="tw-font-body tw-font-bold tw-text-700 tw-text-gray-800" i18n="@@doYouHaveAnyOtherSkinConcerns">Do
              you have any other skin concerns?</h1>
            <span class="tw-inline-block tw-text-300 tw-text-gray-800 tw-mt-1" i18n="@@selectUpto3">(Select upto
              3)</span>
          </div>
          <div *ngIf="selectedConcernsDetails?.[0]?.concernsList?.length"
            class="tw-overflow-x-hidden tw-overflow-y-auto">
            <ul class="tw-flex tw-flex-wrap tw-gap-4 tw-overflow-y-auto">
              <li
                class="tw-box-border tw-flex tw-justify-center tw-items-center tw-gap-2 tw-px-4 tw-py-[9px] tw-border tw-border-solid tw-border-gray-400 tw-rounded-6"
                [ngClass]='{"tw-opacity-30": !selectedSecondaryConcerns[item.displayName] && isOpacityConditionMet, "tw-bg-teal-100 tw-border-teal-400": selectedSecondaryConcerns[item.displayName]}'
                *ngFor="let item of selectedConcernsDetails[0].concernsList; let i = index;"
                (click)="addRemoveSecondaryConcern(item)">
                <div *ngIf="!selectedSecondaryConcerns[item.displayName]"
                  class="tw-w-4 tw-h-4 tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-400 tw-rounded-[50%] tw-shrink-0">
                </div>
                <div *ngIf="selectedSecondaryConcerns[item.displayName]" class="tw-w-4 tw-h-4 tw-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#4EAE9C" />
                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#4EAE9C" />
                    <path
                      d="M11.4229 3.90249L6.79805 8.70975L4.35534 6.72301L2.77905 8.66408L6.11424 11.3739L7.00672 12.097L7.80149 11.2696L13.221 5.64163L11.4232 3.90234L11.4229 3.90249Z"
                      fill="white" />
                  </svg>
                </div>
                <span class="tw-font-body tw-text-300 tw-text-gray-800">{{item.displayName}}</span>
              </li>
            </ul>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-1 tw-bg-gray-50 tw-mt-auto tw-pt-2">
            <cs-button
              [class]="'tw-w-full primary ' + (isSecondaryConditionSelected ? 'tw-bg-teal-600' : 'tw-bg-gray-300')"
              [disabled]="!isSecondaryConditionSelected || ui.saveInProcess"
              [loading]="ui.saveInProcess && isSecondaryConditionSelected"
              (callback)="handleContinueSecondaryConcern()">
              <cs-button-text class="tw-normal-case tw-text-500 tw-font-bold tw-text-gray-50"
                i18n="@@continue">Continue</cs-button-text>
            </cs-button>
            <cs-button [class]="'tw-w-full secondary tw-border-none'" [disabled]="ui.saveInProcess"
              [loading]="ui.saveInProcess && !isSecondaryConditionSelected" (callback)="handleSkipSecondaryConcern()">
              <cs-button-text class="tw-normal-case tw-text-400 tw-font-bold tw-text-teal-600"
                i18n="@@skip">Skip</cs-button-text>
            </cs-button>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>
